import React, { Component, Fragment } from 'react';
import { Grid, Typography, TextField, FormControl } from '@material-ui/core'
import Dividers from '../../common/fields/Dividers';
import { connect } from "react-redux";
import ContentLoader, { List } from "react-content-loader"


class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      doc_number: '',
      phone_contact: '',
      email: '',
      voucher: '',
      changeForm: false,
      errors: {
        name: false,
        surname: false,
        doc_number: false,
        email: false,
        voucher: false,
      },
      initial: false,
      // espera de repsuestas
      getInfoLoginSent: false,
      //aux
      responsesReceived: []
    };
    this.expectedResponses = ["getInfoLogin"]
  }

  // Proceso que chequea respuestas necesarias recibidas para cargar los campos del formulario.
  checkResponsesReceived = () => {
    let allReceived = true;
    let pos = 0;
    while ((pos < this.expectedResponses.length) && allReceived) {
      if (this.state.responsesReceived.indexOf(this.expectedResponses[pos]) === -1) {
        allReceived = false;
      }
      pos = pos + 1;
    }
    return allReceived
  }

  addToResponsesReceived = (response) => {
    let responsesAux = this.state.responsesReceived;
    if (responsesAux.indexOf(response) === -1) {
      responsesAux.push(response);
      this.setState({ responsesReceived: responsesAux });
    }
  }

  inputValidate = () => {
    let count = 6;
    let countValid = 0;
    let response = [];
    let status = false;


    if (this.state.name !== '') {
      countValid++;
      response['name'] = this.state.name;
    }


    if (this.state.surname !== '') {
      countValid++;
      response['surname'] = this.state.surname;
    }


    if (this.state.doc_number !== '') {
      countValid++;
      response['doc_number'] = this.state.doc_number;
    }



    if (this.state.phone_contact !== '') {
      countValid++;
      response['phone_contact'] = this.state.phone_contact;
    }

    if (this.state.email !== '') {
      countValid++;
      response['email'] = this.state.email;
    }


    if (this.state.voucher !== '') {
      countValid++;
      response['voucher'] = this.state.voucher;
    }

    this.setState({
      errors: {
        'name': (this.state.name !== '') ? false : true,
        'surname': (this.state.surname !== '') ? false : true,
        'doc_number': (this.state.doc_number !== '') ? false : true,
        'phone_contact': (this.state.phone_contact !== '') ? false : true,
        'email': (this.state.email !== '') ? false : true,
        'voucher': (this.state.voucher !== '') ? false : true,
      }
    });

    if (response) {
      if (countValid !== count) {
        status = false;
      } else {
        status = true;
      }
    }
    return { "status": status, "data": response };
  }

  handleSubmit = () => {
    let responseStatus = [];
    let responseData = [];
    let scrollPersonal = false;


    let inputValidateInformation = this.inputValidate();
    responseStatus.push(inputValidateInformation.status);
    scrollPersonal = inputValidateInformation.status ? false : true;
    responseData["refund"] = inputValidateInformation

    if (!responseStatus.some(item => item === false)) {

      return { 'continue': true, 'data': responseData, 'createRefund': false };
    }

    return { 'continue': false, 'data': [], 'createRefund': false, "view": this.props.view };
  }

  componentDidMount() {

    let form = {
      documento: this.props.location.state.loginResponse.refunds.doc_number,
      voucherId: this.props.location.state.loginResponse.refunds.voucher
    }
    this.props.getLoginReintegro(form);
    this.setState({
      getInfoLoginSent: true,
    })

    if (Object.keys(this.props.infoStep).length > 0) {
      let arrayStep = []
      this.props.infoStep.map(step => {
        if (step.step === 0) {
          arrayStep.push(step.info)
        }
      })

      let ultimaActuzalicion = Object.keys(arrayStep)[Object.keys(arrayStep).length - 1]

      if (this.props.location.state.loginResponse.refunds.name !== arrayStep[ultimaActuzalicion].refund.data.name || this.props.location.state.loginResponse.refunds.surname !== arrayStep[ultimaActuzalicion].refund.data.surname || this.props.location.state.loginResponse.refunds.phone_contact !== arrayStep[ultimaActuzalicion].refund.data.phone_contact || this.props.location.state.loginResponse.refunds.email !== arrayStep[ultimaActuzalicion].refund.data.email ) {
        this.setState({
          changeForm: true,
        })
      }

    }
  }

  UNSAFE_componentWillReceiveProps(next_props) {

    if (Object.keys(next_props.responseDataLoginReintegro).length > 0 && !this.state.initial) {
      this.setState({
        name: next_props.responseDataLoginReintegro.refunds.name,
        surname: next_props.responseDataLoginReintegro.refunds.surname,
        doc_number: next_props.responseDataLoginReintegro.refunds.doc_number,
        phone_contact: next_props.responseDataLoginReintegro.refunds.phone_contact,
        email: next_props.responseDataLoginReintegro.refunds.email,
        voucher: next_props.responseDataLoginReintegro.refunds.voucher,
        initial: true,
      })
    }
    if (this.state.getInfoLoginSent && (this.props.responseDataLoginReintegro !== next_props.responseDataLoginReintegro)) {
      this.setState({ getInfoLoginSent: false })
      this.addToResponsesReceived("getInfoLogin");
    }

    if (Object.keys(this.props.infoStep).length > 0 && this.state.changeForm) {

      let arrayStep = []
      next_props.infoStep.map(step => {
        if (step.step === 0) {
          arrayStep.push(step.info)
        }
      })

      let ultimaActuzalicion = Object.keys(arrayStep)[Object.keys(arrayStep).length - 1]

      this.setState({
        name: arrayStep[ultimaActuzalicion].refund.data.name,
        surname: arrayStep[ultimaActuzalicion].refund.data.surname,
        phone_contact: arrayStep[ultimaActuzalicion].refund.data.phone_contact,
        email: arrayStep[ultimaActuzalicion].refund.data.email,
        initial: true,
      })
    }
  }

  handlerName = (event, child) => {
    this.setState({ name: event.target.value })
  }

  handlerSurname = (event, child) => {
    this.setState({ surname: event.target.value })
  }

  handlerPhone = (event, child) => {
    this.setState({ phone_contact: event.target.value })
  }

  handlerEmail = (event, child) => {
    this.setState({ email: event.target.value })
  }

  render() {
    const getTipoRefund = () => {
      const response = [
        { "id": "Cuenta propia", "description": "Cuenta propia" },
        { "id": "Autorizar a tercero", "description": "Autorizar a tercero" },
      ];
      return response;
    }

    const LoaderSkeleton = (props) => {
      return (
        <ContentLoader
          height={"200"}
          width={"auto"}
          viewBox="0 0 100% 100%"
          backgroundColor="#d9d9d9"
          foregroundColor="#ecebeb"
        >

          <rect x="10%" y="0" rx="3" ry="3" width="38%" height="30" />
          <rect x="50%" y="0" rx="3" ry="3" width="38%" height="30" />

          <rect x="10%" y="50" rx="3" ry="3" width="38%" height="30" />
          <rect x="50%" y="50" rx="3" ry="3" width="38%" height="30" />


        </ContentLoader>
      )
    }

    return (
      <Fragment>

        <Dividers></Dividers>
        <Typography component="h3" variant="h5" style={{ margin: "0 0 20px 25px" }}>
          Datos del solicitante
        </Typography>
        {(!this.checkResponsesReceived())
          ? <LoaderSkeleton />
          : <>

            <Grid container spacing={3} className="main_admin" alignItems="center" justify="center">

              <Grid item xs={11} sm={5}>
                <FormControl fullWidth>
                  <TextField
                    id={`name`}
                    label="Nombre(s)"
                    name={`name`}
                    placeholder="Ingrese el nombre"
                    defaultValue={this.state.name ? this.state.name : ''}
                    disabled={false}
                    type="text"
                    onChange={this.handlerName}
                    error={this.state.errors.name}
                    variant="outlined"
                  />
                </FormControl>

              </Grid>


              <Grid item xs={11} sm={5}>
                <FormControl fullWidth>
                  <TextField
                    id={`surname`}
                    label="Apellido(s)"
                    name={`surname`}
                    placeholder="Ingrese el apellido"
                    defaultValue={this.state.surname ? this.state.surname : ''}
                    disabled={false}
                    onChange={this.handlerSurname}
                    error={this.state.errors.surname}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={11} sm={5}>
                <FormControl fullWidth>
                  <TextField
                    
                    id={`doc_number`}
                    label="N° de documento"
                    name={`doc_number`}
                    placeholder="Ingrese su documento"
                    defaultValue={this.state.doc_number ? this.state.doc_number : ''}
                    disabled={true}
                    error={this.state.errors.doc_number}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={11} sm={5}>
                <FormControl fullWidth>
                  <TextField
                    id={`voucher`}
                    label="N° de voucher"
                    name={`voucher`}
                    placeholder="Ingrese n° de voucher"
                    minLength={null}
                    maxLength={null}
                    defaultValue={this.state.voucher ? this.state.voucher : ''}
                    disabled={true}
                    error={this.state.errors.voucher}                   
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={11} sm={5}>
                <FormControl fullWidth>
                  <TextField
                    id={`email`}
                    label="Email"
                    name={`email`}
                    defaultValue={this.state.email ? this.state.email : ''}
                    error={this.state.errors.email}
                    disabled={false}
                    onChange={this.handlerEmail}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={11} sm={5}>
                <FormControl fullWidth>
                  <TextField
                    id={`phone_contact`}
                    label="Teléfono"
                    name={`phone_contact`}
                    placeholder="Ingrese su teléfono"
                    defaultValue={this.state.phone_contact ? this.state.phone_contact : ''}
                    disabled={false}
                    onChange={this.handlerPhone}
                    error={this.state.errors.phone_contact}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={11} sm={5}></Grid>

            </Grid>
          </>}
      </Fragment>
    );
  }
}

const mapStateToProps = reducers => {

  return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
  return {
    getLoginReintegro: (form) => dispatch({ type: "GET_LOGIN_REINTEGRO", form }),
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps,
  null,
  { forwardRef: true }
)
  (PersonalInformation)
