const initialState = {
    responsePromotionsList: [],
    responsePromotion: [],
    responsePromotionProduct: null,
    responsePromotionProductOffices:null,
    responseDeleteProduct:null,
    errorDeleteProduct: false,
    finalPromo:false,
    responseDeletePromotionProductOffice:null
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "PROMOTIONS_RESPONSE_LIST":
            //console.log("PROMOTIONS_RESPONSE_LIST")
            if (action.value.detail !== undefined)
                newState.responsePromotionsList = []
            else
                newState.responsePromotionsList = action.value;

            newState.responsePromotion = []
            break;

        case "PROMOTION_RESPONSE":
            // console.log("PROMOTION_RESPONSE", action)
            newState.responsePromotion = action.value;
            break;

        case "PROMOTION_PRODUCT_RESPONSE":    
            newState.responsePromotionProduct = action.value;
            break;

        case "PROMOTION_PRODUCT_OFFICES_RESPONSE":
            newState.responsePromotionProductOffices = action.value;
            newState.responsePromotion = [];
            newState.finalPromo = action.final;
            break;

        case "PRODUCT_OFFICES_RESPONSE":
            newState.responsePromotionProductOffices = action.value
            break;

        case "DELETE_PRODUCT_PROMOTION_RESPONSE":
            newState.responseDeleteProduct = action.value

            if (action.value.detail !== undefined || action.value.status >= 300){ // agrega condicion para validar que se trata de un error
                newState.errorDeleteProduct = true
            }
            break;

        case "DELETE_PRODUCT_PROMOTION_OFFICE_RESPONSE": // Setea el campo de error al borrar producto
            newState.responseDeletePromotionProductOffice = action.value

            if (action.value.detail !== undefined || action.value.status >= 300){ // agrega condicion para validar que se trata de un error
                console.log('Error en la primera consulta')
                newState.errorDeleteProduct = true
            }
            else{
                console.log('No hay error else')
            }
            break;

        default: 
            return newState;
        
    }

    return newState;
};

export default reducer;