export function formatDateBar(date) {
    let d = new Date(date);

    let month = '' + (d.getMonth() + 1);
    let  day = '' + d.getDate();
    let  year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    if([year, month, day].join('/') === 'NaN/NaN/NaN')
        return false;
    else
        return [year, month, day].join('/');
 }

export function formatDate(date) {
    let d = new Date(date);
    let month = (d.getMonth() + 1);
    let  day =  d.getDate();
    let  year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    if([year, month, day].join('-') === 'NaN-NaN-NaN')
        return false;
    else
        return [year, month, day].join('-');
  }

  export function formatDateTime(date) {
    let d = new Date(date);
    let month = (d.getMonth() + 1);
    let  day =  d.getDate();
    let  year = d.getFullYear();
    let  hours = d.getHours();
    let  minutes = d.getMinutes();
    
    minutes = minutes === 0 ? '00': minutes
 
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    if([year, month, day].join('-') === 'NaN-NaN-NaN')
        return false;
    else
        return [day,month, year].join('-')+" "+hours+":"+minutes+' hs';
  }

  export function formatDateYMD(date){
    if (date !== null){
        let dateNew = date.split("-");
        let year    = dateNew[0];
        let month   = dateNew[1];
        let day     = dateNew[2];
        //console.log([year, month, day].join('/'))
        return [year, month, day].join('/')
    }else{
        return null
    }
}

