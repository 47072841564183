const initialState = {
    reservationsResponseList: [],
    responseReservations: [],
    responseFilteredReservations: [],
    responseExportReservations:[],
    errorReservations: false,
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "RESERVATIONS_RESPONSE_LIST":
            newState.reservationsResponseList = action.value;
            if (action.value.detail !== undefined){
                newState.errorReservations = true;
            }else{
                newState.errorReservations = false;
            }
            //newState.responseReservation = []
            break;
        case "RESERVATION_RESPONSE":
            console.log("responseReservation", action.value);
            newState.responseReservations = action.value;
            if (action.value.detail !== undefined){
                newState.errorReservations = true;
            }else{
                newState.errorReservations = false;
            }
            break;
        case "FILTERED_RESERVATIONS_RESPONSE":
            console.log("fiteredReservationS", action.value);
            newState.responseFilteredReservations = action.value;
            if (action.value.detail !== undefined || (action.value.status!= undefined && action.value.status=='Error')){
                newState.errorReservations = true;
            }else{
                newState.errorReservations = false;
            }
            break;
        case "EXPORT_RESERVATIONS_RESPONSE":
            console.log("exportReservationS", action.url);
            newState.responseExportReservations = action.url;
        default: 
            return newState;
    }

    return newState;
};

export default reducer;