import React, { Component } from "react";
import { Container, CssBaseline, AppBar, Box, Toolbar, Paper, Stepper, Step, StepLabel, Button, Link, Typography, Icon, Grid } from '@material-ui/core';
import ContentLoader from "react-content-loader"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/images/logo-color.png';
import { connect } from "react-redux";
import UploadForm from "../forms/reintegros/UploadForm";

const theme = createMuiTheme();

function Copyright() {
	return (
		<Typography variant="body2" align="center">
			© Safe Travel assistance una marca de assist med s.r.l - Todos los derechos reservados.
		</Typography>
	);
}

const LinkButton = React.forwardRef((props, ref) => <Link href={'/refund'} {...props} ref={ref} />);

class StepsUpload extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			skipped: new Set(),
			responseProduct: [],
			disabledBoton: false,
			dataStep1: {},
			errorCreacion: false,
			loader: false,
			logged: false,
			redirectFinal: null,
			getUploadReintegroSent: false,
			responseOK: false,
			errorMsj: null,
			postUploadPendingDocSent: false,
			commentsPendingCliente: null,
			errorUpdateFilesReintegro: false,
			patchUploadPendingDocsSent: false
		}
		this.steps = ['Cargar Documentos', 'Solicitud enviada'];
		this.access = window.localStorage.getItem('headersSetCookie');
		this.searchParams = new URLSearchParams(window.location.search);
	}

	getStepContent(step, props) {
		switch (step) {
			case 0:
				return (<UploadForm {...this.props} ref={element => { this.component = element }} />);
			case 1:
				return (
					<>
						<Grid container spacing={5} className="main_admin" justify="center" style={{ padding: 10 }}>
							{this.state.errorUpdateFilesReintegro ? (
								<Grid item xs={11} sm={8}>
									<Typography variant="h5" gutterBottom style={{ display: 'flex', alignItems: 'center', color: '#DAA520' }}>
										Ocurrió un error <Icon style={{ marginLeft: '8px' }}>error</Icon>
									</Typography>
									<Paper style={{ padding: 16, border: '1px solid #DAA520' }} variant="outlined">
										<Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', paddingTop: "40px" }}>
											No se pudo procesar el envío de documentación
										</Typography>
										<Typography variant="subtitle1" style={{ display: 'flex', justifyContent: 'center', padding: "16px 40px 40px 40px" }}>
											Por favor, contáctese con nosotros a través de reintegros@safetravelassistance.com
										</Typography>

									</Paper>
								</Grid>
							) : (
								<Grid item xs={11} sm={8}>
									<Typography variant="h5" gutterBottom style={{ display: 'flex', alignItems: 'center', color: '#006400' }}>
										Tu solicitud fue enviada <Icon style={{ marginLeft: '8px' }}>check_circle</Icon>
									</Typography>
									<Paper style={{ padding: 16, border: '1px solid #006400' }} variant="outlined">
										<Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', paddingTop: "40px" }}>
											Se ha procesado el envío de documentación
										</Typography>
										<Typography variant="subtitle1" style={{ display: 'flex', justifyContent: 'center', padding: "16px 40px 40px 40px" }}>
											En el transcurso de los siguientes 5 días hábiles nos estaremos comunicando vía mail para informarte la resolución del caso. Ante cualquier eventualidad, volvemos a contactarte.
										</Typography>

									</Paper>
								</Grid>
							)}
						</Grid>
						<Box style={{ display: 'flex', justifyContent: 'center', padding: "20px 0" }}>
							<Button startIcon={<Icon>home</Icon>} variant="contained" color="primary" component={LinkButton} >Inicio</Button>
						</Box>
					</>
				)
			default:
				return (<div></div>);
		}
	}

	handleNext = () => {
		const responseChilComponent = this.component.handleSubmit();
		if (responseChilComponent.continue) {
			if (responseChilComponent.createPost) {
				this.setState({ loader: true })
				const refund_pending = responseChilComponent.data.refund_pending
				const form = new Object()
				const formData = new FormData()

				form.refund_id = this.searchParams.get("refund_id") || null;
				form.voucher_hash = this.searchParams.get("hash") || null;

				let nameFilesPending = [];
				let totalFiles = [];

				this.setState({ commentsPendingCliente: refund_pending.comment_pending !== '' ? refund_pending.comment_pending : null })

				refund_pending.pending_files.map((pending_files, i) => {
					let mynewFile = new File([pending_files],
						`pending-${i}-${pending_files.name}`, { type: pending_files.type });
					totalFiles.push(mynewFile)
					nameFilesPending.push(mynewFile.name)
				})

				for (let j in totalFiles) {
					formData.append('files', totalFiles[j], totalFiles[j].name);
				}

				form.pending_doc_files = nameFilesPending
				let dataFinalString = JSON.stringify(form)
				formData.append('data', dataFinalString)

				this.props.postUploadPendingDoc(formData)
				this.setState({ postUploadPendingDocSent: true })
			}
		}
	};

	componentDidMount() {
		const refund_id = this.searchParams.get("refund_id") || null;
		const hash = this.searchParams.get("hash") || null;
		if (refund_id && hash) {
			let form = new Object();
			form = {
				refund_id,
				hash
			}
			this.props.getUploadReintegro(form)
			this.setState({ getUploadReintegroSent: true, loader: true })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.getUploadReintegroSent) {
			if (
				prevProps.loginReducer.responseErrorUpdateReintegro !=
				this.props.loginReducer.responseErrorUpdateReintegro ||
				prevProps.loginReducer.responseDataUpdateReintegro !=
				this.props.loginReducer.responseDataUpdateReintegro
			) {
				if (!this.props.loginReducer.responseErrorUpdateReintegro) {
					this.setState({ data: this.props.loginReducer.responseDataUpdateReintegro, responseOK: true, errorMsj: null });
				} else {
					this.setState({ data: {}, responseOK: false, errorMsj: this.props.loginReducer.responseMsjerrorUpdateReintegro });
				}
				this.setState({ getUploadReintegroSent: false, loader: false });
			}
		}

		if (this.state.postUploadPendingDocSent) {
			if (
				prevProps.productsReducer.errorUpdateFilesReintegro !=
				this.props.productsReducer.errorUpdateFilesReintegro ||
				prevProps.productsReducer.responseUpdateFilesReintegro !=
				this.props.productsReducer.responseUpdateFilesReintegro
			) {
				if (!this.props.productsReducer.errorUpdateFilesReintegro) {
					console.log(this.props.productsReducer.responseUpdateFilesReintegro)
					let id_file_pending = []
					this.props.productsReducer.responseUpdateFilesReintegro.map((responseFile) => {
						id_file_pending.push(responseFile.file_id)
					})
					if (id_file_pending.length !== 0) {
						const formPatch = new Object()

						if (!formPatch['refund']) {
							formPatch['refund'] = {};
						}

						formPatch['refund'].id = parseInt(this.searchParams.get("refund_id")) || null;
						formPatch['refund'].hash = this.searchParams.get("hash") || null;

						if (!formPatch['refund']['pending_documentation']) {
							formPatch['refund']['pending_documentation'] = {};
						}
						formPatch['refund']['pending_documentation']['client_pending_documents'] = {
							'comment': this.state.commentsPendingCliente,
							'file_ids': id_file_pending,
						};
						this.props.patchUploadPendingDoc(formPatch)
						this.setState({ errorUpdateFilesReintegro: false, patchUploadPendingDocsSent: true })
					}
				} else {
					this.setState({ loader: false, activeStep: this.state.activeStep + 1, errorUpdateFilesReintegro: true })
				}
				this.setState({ postUploadPendingDocSent: false });
			}
		}

		if (this.state.patchUploadPendingDocsSent) {
			if (
				prevProps.productsReducer.errorUpdatePendingReintegro !=
				this.props.productsReducer.errorUpdatePendingReintegro ||
				prevProps.productsReducer.responseUpdatePendingReintegro !=
				this.props.productsReducer.responseUpdatePendingReintegro
			) {
				if (!this.props.productsReducer.errorUpdatePendingReintegro) {
					console.log(this.props.productsReducer.responseUpdatePendingReintegro)
					this.setState({ loader: false, activeStep: this.state.activeStep + 1, errorUpdateFilesReintegro: false })
				} else {
					console.log(this.props.productsReducer.responseUpdatePendingReintegro)
					this.setState({ loader: false, activeStep: this.state.activeStep + 1, errorUpdateFilesReintegro: true })
				}
				this.setState({ patchUploadPendingDocsSent: false });
			}
		}
	}



	render() {


		const LoaderSkeleton = (props) => {
			return (
				<div style={{ marginTop: '50px' }}>
					<ContentLoader
						height={"200"}
						width={"auto"}
						viewBox="0 0 100% 100%"
						backgroundColor="#d9d9d9"
						foregroundColor="#ecebeb"
					>
						<rect x="10%" y="0" rx="3" ry="3" width="38%" height="30" />
						<rect x="50%" y="0" rx="3" ry="3" width="38%" height="30" />
						<rect x="10%" y="50" rx="3" ry="3" width="38%" height="30" />
						<rect x="50%" y="50" rx="3" ry="3" width="38%" height="30" />
					</ContentLoader>
				</div>
			);
		};

		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />

				<AppBar
					position="relative"
					color="default"
					elevation={0}
					style={{
						position: 'relative',
						borderBottom: (t) => `1px solid ${t.palette.divider}`,
					}}
				>
					<Toolbar>
						<img src={Logo} alt='by SafeTravelAssistance' style={{ maxWidth: "230px" }} />
					</Toolbar>
				</AppBar>

				<Container style={{ paddingTop: "20px" }}>
					<Paper variant="outlined" style={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
						<Typography component="h1" variant="h4" align="center" style={{ marginTop: "20px" }}>
							Reintegros
						</Typography>

						{this.state.responseOK ? (
							<>
								<Stepper activeStep={this.state.activeStep} style={{ paddingTop: "15px", paddingBottom: "15px", maxWidth: "600px", margin: "0 auto" }}>
									{this.steps.map((label, index) => {
										const stepProps = {};
										const labelProps = {};
										return (
											<Step key={label} {...stepProps}>
												<StepLabel {...labelProps}>{label}</StepLabel>
											</Step>
										);
									})}
								</Stepper>

								{this.state.activeStep === this.steps.length ? (
									<React.Fragment>
										{(this.state.loader !== true)
											? <LoaderSkeleton />
											: <>
												<Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', paddingTop: "40px" }}>
													{this.state.errorCreacion !== true ? (
														"¡Su solicitud ha sido enviada con éxito!"
													) : (
														"Hubo un error en la creación de su solicitud"
													)}
												</Typography>
												<Typography variant="subtitle1" style={{ display: 'flex', justifyContent: 'center', padding: "40px" }}>
													{this.state.errorCreacion !== true ? (
														"Dentro de los próximos 5 días hábiles te contactaremos con novedades acerca de tu solicitud de reintegro."
													) : (
														"Para continuar con su pedido por favor ponganse en contanto con reintegros@safetravelassistance.com"
													)}
												</Typography>

												<Box style={{ display: 'flex', justifyContent: 'center', paddingTop: "20px", paddingBottom: "10px" }}>
													<Button startIcon={<Icon>home</Icon>} variant="contained" color="primary" component={LinkButton} >Inicio</Button>
												</Box>

												<Typography variant="caption" style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
													En unos segundos serás redireccionado al inicio
												</Typography>
											</>}
									</React.Fragment>
								) : (
									<React.Fragment>
										{this.getStepContent(this.state.activeStep, this.props)}
										{this.state.activeStep === 0 &&
											<Box style={{ display: 'flex', justifyContent: 'center', paddingTop: "40px", paddingBottom: "20px" }}>
												<Button
													variant="contained"
													color="primary"
													onClick={this.handleNext} disabled={this.state.disabledBoton}
												>
													{this.state.activeStep === this.steps.length - 1 ? 'finalizar' : 'continuar'}
												</Button>
											</Box>
										}
									</React.Fragment>
								)}
							</>
						) : (
							<React.Fragment>
								{(this.state.loader === true)
									? <LoaderSkeleton />
									: <>
										<Grid container spacing={5} className="main_admin" justify="center" style={{ padding: 10 }}>
											<Grid item xs={11} sm={8}>
												<Typography variant="h5" gutterBottom style={{ display: 'flex', alignItems: 'center', color: '#DAA520' }}>
													Ocurrió un error <Icon style={{ marginLeft: '8px' }}>error</Icon>
												</Typography>
												<Paper style={{ padding: 16, border: '1px solid #DAA520' }} variant="outlined">
													<Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', paddingTop: "40px" }}>
														No se puede procesar el acceso a la solicitud
													</Typography>
													<Typography variant="subtitle1" style={{ display: 'flex', justifyContent: 'center', padding: "16px 40px 40px 40px" }}>
														{this.state.errorMsj ? (
															this.state.errorMsj
														) : (
															"Hubo un error, por favor inténtelo nuevamente."
														)}
													</Typography>
												</Paper>
											</Grid>
										</Grid>

										<Box style={{ display: 'flex', justifyContent: 'center', padding: "20px 0" }}>
											<Button startIcon={<Icon>home</Icon>} variant="contained" color="primary" component={LinkButton} >Inicio</Button>
										</Box>
									</>
								}
							</React.Fragment>
						)}


					</Paper>
					<br />
					<Copyright />
				</Container>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = reducers => {

	return reducers;
};

const mapDispatchToProps = dispatch => {
	return {
		getUploadReintegro: (form) => dispatch({ type: "GET_UPLOAD_REINTEGRO", form }),
		postUploadPendingDoc: (formData) => dispatch({ type: "POST_UPlOAD_PENDING", formData }),
		patchUploadPendingDoc: (form) => dispatch({ type: "PATCH_UPlOAD_PENDING", form }),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(StepsUpload)