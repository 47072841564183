import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* office(actions) {
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/office/' : config.credentials.url + '/office/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)
        let typeOffice = (actions.type === "GET_OFFICE") ? "OFFICE_RESPONSE" : "OFFICE_PARENT_RESPONSE"
        yield put({ type: typeOffice, value: response })

    } catch (error) {
        let typeOffice = (actions.type === "GET_OFFICE") ? "OFFICE_RESPONSE" : "OFFICE_PARENT_RESPONSE"
        yield put({ type: typeOffice, value: error })
    }
}

function* offices(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/office/?offset=0&limit=' + actions.limit : config.credentials.url + '/office/' + actions.query

        //console.time('t2')
        const response = yield call(apiCall, 'get', url, requestOptions)
        //console.timeEnd('t2')
        //console.log(response)
        yield put({ type: "OFFICES_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "OFFICES_RESPONSE_LIST", value: error })
    }
}

function* hashExportOffices(actions) {
    try {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${actions.access}`,
        };
        const requestOptions = {
            headers: headers,
        };

        let url =
            actions.query === null
                ? config.credentials.url + '/office_report/?offset=0&limit=1000000'
                : config.credentials.url +
                '/office_report/' +
                actions.query +
                '&offset=0&limit=100000';

        const response = yield call(apiCall, 'get', url, requestOptions);
        //saveAs(response.message, "filter_Offices.xlsx")
        yield put({ type: 'HASH_EXPORT_OFFICES_RESPONSE', value: response });
    } catch (error) {
        yield put({ type: 'HASH_EXPORT_OFFICES_RESPONSE', value: error });
    }
}

function* checkExportOffices(actions) {
    try {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${actions.access}`,
        };
        const requestOptions = {
            headers: headers,
        };

        const response = yield call(
            apiCall,
            'GET',
            config.credentials.url + '/report/' + actions.hash + '/',
            requestOptions
        );

        yield put({ type: 'REPORT_EXPORT_OFFICES_RESPONSE', value: response });
    } catch (error) {
        yield put({ type: 'REPORT_EXPORT_OFFICES_RESPONSE', value: error });
    }
}

function* updateExportOffices(actions) {
    try {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${actions.access}`,
        };
        const requestOptions = {
            headers: headers,
        };

        const response = yield call(
            apiCall,
            'PUT',
            config.credentials.url + '/report/' + actions.hash + '/',
            requestOptions
        );

        yield put({ type: 'UPDATE_EXPORT_OFFICES_RESPONSE', value: response });
    } catch (error) {
        yield put({ type: 'UPDATE_EXPORT_OFFICES_RESPONSE', value: error });
    }
}

function* add(actions) {
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const officeResponse = yield call(apiCall, 'POST', config.credentials.url + '/office/', requestOptions);

        if (officeResponse.id !== undefined) {
            let sperta = actions.dataSpertaPayments["sperta"];
            sperta["office"] = officeResponse.id;
            const requestOptionsSperta = {
                headers: headers,
                body: JSON.stringify(sperta)
            };
            //var spertaResponse = yield call(apiCall, 'POST',config.credentials.url+'/office_sperta/', requestOptionsSperta);

            let karing = actions.dataSpertaPayments["karing"];
            karing["office"] = officeResponse.id;
            const requestOptionsKaring = {
                headers: headers,
                body: JSON.stringify(karing)
            }; //console.log(karing);
            //     var karingResponse = yield call(apiCall, 'POST',config.credentials.url+'/office_karing/', requestOptionsKaring);

            console.log(actions.dataSpertaPayments["payments"]);

            let payments = actions.dataSpertaPayments["payments"];
            payments["office"] = officeResponse.id;
            const requestOptionsPayments = {
                headers: headers,
                body: JSON.stringify(payments)
            }; //console.log(payments);

            const spertaResponse = yield call(apiCall, 'POST', config.credentials.url + '/office_sperta/', requestOptionsSperta);

            const paymentsResponse = yield call(apiCall, 'POST', config.credentials.url + '/office_payments/', requestOptionsPayments);

            const karingResponse = yield call(apiCall, 'POST', config.credentials.url + '/office_karing/', requestOptionsKaring);

            if (officeResponse.detail !== undefined && (spertaResponse.detail !== undefined || karingResponse.detail !== undefined) && paymentsResponse.detail !== undefined) {
                officeResponse["detail"] = "Hubo un error";
            }
            //if(officeResponse.detail !== undefined && spertaResponse.detail !== undefined && paymentsResponse.detail !== undefined){
            //    officeResponse["detail"] = "Hubo un error";
            //}

            yield put({ type: "OFFICE_RESPONSE", value: officeResponse })
        }



    } catch (error) {
        yield put({ type: "OFFICE_RESPONSE", value: error })
    }
}

function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };


        const response = yield call(apiCall, 'delete', config.credentials.url + '/office/' + actions.data + '/', requestOptions)


        yield put({ type: "OFFICE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "OFFICE_RESPONSE", value: error })
    }
}

function* duplicate(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/duplicate_office/?office_template=' : config.credentials.url + '/duplicate_office/?office_template=' + actions.data

        const response = yield call(apiCall, 'get', url, requestOptions)
        
        yield put({ type: "DUPLICATE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "DUPLICATE_RESPONSE", value: error })
    }
    
}

function* addDuplicate(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const officeResponse = yield call(apiCall, 'POST', config.credentials.url + '/duplicate_office/', requestOptions);

        yield put({ type: "OFFICE_RESPONSE", value: officeResponse })

    } catch (error) {
        yield put({ type: "OFFICE_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const officeResponse = yield call(apiCall, 'put', config.credentials.url + '/office/' + actions.id + '/', requestOptions)

        if (officeResponse.id !== undefined) {

            let sperta = actions.dataSpertaPayments["sperta"];
            sperta["office"] = officeResponse.id;
            const requestOptionsSperta = {
                headers: headers,
                body: JSON.stringify(sperta)
            };
            //var spertaResponse = yield call(apiCall, 'put',config.credentials.url+'/office_sperta/'+officeResponse.sperta.id+'/', requestOptionsSperta);


            let karing = actions.dataSpertaPayments["karing"];
            karing["office"] = officeResponse.id;
            const requestOptionsKaring = {
                headers: headers,
                body: JSON.stringify(karing)
            };
            // var karingResponse = yield call(apiCall, 'POST',config.credentials.url+'/office_karing/'+officeResponse.karing.id+'/', requestOptionsKaring);

            console.log(actions.dataSpertaPayments["payments"]);
            let payments = actions.dataSpertaPayments["payments"];
            payments["office"] = officeResponse.id;
            const requestOptionsPayments = {
                headers: headers,
                body: JSON.stringify(payments)
            };

            const spertaResponse = yield call(apiCall, 'put', config.credentials.url + '/office_sperta/' + officeResponse.sperta.id + '/', requestOptionsSperta);
            const paymentsResponse = yield call(apiCall, 'put', config.credentials.url + '/office_payments/' + officeResponse.payment.id + '/', requestOptionsPayments);
            const karingResponse = yield call(apiCall, 'put', config.credentials.url + '/office_karing/' + officeResponse.karing.id + '/', requestOptionsKaring);

            // if(officeResponse.detail !== undefined && (spertaResponse.detail !== undefined || karingResponse.detail !== undefined) && paymentsResponse.detail !== undefined){
            //     officeResponse["detail"] = "Hubo un error";
            // }
            if (officeResponse.detail !== undefined && spertaResponse.detail !== undefined && paymentsResponse.detail !== undefined) {
                officeResponse["detail"] = "Hubo un error";
            }

            yield put({ type: "OFFICE_RESPONSE", value: officeResponse })
        }

    } catch (error) {
        yield put({ type: "OFFICE_RESPONSE", value: error })
    }
}

function* sperta(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/office_sperta/' : config.credentials.url + '/office_sperta/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "SPERTA_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "SPERTA_RESPONSE", value: error })
    }
}

function* karing(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/office_karing/' : config.credentials.url + '/office_karing/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "KARING_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "KARING_RESPONSE", value: error })
    }
}

function* payment(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/office_payments/' : config.credentials.url + '/office_payments/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "PAYMENT_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PAYMENT_RESPONSE", value: error })
    }
}

function* paymentHistory(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const response = yield call(apiCall, 'post', config.credentials.url + '/review/', requestOptions)

        yield put({ type: "PAYMENT_RESPONSE_HISTORY", value: response })

    } catch (error) {
        yield put({ type: "PAYMENT_RESPONSE_HISTORY", value: error })
    }
}


function* aditionalOffices(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/aditional_office/?offset=0&limit=' + actions.limit : config.credentials.url + '/aditional_office/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "ADITIONAL_OFFICE_LIST_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "ADITIONAL_OFFICE_LIST_RESPONSE", value: error })
    }
}


function* addAditional(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const response = yield call(apiCall, 'POST', config.credentials.url + '/aditional_office/', requestOptions);

        yield put({ type: "ADITIONAL_OFFICE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "ADITIONAL_OFFICE_RESPONSE", value: error })
    }
}

function* editAditional(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const response = yield call(apiCall, 'put', config.credentials.url + '/aditional_office/' + actions.id + '/', requestOptions)

        yield put({ type: "ADITIONAL_OFFICE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "ADITIONAL_OFFICE_RESPONSE", value: error })
    }
}

function* removeAditional(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'delete', config.credentials.url + '/aditional_office/' + actions.data + '/', requestOptions)

        yield put({ type: "ADITIONAL_OFFICE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "ADITIONAL_OFFICE_RESPONSE", value: error })
    }
}

function* officesLimited(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/office_name_id/?offset=0&limit=' + actions.limit : config.credentials.url + '/office/' + actions.query

        
        //console.time("t1");
        const response = yield call(apiCall, 'get', url, requestOptions)
        //console.timeEnd("t1");
        yield put({ type: "OFFICES_RESPONSE_LIST_LIMITED", value: response })

    } catch (error) {
        yield put({ type: "OFFICES_RESPONSE_LIST_LIMITED", value: error })
    }
}


export function* getOffice() {
    yield takeEvery("GET_OFFICE", office)
}

export function* getOfficeParent() {
    yield takeEvery("GET_OFFICE_PARENT", office)
}

export function* getOfficesList() {
    yield takeEvery("GET_OFFICES", offices)
}

export function* addOffice() {
    yield takeEvery("ADD_OFFICE", add)
}

export function* removeOffice() {
    yield takeEvery("REMOVE_OFFICE", remove)
}

export function* duplicateOffice() {
    yield takeEvery("GET_DUPLICATE_OFFICE", duplicate)
}

export function* addDuplicateOffice() {
    yield takeEvery("ADD_DUPLICATE_OFFICE", addDuplicate)
}


export function* editOffice() {
    yield takeEvery("EDIT_OFFICE", edit)
}

export function* getSperta() {
    yield takeEvery("GET_SPERTA_OFFICE", sperta)
}

export function* getKaring() {
    yield takeEvery("GET_KARING_OFFICE", karing)
}

export function* getPayment() {
    yield takeEvery("GET_PAYMENT_OFFICE", payment)
}

export function* getPaymentHistory() {
    yield takeEvery("GET_PAYMENT_HISTORY_OFFICE", paymentHistory)
}

export function* getAditionalOffices() {
    yield takeEvery("GET_ADITIONAL_OFFICES", aditionalOffices)
}

export function* addAditionalOffice() {
    yield takeEvery("ADD_ADITIONAL_OFFICE", addAditional)
}

export function* editAditionalOffice() {
    yield takeEvery("EDIT_ADITIONAL_OFFICE", editAditional)
}

export function* removeAditionalOffice() {
    yield takeEvery("REMOVE_ADITIONAL_OFFICE", removeAditional)
}

export function* getHashExportOfficesList() {
    yield takeEvery('GET_HASH_EXPORT_OFFICES', hashExportOffices);
}

export function* checkExportOfficesList() {
    yield takeEvery('CHECK_EXPORT_OFFICES', checkExportOffices);
}

export function* updateExportOfficesList() {
    yield takeEvery('UPDATE_EXPORT_OFFICES', updateExportOffices);
}

export function* getOfficeListLimited() {
    yield takeEvery("GET_OFFICES_LIMITED", officesLimited)
}