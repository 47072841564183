import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';

function* paymentDetail(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
		};

		let url =
			actions.query === null
				? config.credentials.url + '/payment_detail/'
				: config.credentials.url + '/payment_detail/' + actions.query;
				
		const response = yield call(apiCall, 'get', url, requestOptions);

		console.log(response)

		yield put({ type: 'PAYMENT_DETAIL_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'PAYMENT_DETAIL_RESPONSE', value: error });
	}
}

export function* getPaymentDetail() {
    yield takeEvery("GET_PAYMENT_DETAIL", paymentDetail)
}

