const initialState = {
    responseBlacklist: [],
    responsePassengerBlacklist: [],
    errorBlacklist: false
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "BLACKLIST_RESPONSE":
            newState.responseBlacklist = action.value;
            break;
        case "PAX_BLACKLIST_RESPONSE":
            newState.responsePassengerBlacklist = action.value;
            break;
            
        default: 
            return newState;
    }

    if (action.value.detail !== undefined){
        newState.errorBlacklist= true
    }else{
        newState.errorBlacklist= false
    }


    return newState;
};

export default reducer;