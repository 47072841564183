import permissions_by_group from '../config/permissions_by_group.json'

 /*
  * Verifica a que campos del formulario tiene permiso el usuario de todos los posibles que hay en el config.json
  * para un determinado TableList en base a sus permisos (es decir, los campos que vengan en el response).
  *  @param   {string}   field      Campo que se quiera mostrar en la View, es el nombre que debe tener en el response.
  *  @param   {array}    data       Respuesta del endpoint que contiene solo los campos (fields) que puede ver el usuario
  *  @param   {string}   view       Vista en la que se encuentra (create, edit, show, etc.)
 *  @return  {boolean}
  */
export function enabledField(field, data, view, active=true){
    let result = false

    if (view == "create" || !active){  //Si la vista es create o Si no está activada la verificacion de permisos, se inserta el campo.
        result = true
    }else{                  //Si la vista es edit  o show, verifica si el usuario tiene permisos para ver el campo
            let levelField = field.split('.');  // Ejemplo: field=pax.name  levelField=['pax', 'name']
            let fieldFound = false
            let pos = 0
            let object = data
            while (!fieldFound && pos<levelField.length){
                if(object[levelField[pos]]!== undefined){
                    object = object[levelField[pos]]
                    if (pos == levelField.length-1){ // Si esta en el ultimo lugar, entonces encontro el field.
                        fieldFound = true   //encontrado
                    }
                }else{
                    fieldFound=false //no encontrado
                }
                pos++
            }

            result = fieldFound
    }
    return result
}



 /*
  * Verifica a que headers tiene permiso el usuario de todos los posibles que hay en el config.json para un TableList.
  * en base a sus permisos.
  *  @param   {array}   headers      Headers posibles para un determinado TableList.
  *  @param   {array}   repsonse     Respuesta del endpoint que contiene solo los campos (headers) que puede ver el usuario
  *  @return  {array}
  */
 export function enabledTableHeaders(headers, response){
    let result = []
    let headersResponse =  response.length>0 ? Object.keys(response[0]) : []
    if (response.length>0){ //si no hay registros, no mostrara headers.
        headers.forEach( header =>{
            if (headersResponse.indexOf(header.key)!=-1){
                result.push(header.label)
            }
        })
    }
    //console.log("headers permitidos", result);
    return result
 }

 /*
  * Verifica si un valor se encuentra dentro de un array de permisos.
  * Se recorre en esta funcion ya que el permissionItems no es un json clave:valor,
  * si no un array de objetos que dentro tienen las clave:valor que buscamos.
  *  @param   {array}   permissionsItems      Array de objetos con los items que tiene habilitados ese usuario.
  *  @param   {array}   value                 Valor a buscar en el array
  *  @return  {boolean}
  */
export function isPermissionEnabled(permissionsItems, value) {               // Ejemplo:
    if (value.indexOf('/')!==-1){                                       // value = user/view
        let permissionStr  = value.split('/');
        let permissionFather = permissionStr[0];                        // user
        let permissionAction = permissionStr[1];                        // view
        //verifico que tenga el permisoPadre
        var result = []
        if (permissionsItems !== null){
            result = permissionsItems.filter(function(permission) {
                return Object.keys(permission)[0]  == permissionFather;
            });
        }
        //console.log("permission result", result)
        if (result.length>0){ //Si tiene el permiso padre
            if (result[0][permissionFather].indexOf(permissionAction)!==-1){  // verifico que la accion (add, view, etc.) esté dentro del permiso padre.
                return true
            }else {
                return false
            }
        }else{ // Si NO tiene el permiso padre, tampoco tendrá la accion.
            return false
        }
    }else{
        var result = []
        if (permissionsItems !== null){
            result = permissionsItems.filter(function(permission) {
                return Object.keys(permission)[0]  == value;
            });
        }
        if (result.length>0){
            return true
        }else {
            return false
        }
    }
}

/*
* Recorre config.json recursivamente y devuelve los items que puede ver el usuario segun sus permisos.
* Los items del config.json que no tengan el atributo permissionName ni submenu, iran a la interfaz directamente
* ya que no dependen de ningun permiso para realizar su funcionalidad. Ejemplo: item Dashboard.
*  @param   {array}   configItems         array de objetos con todos los items y submenus posibles.
*  @param   {array}   permissionsItems    array de objetos con los items que tiene habilitado ese usuario.
*  @return  {array}
*/
export function enabledPermissions(configItems, permissionsItems) {
    let enabledItems = [];
    configItems.forEach( item => {
        if (!item.permissionName){ // Si NO tiene permissionName
            if (!item.submenu) { // Si NO tiene submenu
                enabledItems.push(item);
            } else{ // Si tiene submenu
                let submenu = enabledPermissions(item.submenu, permissionsItems)
                if (submenu.length>0){ //Lo agrego SOLO si algun item del submenu tiene los permisos.
                  enabledItems.push(item);
                  enabledItems[enabledItems.length - 1].submenu = submenu
                }
          }
        }else{ // si tiene permissionName
            if (isPermissionEnabled(permissionsItems, item.permissionName)) { // verifico si el usuairo posee ese permiso
                enabledItems.push(item);
                if (item.submenu) { // si tiene submenu
                    enabledItems[enabledItems.length - 1].submenu = enabledPermissions(item.submenu, permissionsItems)
                }
            }
        }
    })
    //console.log("lista permissionName final", enabledItems);
    return enabledItems
}


/*
* Verifica si el usuario tiene habilitado editar un campo de una entidad en base al grupo
* al que pertenezca y a la configuracion del archivo permissions_by_group.json.
*  @param   {string}   field      Campo a verificar si es editable o no.
*  @param   {string}   data       Grupo al que pertenece el usuario logeado
*  @param   {string}   view       Vista a la que se accedio (show, create, edit)
*  @param   {string}   entity     Entidad a la que pertenece la vista (Voucher, Office, etc.)
*  @return  {boolean}             True esta habilitado, False esta deshabilitado.
*/
export function enabledFieldByGroup(field, data, view, entity){

    if (data == undefined || data.user_group == undefined) {
        let groupResult = permissions_by_group.filter(g => g.id == data);
        if (groupResult.length>0){
            let entityResult = groupResult[0].entity.filter(e => e.value == entity)
            if (entityResult.length > 0){
                let viewResult = entityResult[0].views.filter(v => v.value == view)

                    if (viewResult.length>0){
                        let fieldResult = viewResult[0].fields.indexOf(field)
                        if (fieldResult!==-1){
                            return true
                        }
                    }
                }
        }
        return false
    } else {
        let groupResult = permissions_by_group.filter(g => g.group == data.user_group);
        if (groupResult.length>0){
            let entityResult = groupResult[0].entity.filter(e => e.value == entity)
            if (entityResult.length > 0){
                let viewResult = entityResult[0].views.filter(v => v.value == view)
                if (viewResult.length>0){
                    let fieldResult = viewResult[0].fields.indexOf(field)
                    if (fieldResult!==-1){
                        return true
                    }
                }
            }
        }
    }
}

