const initialState = {
    responseTagsList: [],
    responseTag: []
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "TAGS_RESPONSE_LIST":
            
            if (action.value.detail !== undefined)
                newState.responseTagsList = []
            else
                newState.responseTagsList = action.value;

            newState.responseTag = []
            break;
        case "TAG_RESPONSE":
      
            newState.responseTag = action.value;
      
            break;
            
        default: 
            return newState;
    }

    return newState;
};

export default reducer;