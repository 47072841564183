const initialState = {
    responseOfficesList: [],
    responseOffice: [],
    responseOfficeParent: [],
    responseSperta: [],
    responseKaring: [],
    responseDuplicate: [],
    responsePayment: [],
    responseTagsList: [],
    responseOfficeHistory: [],
    responseAditionalOffice: [],
    responseAditionalOfficesList: [],
    errorOffice: false,
    errorOfficeList: false,

    responseHashExportOffices: [],
    responseUpdateReportOffices: [],
    responseCheckReportOffices: [],
    errorHashExportOffices: false,
    errorCheckReportOffices: false,
    errorUpdateReportOffices: false
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "OFFICES_RESPONSE_LIST":
            if (action.value.detail !== undefined) {
                newState.errorOfficeList = true;
            } else {
                newState.errorOfficeList = false;
            }
            newState.responseOfficesList = action.value;
            break;
        case "OFFICE_RESPONSE":
            newState.responseOffice = action.value;
            if (action.value.detail !== undefined) {
                newState.errorOffice = true;
            } else {
                newState.errorOffice = false;
            }
            break;
        case "OFFICE_PARENT_RESPONSE":
            newState.responseOfficeParent = action.value;
            break;
        case "ADITIONAL_OFFICE_RESPONSE":
            newState.responseAditionalOffice = action.value;
            break;
        case "ADITIONAL_OFFICE_LIST_RESPONSE":
            newState.responseAditionalOfficeList = action.value;
            break;
        case "PAYMENT_RESPONSE_HISTORY":
            newState.responseOfficeHistory = action.value;
            break;
        case "DUPLICATE_RESPONSE":
            newState.responseDuplicate = action.value;
            break;    
        case "SPERTA_RESPONSE":
            newState.responseSperta = action.value;
            break;
        case "KARING_RESPONSE":
            newState.responseKaring = action.value;
            break;
        case "PAYMENT_RESPONSE":
            newState.responsePayment = action.value;

            break;
        case "TAGS_RESPONSE_LIST":
            if (action.value.detail !== undefined)
                newState.responseTagsList = []
            else
                newState.responseTagsList = action.value;
            break;
        case 'HASH_EXPORT_OFFICES_RESPONSE':
            newState.responseHashExportOffices = action.value;
            if (action.value.detail !== undefined) {
                newState.errorHashExportOffices = true;
            } else {
                newState.errorHashExportOffices = false;
            }
            break;
        case 'REPORT_EXPORT_OFFICES_RESPONSE':
            newState.responseCheckReportOffices = action.value;
            if (action.value.detail !== undefined) {
                newState.errorCheckReportOffices = true;
            } else {
                newState.errorCheckReportOffices = false;
            }
            break;
        case 'UPDATE_EXPORT_OFFICES_RESPONSE':
            newState.responseUpdateReportOffices = action.value;
            if (action.value.detail !== undefined) {
                newState.errorUpdateReportOffices = true;
            } else {
                newState.errorUpdateReportOffices = false;
            }
            break;
        case "OFFICES_RESPONSE_LIST_LIMITED":
            if (action.value.detail !== undefined) {
                newState.errorOfficeList = true;
            } else {
                newState.errorOfficeList = false;
            }
            newState.responseOfficesList = action.value;
            break;    
        default:
            return newState;
    }

    return newState;
};

export default reducer;