import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* coverage(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/coverage/' : config.credentials.url + '/coverage/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "COVERAGE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "COVERAGE_RESPONSE", value: error })
    }
}

function* coverages(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/coverage/?offset=0&limit='+actions.limit : config.credentials.url + '/coverage/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "COVERAGES_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "COVERAGES_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/coverage/', requestOptions);

      

        yield put({ type: "COVERAGE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "COVERAGE_RESPONSE", value: error })
    }
}


function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/coverage/' + actions.data+ '/', requestOptions)


        yield put({ type: "COVERAGE_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "COVERAGE_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/coverage/' + actions.id+ '/', requestOptions)

        yield put({ type: "COVERAGE_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "COVERAGE_RESPONSE", value: error })
    }
}


export function* getCoverage() {
    yield takeEvery("GET_COVERAGE", coverage)
}
export function* getCoveragesList() {
    yield takeEvery("GET_COVERAGES", coverages)
}

export function* addCoverage() {
    yield takeEvery("ADD_COVERAGE", add)
}

export function* removeCoverage() {
    yield takeEvery("REMOVE_COVERAGE", remove)
}

export function* editCoverage() {
    yield takeEvery("EDIT_COVERAGE", edit)
}
