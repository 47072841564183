import React, { Component, Fragment } from 'react';
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, TextField, Icon } from '@material-ui/core'
import Dividers from '../../common/fields/Dividers';
import Background from '../../../assets/images/product-image-placeholder.jpg';
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import ModalRefund from './RefundModal';
import { getMonedas, getMonedasDefault } from '../../../services/InfoFormService';

class RefundSelection extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {},
			coberturas: {},
			openCargaCobertura: [],
			refund_coverage: [],
			selectedFileDefault: Background,
			currentCargaCobertura: null,
			openedCargaCoberturaModal: false,
			openCargaCoberturaCallback: false,
			saveRefund: false,
			msjErrorProx: false,

			getCoberturasSent: false,
			initial: false,
			// espera de repsuestas
			getInfoCoberturasLoginSent: false,
			//aux
			responsesReceived: [],
			comment: ''
		};
		this.expectedResponses = ["getCoberturasLogin"]
	}

	handleSubmit = () => {
		let responseStatus = [];
		let responseData = [];
		let scrollPersonal = false;

		if (Object.keys(this.state.refund_coverage).length > 0) {
			this.setState({ msjErrorProx: false });
			responseData["refund_coverage"] = this.state.refund_coverage
			responseData["refund_coverage"]["comment"] = this.state.comment
			return { 'continue': true, 'data': responseData, 'createRefund': false };
		} else {
			this.setState({ msjErrorProx: true });
			return { 'continue': false, 'data': [], 'createRefund': false, "view": this.props.view };
		}
	}

	handleSubmitAnterior = () => {
		let responseStatus = [];
		let responseData = [];
		let scrollPersonal = false;

		responseData["refund_coverage"] = this.state.refund_coverage
		responseData["refund_coverage"]["comment"] = this.state.comment
		return { 'continue': true, 'data': responseData, 'createRefund': false };
	}

	checkResponsesReceived = () => {
		let allReceived = true;
		let pos = 0;
		while ((pos < this.expectedResponses.length) && allReceived) {
			if (this.state.responsesReceived.indexOf(this.expectedResponses[pos]) === -1) {
				allReceived = false;
			}
			pos = pos + 1;
		}
		return allReceived
	}

	addToResponsesReceived = (response) => {
		let responsesAux = this.state.responsesReceived;
		if (responsesAux.indexOf(response) === -1) {
			responsesAux.push(response);
			this.setState({ responsesReceived: responsesAux });
		}
	}

	componentDidMount() {
		let form = {
			documento: this.props.location.state.loginResponse.refunds.doc_number,
			voucherId: this.props.location.state.loginResponse.refunds.voucher
		}
		this.props.getLoginReintegro(form);
		this.setState({
			getInfoLoginSent: true,
		})

		if (Object.keys(this.props.infoStep).length > 0) {
			let arrayStep = []
			this.props.infoStep.map(step => {
				if (step.step === 1) {
					arrayStep.push(step.info)
				}
			})

			if (Object.keys(arrayStep).length > 0) {
				this.setState({
					saveRefund: true,
				})
			}
		}
	}

	UNSAFE_componentWillReceiveProps(next_props) {

		if (Object.keys(next_props.responseDataLoginReintegro).length > 0 && !this.state.initial) {
			this.setState({
				data: next_props.responseDataLoginReintegro.refunds,
			})
		}

		if (this.state.getInfoLoginSent && (this.props.responseDataLoginReintegro !== next_props.responseDataLoginReintegro)) {
			this.setState({ getInfoLoginSent: false })
			this.addToResponsesReceived("getCoberturasLogin");
		}

		if (Object.keys(next_props.infoStep).length > 0 && this.state.saveRefund) {

			let arrayStep = []
			next_props.infoStep.map(step => {
				if (step.step === 1) {
					arrayStep.push(step.info)
				}
			})

			let ultimaActuzalicion = Object.keys(arrayStep)[Object.keys(arrayStep).length - 1]
			this.setState({
				refund_coverage: arrayStep[ultimaActuzalicion].refund_coverage,
				comment: arrayStep[ultimaActuzalicion].refund_coverage.comment
			})
		}
	}

	handleOpenCargaCobertura = (voucher) => {
		let openCargaCoberturaAux = this.state.openCargaCobertura;
		openCargaCoberturaAux[voucher] = true;
		this.setState({ openCargaCobertura: openCargaCoberturaAux });
		this.setState({ currentCargaCobertura: voucher });
		this.setState({ openedCargaCoberturaModal: true });
	};

	handleCloseCargaCobertura = (voucher) => {
		let openCargaCoberturaAux = this.state.openCargaCobertura;
		openCargaCoberturaAux[voucher] = false;
		this.setState({
			openCargaCobertura: openCargaCoberturaAux,
			openedCargaCoberturaModal: false,
		});
	};

	callbackCargaCobertura = () => {
		this.setState({ openCargaCoberturaCallback: true });
	};

	handleCarga = (voucher) => {
		const responseChilComponent = this.cargaCober.submitCobertura();
		const uploadedCoberturas = [...this.state.refund_coverage];

		if (responseChilComponent.status) {
			this.handleCloseCargaCobertura(voucher)
			if (this.state.openedCargaCoberturaModal) {
				uploadedCoberturas.push(responseChilComponent.data.cobertura.data)
				this.setState({
					refund_coverage: uploadedCoberturas
				});
			}
		}
	};

	deleteSeleccionCobertura = (cobertura) => {
		const filtredData = this.state.refund_coverage.filter(coverage => coverage.coverage !== cobertura);
		this.setState({
			refund_coverage: filtredData,
		})
	}

	handlerComment = (event) => {
		this.setState({ comment: event.target.value });
	}

	render() {
		const LoaderSkeleton = (props) => {
			return (
				<ContentLoader
					height={"200"}
					width={"auto"}
					viewBox="0 0 100% 100%"
					backgroundColor="#d9d9d9"
					foregroundColor="#ecebeb"
				>

					<rect x="10%" y="0" rx="3" ry="3" width="38%" height="30" />
					<rect x="50%" y="0" rx="3" ry="3" width="38%" height="30" />

					<rect x="10%" y="50" rx="3" ry="3" width="38%" height="30" />
					<rect x="50%" y="50" rx="3" ry="3" width="38%" height="30" />


				</ContentLoader>
			)
		}

		const ModalCargaCobertura = (props) => {
			return (
				<Fragment>
					{(this.state.refund_coverage.length === 0) ?
						<Grid style={{ display: 'flex', justifyContent: 'center' }}>
							<div style={{ textAlign: "center" }}>
								<Button
									color='primary'
									className='buttonEdit'
									variant='contained'
									size='large'
									onClick={() => {
										this.handleOpenCargaCobertura(props.voucher);
									}}
								>
									Cargar reintegros
								</Button>
								{(this.state.msjErrorProx) ?
									<Typography variant='caption' component="div">
										Debe cargar al menos una cobertura para continuar.
									</Typography>
									:
									<></>}
							</div>
						</Grid>
						:
						<Grid style={{ margin: "15px" }}>
							<Grid style={{ display: 'flex', justifyContent: 'center' }}>
								<Button
									style={{ margin: 25,  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}
									color='primary'
									className='buttonEdit'
									variant='outlined'
									size='large'
									startIcon={<Icon>add</Icon>}
									onClick={() => {
										this.handleOpenCargaCobertura(props.voucher);
									}}
								>
									Agregar reintegro
								</Button>
							</Grid>
						</Grid>
					}

					<Dialog
						fullWidth
						maxWidth='md'
						onClose={() => {
							this.handleCloseCargaCobertura(props.voucher);
						}}
						data-index={props.voucher}
						aria-labelledby={props.voucher}
						open={
							this.state.openCargaCobertura.length === ''
								? false
								: this.state.openCargaCobertura[props.voucher]
						}
						className={`dialog-${props.voucher}`}
					>
						<DialogTitle
							id={this.props.voucher}
							onClose={() => {
								this.handleCloseCargaCobertura(props.voucher);
							}}
							disableTypography
							className='dialogTitle_root'
						>
							<Grid container>
								<Grid item sm={10}>
									<Typography variant='h5'>
										Carga de reintegro
									</Typography>
								</Grid>
							</Grid>
						</DialogTitle>
						<DialogContent dividers className='dialogContent_root'>
							<ModalRefund {...props}
								ref={(element) => {
									this.cargaCober = element;
								}}
								views='show'
								card='carga_reintegro'
								callbackCargaCobertura={this.callbackCargaCobertura}
							/>
						</DialogContent>
						<DialogActions className='dialogActions_root'>
							<Button
								onClick={() => {
									this.handleCarga(props.voucher);
								}}
								variant="contained"
								color="primary" >Cargar Reintegro</Button>
							<Button
								onClick={() => {
									this.handleCloseCargaCobertura(props.voucher);
								}}
								variant='outlined'
								color='primary'
							>
								Cerrar
							</Button>
						</DialogActions>
					</Dialog>
				</Fragment>
			);
		};

		return (
			<Fragment>

				<Dividers></Dividers>
				<Typography component="h3" variant="h5" style={{ margin: "0 0 20px 25px" }}>
					Selección y carga de reintegros
				</Typography>
				{(!this.checkResponsesReceived())
					? <LoaderSkeleton />
					: <>
						{(this.state.refund_coverage.length !== 0) ?
							<Grid container spacing={1} justify="center">
								{this.state.refund_coverage.map((coverage, index) => (
									<Grid item md={8} key={index} style={{ border: "1px solid #CCD1D1", borderRadius: "4px", boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .1)", margin: "15px" }}>
										<Grid item sm={12}  >
											<Typography component="h6" variant="h6" style={{ marginLeft: "5px" }}>
												{coverage.coverage.toLowerCase()}
											</Typography>
										</Grid>

										<Grid item sm={12} >
											<Typography variant="caption" style={{ marginLeft: "5px" }}>
												País del siniestro: {`${coverage.sinister_country}`}
											</Typography>
										</Grid>

										<Grid item sm={12} >
											<Typography variant="caption" style={{ marginLeft: "5px" }}>
												Fecha del siniestro: {`${coverage.sinister_date}`}
											</Typography>
										</Grid>

										<Grid item sm={12} >
											<Typography variant="caption" style={{ marginLeft: "5px" }}>
												Moneda de gasto: {`${coverage.currency}`}
											</Typography>
										</Grid>

										<Grid item sm={12} >
											<Typography variant="caption" style={{ marginLeft: "5px" }}>
												Monto Solicitado a reintegrar: {getMonedasDefault().map(moneda => {
													if (moneda.id === coverage.currency) {
														return moneda.iso
													}
												}
												)}
												{` ${coverage.amount} `}
											</Typography>
										</Grid>

										<Grid item sm={12} >
											<Typography variant="caption" style={{ marginLeft: "5px" }}>
												Archivos subidos: {`${coverage.coverage_files.length}`}
											</Typography>
										</Grid>

										<Grid item sm={12} style={{ display: 'flex', justifyContent: 'end' }}>
											<Button
												color='default'
												variant='contained'
												size='small'
												onClick={() => {
													this.deleteSeleccionCobertura(coverage.coverage);
												}}
											>
												Remover
											</Button>
										</Grid>
									</Grid>
								))}
							</Grid>
							:
							<></>
						}

						<Grid container spacing={3} className="main_admin" alignItems="center" justify="center">
							<Grid item sm={8} style={{ padding: "25px 0" }}>
								<ModalCargaCobertura
									dataCobertura={this.state.data}
									dataCargada={this.state.refund_coverage}
								/>

							</Grid>
						</Grid>
						{this.state.refund_coverage.length > 0 &&
							<>
								<Grid container spacing={1} alignItems="center" justify="center">
									<Grid item sm={8} >
										<p component="h4" variant="h6" >
											¿Desea agregar un comentario adicional? (*opcional)
										</p>
										<FormControl fullWidth>
											<TextField
												id={`comment`}
												label="Comentario"
												type="text"
												value={this.state.comment}
												disabled={false}
												onChange={this.handlerComment}
												variant="outlined"
												multiline
												rows={2}
												inputProps={{ maxLength: 255 }}
											/>
										</FormControl>
									</Grid>
								</Grid>
								<Grid container spacing={1} alignItems="center" justify="center">
									<Grid item style= {{ margin: "10px 15px 0 15px"}}>
										<Typography variant='caption'>
											Una vez finalizada la carga de solicitudes de reintegros, seleccione CONTINUAR.
										</Typography>
									</Grid>
								</Grid>
							</>
						}
					</>}
			</Fragment >
		);
	}
}

const mapStateToProps = reducers => {
	return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
	return {
		//getLogin: (form) => dispatch({ type: "GET_LOGIN", form }),
		getLoginReintegro: (form) => dispatch({ type: "GET_LOGIN_REINTEGRO", form }),
	};
};

export default connect(
	mapStateToProps,
	mapDispachToProps,
	null,
	{ forwardRef: true }
)
	(RefundSelection)


