import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* products(actions) {

    try {
       // console.log("entro en la funcion correcta")
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/product/?offset=0&limit='+actions.limit : config.credentials.url + '/product/' + actions.query

        const dataProducts = yield call(apiCall, 'get', url, requestOptions)

        //console.log("acciones",actions)
        if(actions.view === "delete"){ 
            yield put({ type: "PRODUCTS_RESPONSE", response: dataProducts, view:actions.view, responseDelete:actions.responseDelete, id:actions.id })
        }else{
            yield put({ type: "PRODUCTS_RESPONSE", response: dataProducts, view:actions.view })
        }
       

    } catch (error) {
        yield put({ type: "PRODUCTS_RESPONSE", response: error ,view:actions.view})
    }
}

function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/product/' + actions.id+ '/', requestOptions)


       actions.view = "delete";
       actions.responseDelete = response;
       actions.query = null;
       yield products(actions);
 
    } catch (error) {
        console.log(error)
        yield put({ type: "PRODUCT_RESPONSE_REMOVE", value: error })
    }
}

function* productsReducida(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/product_name_id/?offset=0&limit='+actions.limit : config.credentials.url + '/product_name_id/' + actions.query

        const dataProducts = yield call(apiCall, 'get', url, requestOptions)

        //console.log("acciones",actions)
        if(actions.view === "delete"){ 
            yield put({ type: "PRODUCTS_RESPONSE_REDUCIDA", response: dataProducts, view:actions.view, responseDelete:actions.responseDelete, id:actions.id })
        }else{
            yield put({ type: "PRODUCTS_RESPONSE_REDUCIDA", response: dataProducts, view:actions.view })
        }
       

    } catch (error) {
        yield put({ type: "PRODUCTS_RESPONSE_REDUCIDA", response: error ,view:actions.view})
    }
}

export function* getProductsList() {
    yield takeEvery("GET_PRODUCTS", products)
}

export function* removeProduct() {
    yield takeEvery("REMOVE_PRODUCT", remove)
}

export function* getProductsListReducida() {
    yield takeEvery("GET_PRODUCTS_REDUCIDA", productsReducida)
}
