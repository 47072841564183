import React, { Component } from "react";
import { Container, CssBaseline, AppBar, Box, Toolbar, Paper, Stepper, Step, StepLabel, Button, Link, Typography, Icon } from '@material-ui/core';
import ContentLoader, { List } from "react-content-loader"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/images/logo-color.png';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PersonalInformation from '../forms/reintegros/PersonalInformationForm';
import RefundSelection from '../forms/reintegros/RefundSelectionForm';
import PaymentForm from "../forms/reintegros/PaymentForm";


function Copyright() {
	return (
		<Typography variant="body2" align="center">
			© Safe Travel assistance una marca de assist med s.r.l - Todos los derechos reservados.
		</Typography>
	);
}

const theme = createMuiTheme();

class Steps extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			skipped: new Set(),
			responseProduct: [],
			disabledBoton: false,
			dataStep1: {},
			errorCreacion: false,
			loader: false,
			logged: false,
			redirectFinal: null
		}
		this.steps = ['Datos personales', 'Carga de reintegros', 'Datos del pago'];
		this.access = window.localStorage.getItem('headersSetCookie');
	}

	isStepOptional = (step) => {
		return step === 1;
	};

	getStepContent(step, props) {
		switch (step) {
			case 0:
				return (<PersonalInformation  {...props} ref={element => { this.component = element }} />);
			case 1:
				return (<RefundSelection {...props} ref={element => { this.component = element }} />);
			case 2:
				return (<PaymentForm {...this.props} ref={element => { this.component = element }} />);
			default:
				return (<div></div>);
		}
	}

	isStepSkipped = (step) => {

		return this.state.skipped.has(step);
	};

	UNSAFE_componentWillMount() {
		if (this.props.location.state === undefined || this.props.location.state.loginResponse === undefined) {
			this.setState({ logged: true })
		}
		//Se dispara cuando en el roting, viene del editar/id o ver/id.Obtengo los datos del producto
		if (this.props.id !== undefined) {
			this.props.getProductsList(this.access, this.props.id, this.props.views)
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {

		if (nextProps.status === "SUCCESS") {
			this.setState({ activeStep: this.state.activeStep + 1, })
		} else {
			console.log("NO PUEDE AVANZAR", this.props.status);
		}

		if (this.props.completedForm !== nextProps.completedForm) {
			if (nextProps.completedForm !== false) {
				let responseRedirectFinal = null
				if (nextProps.errorCreateRefund !== false) {
					this.setState({ errorCreacion: true, loader: true, redirectFinal: 'https://safetravelassistance.com/' })
					responseRedirectFinal = 'https://safetravelassistance.com/'
				} else {
					this.setState({ errorCreacion: false, loader: true })
					if (nextProps.responseCreate.refund.url_country_b2c !== null) {
						responseRedirectFinal = nextProps.responseCreate.refund.url_country_b2c
						this.setState({ redirectFinal: nextProps.responseCreate.refund.url_country_b2c })
					} else {
						responseRedirectFinal = 'https://safetravelassistance.com/'
						this.setState({ redirectFinal: 'https://safetravelassistance.com/' })
					}
				}
				this.props.getLogoutReintegro()
				setTimeout(function(){window.location.href=responseRedirectFinal} , 20000);   
			}
		}

		if (nextProps.finalStep === true && nextProps.finalStep !== this.props.finalStep) {
			let step1 = {};
			let step2 = {};
			let refund_coverage = [];
			let step3 = {};
			let totalFiles = [];
			const dataFinal = {}

			const formData = new FormData()

			if (Object.keys(this.props.infoStep).length > 0) {
				let arrayStep1 = []
				nextProps.infoStep.map(step => {
					if (step.step === 0) {
						arrayStep1.push(step.info)
					}
				})
				let ultimaActuzalicion1 = Object.keys(arrayStep1)[Object.keys(arrayStep1).length - 1]

				step1 = arrayStep1[ultimaActuzalicion1].refund.data
				dataFinal.refund = { ...step1 };
			}

			if (Object.keys(this.props.infoStep).length > 0) {

				let arrayStep2 = []
				nextProps.infoStep.map(step => {
					if (step.step === 1) {
						arrayStep2.push(step.info)
					}
				})
				let ultimaActuzalicion2 = Object.keys(arrayStep2)[Object.keys(arrayStep2).length - 1]
				step2 = arrayStep2[ultimaActuzalicion2].refund_coverage
				let step2comment = arrayStep2[ultimaActuzalicion2].refund_coverage.comment !== '' ? arrayStep2[ultimaActuzalicion2].refund_coverage.comment : null
				step2.map((coverage, i) => {
					let nameFilesCoverage = [];
					let coverageObj = { ...coverage };
					refund_coverage.push(coverageObj)
					coverageObj.coverage_files.map((fileCoverage, k) => {
						let mynewFile = new File([fileCoverage],
							`coverage-${i}-${k}-${fileCoverage.name}`, { type: fileCoverage.type });
						totalFiles.push(mynewFile)
						nameFilesCoverage.push(mynewFile.name)
					})
					refund_coverage[i].coverage_files = nameFilesCoverage
				})
				dataFinal.refund_coverage = refund_coverage;
				dataFinal.refund = {
					...step1,
					client_comment: step2comment
				};
			}

			if (Object.keys(this.props.infoStep).length > 0) {
				let arrayStep3 = []
				nextProps.infoStep.map(step => {
					if (step.step === 2) {
						arrayStep3.push(step.info)
					}
				})
				let ultimaActuzalicion3 = Object.keys(arrayStep3)[Object.keys(arrayStep3).length - 1]
				step3 = arrayStep3[ultimaActuzalicion3].refund_payment.data
				dataFinal.refund_payment = { ...step3 };
				if (step3.authorized_document) {
					let nameFilesAuthDoc = [];
					step3.authorized_document.map((filePaymentAuhDoc, j) => {
						let mynewFileDoc = new File([filePaymentAuhDoc],
							`paymentAuhDoc-${j}-${filePaymentAuhDoc.name}`, { type: filePaymentAuhDoc.type });
						totalFiles.push(mynewFileDoc)
						nameFilesAuthDoc.push(mynewFileDoc.name)
					})
					dataFinal.refund_payment.authorized_document = nameFilesAuthDoc;
				}
				if (step3.authorization_letter) {
					let nameFilesAuth = [];
					step3.authorization_letter.map((filePaymentAuh, n) => {
						let mynewFileLet = new File([filePaymentAuh],
							`paymentAuhLet-${n}-${filePaymentAuh.name}`, { type: filePaymentAuh.type });
						totalFiles.push(mynewFileLet)
						nameFilesAuth.push(mynewFileLet.name)
					})
					dataFinal.refund_payment.authorization_letter = nameFilesAuth;
				}
			}

			for (let m in totalFiles) {
				formData.append('files', totalFiles[m], totalFiles[m].name);
			}

			let dataFinalString = JSON.stringify(dataFinal)
			formData.append('data', dataFinalString)

			this.props.uploadReintegro(formData)
		}
	}

	handleNext = () => {
		const responseChilComponent = this.component.handleSubmit();

		if (responseChilComponent.continue) {
			let newSkipped = this.state.skipped;
			if (this.isStepSkipped(this.state.activeStep)) {
				newSkipped = new Set(newSkipped.values());
				newSkipped.delete(this.state.activeStep);
			}

			if (!responseChilComponent.updateProduct)
				this.setState({ activeStep: this.state.activeStep + 1, skipped: newSkipped })

			this.props.saveInfoStepRefund(responseChilComponent.data, this.state.activeStep, responseChilComponent.createRefund)

			if (responseChilComponent.updateProduct) {
				this.props.updateProduct(this.access, this.props.hashProduct, this.props.infoStep, responseChilComponent.data, this.state.activeStep)
			}
		}

	};

	handleBack = () => {
		const responseChilComponentAnteior = this.component.handleSubmitAnterior();

		if (responseChilComponentAnteior.continue) {
			this.props.saveInfoStepRefund(responseChilComponentAnteior.data, this.state.activeStep, responseChilComponentAnteior.createRefund)
		}

		this.setState({ activeStep: this.state.activeStep - 1 })

	};

	handleSkip = () => {
		if (!this.isStepOptional(this.state.activeStep)) {
			throw new Error("You can't skip a step that isn't optional.");
		}

		this.setState((prevState) => {

			const newSkipped = new Set(prevState.skipped.values());
			newSkipped.add(this.state.activeStep);
			let response = {
				activeStep: prevState.activeStep + 1,
				skipped: newSkipped
			}

			return response;
		})
	};

	render() {

		const { classes } = this.props;
		const LinkButton = React.forwardRef((props, ref) => <Link href={this.state.redirectFinal} {...props} ref={ref} />);

		const LoaderSkeleton = (props) => {
			return (
				<ContentLoader
					height={"200"}
					width={"auto"}
					viewBox="0 0 100% 100%"
					backgroundColor="#d9d9d9"
					foregroundColor="#ecebeb"
				>

					<rect x="10%" y="0" rx="3" ry="3" width="38%" height="30" />
					<rect x="50%" y="0" rx="3" ry="3" width="38%" height="30" />

					<rect x="10%" y="50" rx="3" ry="3" width="38%" height="30" />
					<rect x="50%" y="50" rx="3" ry="3" width="38%" height="30" />
				</ContentLoader>
			)
		}

		return (
			this.state.logged !== false
				? (<Redirect to={{
					pathname: "/refund",
				}} />)
				:
				<ThemeProvider theme={theme}>
					<CssBaseline />

					<AppBar
						position="relative"
						color="default"
						elevation={0}
						style={{
							position: 'relative',
							borderBottom: (t) => `1px solid ${t.palette.divider}`,
						}}
					>
						<Toolbar>
							<img src={Logo} alt='by SafeTravelAssistance' style={{ maxWidth: "230px" }} />
						</Toolbar>
					</AppBar>

					<Container style={{ paddingTop: "20px" }}>
						<Paper variant="outlined" style={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
							<Typography component="h1" variant="h4" align="center" style={{ marginTop: "20px" }}>
								Solicitud de reintegros
							</Typography>

							<Stepper activeStep={this.state.activeStep} style={{ paddingTop: "15px", paddingBottom: "15px" }}>
								{this.steps.map((label, index) => {
									const stepProps = {};
									const labelProps = {};
									if (this.isStepSkipped(index)) {
										stepProps.completed = false;
									}
									return (
										<Step key={label} {...stepProps}>
											<StepLabel {...labelProps}>{label}</StepLabel>
										</Step>
									);
								})}
							</Stepper>

							{this.state.activeStep === this.steps.length ? (
								<React.Fragment>
									{(this.state.loader !== true)
										? <LoaderSkeleton />
										: <>
											<Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', paddingTop: "40px" }}>
												{this.state.errorCreacion !== true ? (
													"¡Su solicitud ha sido enviada con éxito!"
												) : (
													"Hubo un error en la creación de su solicitud"
												)}
											</Typography>
											<Typography variant="subtitle1" style={{ display: 'flex', justifyContent: 'center', padding: "40px" }}>
												{this.state.errorCreacion !== true ? (
													"Dentro de los próximos 5 días hábiles te contactaremos con novedades acerca de tu solicitud de reintegro."
												) : (
													"Para continuar con su pedido por favor ponganse en contanto con reintegros@safetravelassistance.com"
												)}
											</Typography>

											<Box style={{ display: 'flex', justifyContent: 'center', paddingTop: "20px", paddingBottom: "10px" }}>
												<Button startIcon={<Icon>home</Icon>} variant="contained" color="primary" component={LinkButton} >Inicio</Button>
											</Box>

											<Typography variant="caption" style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
												En unos segundos serás redireccionado al inicio
											</Typography>
										</>}
								</React.Fragment>
							) : (
								<React.Fragment>
									{this.getStepContent(this.state.activeStep, this.props)}
									<Box style={{ display: 'flex', justifyContent: 'center', paddingTop: "20px", paddingBottom: "20px" }}>
										<Button disabled={this.state.activeStep === 0} onClick={this.handleBack} >
											Anterior
										</Button>

										<Button
											variant="contained"
											color="primary"
											onClick={this.handleNext} disabled={this.state.disabledBoton}
										>
											{this.state.activeStep === this.steps.length - 1 ? 'finalizar' : 'continuar'}
										</Button>
									</Box>
								</React.Fragment>
							)}
						</Paper>
						<br />
						<Copyright />
					</Container>
				</ThemeProvider>


		);
	}
}

const mapStateToProps = reducers => {

	return reducers.productsReducer;
};

const mapDispatchToProps = dispatch => {
	return {
		saveInfoStepRefund: (infoStep, step, createRefund) => dispatch({ type: "SAVE_INFO_STEP_REFUND", infoStep, step, createRefund }),
		uploadReintegro: (formData) => dispatch({ type: "SAVE_REINTEGRO", formData }),
		getLogoutReintegro: () => dispatch({ type: "GET_LOGOUT_REINTEGRO" }),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Steps)