import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import * as QueryString from "query-string"
import { connect } from "react-redux";
import jwt_decode from 'jwt-decode';
import {Dialog, DialogContent, Typography}  from '@material-ui/core';
import preload from '../../assets/images/preload.gif';

class Main extends Component {
    constructor(props) {
		super(props);
		this.state = {
			waitingResponse: true,
            loginError : null
		};
	}

    /*
    * Verifica si se ha pasado un token en la URL. Si es así, llama a verifyAccess, caso contrario no espera respuesta.
    */ 
    componentDidMount(){
        let queryParams = QueryString.parse(this.props.location.search);
        if (queryParams.access!=undefined){
            this.setState({ waitingResponse: true })
            this.verifyAccess(queryParams.access);
        }else{
            this.setState({ waitingResponse: false })
        }
    }

    /*
    * Renderiza el componente y setea el state solo si ha llegado una respuesta y verifica si la petición al endpoint dio error.
    */
	componentDidUpdate(prevProps,prevState,snapshot){
        if (this.props.responseUserLogin != prevProps.responseUserLogin ||  this.props.responseError != prevProps.responseError){
            if (this.props.responseError.code != undefined ) {
                this.setState({ waitingResponse: false })
                this.setState({ loginError: "INVALID_TOKEN" })
            }else {
                if (this.props.responseUserLogin.id != undefined){ // Respuesta satisfactoria del endpoint  
                    this.setState({ waitingResponse: false })
                }
            }
        }
	}

    /*
    * Verifica que el access token tenga un formato valido. Si jwt_decode produce un error, el formato no es un jwt
    */
    verifyAccess(token){
        let userDataJWT;
        try {
            userDataJWT =  jwt_decode(token);
            this.props.getUser(token, userDataJWT.user_id, 'LOGIN');
        } catch(error) {
            this.setState({ loginError: "INVALID_TOKEN" })
            this.setState({ waitingResponse: false })
        }
    }

    handleError = () => {
        return (
            <Redirect to={{
                pathname: `/login`, state: {
                    accessTokenError: true
                }
            }} />
        )
    }

    loadingScreen =  () =>{
        return (
            <Dialog open={true} align="center" className="main_loading">
                <DialogContent>
                <div> <img src={preload}/>   </div>
                <div> <Typography variant="h5">Verificando acceso... </Typography>  </div>                
                </DialogContent>
            </Dialog>
        ) 
    }

    render() {
        let token = window.localStorage.getItem('headersSetCookie');
        console.log("token",token);
        return (
            this.state.waitingResponse          // si esta esperando una respuesta del endpoint
                ? this.loadingScreen()                  // muestra animación de loading en la pagina.
                : this.state.loginError !== null      // Si ya no espera respuesta, verifica que no haya habido error
                    ? this.handleError()                        // Si hay alguno, redirecciona al login, mostrando el error
                    : token === null || token === undefined                          // Si no hay ningun eror, verifica si hay un token guardado como cookie
                        ? (<Redirect to={"/login"} />)                  // Si  no hay, redirecciona al login
                        : (<Redirect to={"/dashboard"} />)              // Si hay, tiene una sesión iniciada, por ende va al dashboard
        )
    }
}

const mapStateToProps = reducers => {
	return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
	return {
		getUser: (access, query, queryType) => dispatch({ type: "GET_USER", access, query, queryType }),
	};
};

export default connect(mapStateToProps, mapDispachToProps)(Main);