import React, { Component, Fragment } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Button, Grid, Typography, Chip, Box, TextField, CircularProgress, FormControl, FormHelperText } from '@material-ui/core';
import { formatDate } from '../../../services/formatDate';
import ValidationDateTodayRefund from '../../common/fields/ValidationDateTodayRefund';
import { getPaises, getMonedas, getMonedasDefault } from '../../../services/InfoFormService'
import { Autocomplete } from '@material-ui/lab';
import CurrencyInput from './CurrencyInput'
import { Alert } from '@material-ui/lab';

class ModalRefund extends Component {
	constructor(props) {
		super(props);
		this.state = {
			coberturaId: '',
			uploadedFiles: [],
			fileLimit: false,
			sinister_country: '',
			sinister_date: '',
			currency: '',
			amount: '',
			isFocused: true,
			onLoad: false,
			//errores
			errors: {
				coberturaId: false,
				uploadedFiles: false,
				sinister_country: false,
				sinister_date: false,
				currency: false,
				amount: false,
				services: false,
				errorCheckboxesTransporte: false,
				alojamientos: { montoPagado: false, montoRecuperado: false },
				transporte: {
					subServices: {
						vuelos: { montoPagado: false, montoRecuperado: false },
						trenes: { montoPagado: false, montoRecuperado: false },
						bus: { montoPagado: false, montoRecuperado: false },
						crucero: { montoPagado: false, montoRecuperado: false },
						transfer: { montoPagado: false, montoRecuperado: false }
					}
				},
				excursiones: { montoPagado: false, montoRecuperado: false },
				otro: { nombreServicio: false, montoPagado: false, montoRecuperado: false },
			},
			initial: false,
			foundItems: null,
			cancelacionMulticausa: false,
			services: {
				alojamientos: { selected: false, montoPagado: '', montoRecuperado: '' },
				transporte: { selected: false, subServices: { vuelos: { selected: false, montoPagado: '', montoRecuperado: '' }, trenes: { selected: false, montoPagado: '', montoRecuperado: '' }, bus: { selected: false, montoPagado: '', montoRecuperado: '' }, crucero: { selected: false, montoPagado: '', montoRecuperado: '' }, transfer: { selected: false, montoPagado: '', montoRecuperado: '' } } },
				excursiones: { selected: false, montoPagado: '', montoRecuperado: '' },
				otro: { selected: false, nombreServicio: '', montoPagado: '', montoRecuperado: '' },
			},
		}
	}

	inputValidate = () => {
		let count = 6;
		let countValid = 0;
		let response = {};
		let status = false;

		if (this.state.uploadedFiles.length > 0) {
			countValid++;
			response['coverage_files'] = this.state.uploadedFiles;
		}

		if (this.state.coberturaId && this.state.coberturaId !== '') {
			countValid++;
			response["coverage"] = this.state.coberturaId.description;
		}

		if (this.state.sinister_country && this.state.sinister_country !== '') {
			countValid++;
			response["sinister_country"] = this.state.sinister_country.countryName;
		}

		if (this.state.sinister_date && this.state.sinister_date !== null) {
			if (this.state.sinister_date.state.selectedDate !== '' && this.state.sinister_date.state.selectedDate !== null) {
				countValid++;
				response["sinister_date"] = formatDate(this.state.sinister_date.state.selectedDate);
			}
		}

		if (this.state.currency && this.state.currency !== '') {
			countValid++;
			response["currency"] = this.state.currency.id;
		}

		if (this.state.amount && this.state.amount !== '') {
			countValid++;
			response['amount'] = this.state.amount;
		}

		let errorCheckboxes = false
		let errorAlojamiento = { montoPagado: false, montoRecuperado: false };
		let errorExcursiones = { montoPagado: false, montoRecuperado: false };
		let errorOtro = { nombreServicio: false, montoPagado: false, montoRecuperado: false };
		let errorTransporte = {
			subServices: {
				vuelos: { montoPagado: false, montoRecuperado: false },
				trenes: { montoPagado: false, montoRecuperado: false },
				bus: { montoPagado: false, montoRecuperado: false },
				crucero: { montoPagado: false, montoRecuperado: false },
				transfer: { montoPagado: false, montoRecuperado: false },
			},
		};
		let errorCheckboxesTransporte = false;

		if (this.state.cancelacionMulticausa) {
			count++
			const { alojamientos, transporte, excursiones, otro } = this.state.services;
			const isAnyServiceSelected =
				alojamientos.selected ||
				excursiones.selected ||
				otro.selected ||
				transporte.selected;
			if (!isAnyServiceSelected) {
				errorCheckboxes = true;
			} else {
				countValid++;
				if (alojamientos.selected) {
					count++;
					if (!alojamientos.montoPagado) {
						errorAlojamiento.montoPagado = true;
					}
					if (!alojamientos.montoRecuperado) {
						errorAlojamiento.montoRecuperado = true;
					}
					if (alojamientos.montoPagado && alojamientos.montoRecuperado) {
						countValid++;
						if (!response['penalty_letter']) {
							response['penalty_letter'] = {};
						}
						response['penalty_letter']['alojamientos'] = {
							'amount_paid': alojamientos.montoPagado,
							'amount_recovered': alojamientos.montoRecuperado,
						};
					}
				}
				if (excursiones.selected) {
					count++;
					if (!excursiones.montoPagado) {
						errorExcursiones.montoPagado = true;
					}
					if (!excursiones.montoRecuperado) {
						errorExcursiones.montoRecuperado = true;
					}
					if (excursiones.montoPagado && excursiones.montoRecuperado) {
						countValid++;
						if (!response['penalty_letter']) {
							response['penalty_letter'] = {};
						}
						response['penalty_letter']['excursiones'] = {
							'amount_paid': excursiones.montoPagado,
							'amount_recovered': excursiones.montoRecuperado,
						};
					}
				}
				if (otro.selected) {
					count++;
					if (!otro.nombreServicio) {
						errorOtro.nombreServicio = true;
					}
					if (!otro.montoPagado) {
						errorOtro.montoPagado = true;
					}
					if (!otro.montoRecuperado) {
						errorOtro.montoRecuperado = true;
					}
					if (otro.nombreServicio && otro.montoPagado && otro.montoRecuperado) {
						countValid++;
						if (!response['penalty_letter']) {
							response['penalty_letter'] = {};
						}
						response['penalty_letter']['otro'] = {
							'free_text': otro.nombreServicio,
							'amount_paid': otro.montoPagado,
							'amount_recovered': otro.montoRecuperado,
						};
					}
				}
				if (transporte.selected) {
					count++;
					const subServices = Object.keys(transporte.subServices);
					const isAnySubServiceSelected = subServices.some(subService => transporte.subServices[subService].selected);

					if (!isAnySubServiceSelected) {
						errorCheckboxesTransporte = true;
					} else {
						countValid++;
						subServices.forEach(subService => {
							if (transporte.subServices[subService].selected) {
								count++;
								if (!transporte.subServices[subService].montoPagado) {
									errorTransporte.subServices[subService].montoPagado = true;
								}
								if (!transporte.subServices[subService].montoRecuperado) {
									errorTransporte.subServices[subService].montoRecuperado = true;
								}
								if (transporte.subServices[subService].montoPagado && transporte.subServices[subService].montoRecuperado) {
									countValid++;
									if (!response['penalty_letter']) {
										response['penalty_letter'] = {};
									}
									if (!response['penalty_letter']['transporte']) {
										response['penalty_letter']['transporte'] = {};
									}
									response['penalty_letter']['transporte'][subService] = {
										'amount_paid': transporte.subServices[subService].montoPagado,
										'amount_recovered': transporte.subServices[subService].montoRecuperado,
									};
								}
							}
						});
					}
				}
			}
		} else {
			response['penalty_letter'] = null
		}

		this.setState({
			errors: {
				'coberturaId': (this.state.coberturaId && this.state.coberturaId !== '') ? false : true,
				'uploadedFiles': (this.state.uploadedFiles.length > 0) ? false : true,
				'sinister_country': (this.state.sinister_country && this.state.sinister_country !== '') ? false : true,
				'sinister_date': (this.state.sinister_date && this.state.sinister_date.state.selectedDate !== null && this.state.sinister_date.state.errorText === '') ? false : true,
				'currency': (this.state.currency && this.state.currency !== '') ? false : true,
				'amount': (this.state.amount && this.state.amount !== '') ? false : true,
				'services': errorCheckboxes,
				'errorCheckboxesTransporte': errorCheckboxesTransporte,
				'alojamientos': errorAlojamiento,
				'excursiones': errorExcursiones,
				'otro': errorOtro,
				'transporte': errorTransporte,
			}
		});

		if (response) {
			if (countValid !== count) {
				status = false;
			} else {
				status = true;
			}
		}

		return { "status": status, "data": response };
	}

	submitCobertura = () => {

		let responseStatus = [];
		let responseData = [];

		let inputValidateCobertura = this.inputValidate();
		responseStatus.push(inputValidateCobertura.status);
		responseData["cobertura"] = inputValidateCobertura

		if (!responseStatus.some(item => item === false)) {

			return { 'data': responseData, status: true };
		}

		return { 'data': [], status: false };
	}

	handlerCobertura = (object, value) => {
		this.setState({ coberturaId: value })
		if(value){
			this.setState({ cancelacionMulticausa: value.id === "CANCELACION DE VIAJE MULTI CAUSA" })
			const requiredItems = this.props.dataCobertura.required_items;
			const isEmpty = Object.entries(requiredItems).length === 0;
			if (!isEmpty) {
				const foundKey = Object.keys(requiredItems).find(key => key === value.description);
				if (foundKey) {
					const items = requiredItems[foundKey].slice().sort();
					this.setState({ foundItems: items });
				} else {
					this.setState({ foundItems: null });
				}
			} else {
				this.setState({ foundItems: null });
			}
		}else{
			this.setState({ cancelacionMulticausa: false })
		}
	}

	handlerSinisterCountry = (object, value) => this.setState({ sinister_country: value });

	handlerCurrency = (object, value) => this.setState({ currency: value });

	compressImage = async (file, { quality = 1, type = file.type }) => {
		// Get as image data
		const imageBitmap = await createImageBitmap(file);

		// Draw to canvas
		const canvas = document.createElement('canvas');
		canvas.width = imageBitmap.width;
		canvas.height = imageBitmap.height;
		const ctx = canvas.getContext('2d');
		ctx.drawImage(imageBitmap, 0, 0);

		// Turn into Blob
		const blob = await new Promise((resolve) =>
			canvas.toBlob(resolve, type, quality)
		);

		// Turn Blob into File
		return new File([blob], file.name, {
			type: blob.type,
		});
	};

	handleUploadFiles = async (files) => {
		const maxCountCoberturas = 20;
		const uploaded = [...this.state.uploadedFiles];
		let limitExceeded = false;

		for (const file of files) {
			let compressedFile;

			if (file.type === 'application/pdf') {
				compressedFile = file;
			} else {
				compressedFile = await this.compressImage(file, {
					quality: 0.5,
					type: 'image/jpeg',
				});
			}

			if (uploaded.findIndex((f) => f.name === compressedFile.name) === -1) {
				uploaded.push(compressedFile);

				if (uploaded.length === maxCountCoberturas) {
					this.setState({
						fileLimit: true,
					});
				}

				if (uploaded.length > maxCountCoberturas) {
					this.setState({
						fileLimit: false,
					});
					limitExceeded = true;
					break;
				}
			}
		}

		if (!limitExceeded) {
			this.setState({
				uploadedFiles: uploaded,
			});
		}

		this.setState({ onLoad: false })
	};

	handlerAmount = (event, child) => {
		this.setState({ amount: event.target.value })
	}

	handleFileEvent = (e) => {
		this.setState({ onLoad: true })
		const achivosSubidos = Array.prototype.slice.call(e.target.files)

		const chosenFiles = []

		const allowedFileTypes = ["jpg", "png", "pdf", "jpeg", "PNG", "JPG", "PDF", "JPEG"];
		achivosSubidos.map((fileExt) => {
			let fileExtension = fileExt.name.split(".").at(-1)
			if (!allowedFileTypes.includes(fileExtension)) {
				window.alert(`Las extensiones admitidas son ${allowedFileTypes.join(", ")}`);
				this.setState({ onLoad: false })
				return false;
			} else {
				chosenFiles.push(fileExt)
			}
		})

		if (chosenFiles.length > 0) {
			this.handleUploadFiles(chosenFiles);
		}

	}

	deleteFile = (file) => {
		const filtredData = this.state.uploadedFiles.filter(uploadFile => uploadFile.name !== file);
		this.setState({
			uploadedFiles: filtredData,
		})
		const maxCountCoberturas = 20;
		const uploaded = [...this.state.uploadedFiles];
		let limitExceeded = false;

		if (uploaded.length === maxCountCoberturas) {

			this.setState({
				fileLimit: true,
			})
		}
		if (uploaded.length > maxCountCoberturas) {
			this.setState({
				fileLimit: false,
			})
			limitExceeded = true;
			return true;
		} else {
			this.setState({
				fileLimit: false,
			})
			limitExceeded = false;
			return false;
		}
	}

	handleFocus = () => this.setState({ isFocused: true })

	handleBlur = () => this.setState({ isFocused: false })


	handleChangeServiceMulticausa = (event) => {
		const { name, checked } = event.target;

		if (name === 'transporte') {
			// Manejar la selección/deselección del servicio "transporte"
			if (!checked) {
				const updatedSubServices = Object.keys(this.state.services[name].subServices).reduce((acc, subService) => ({
					...acc,
					[subService]: {
						selected: false,
						montoPagado: '',
						montoRecuperado: ''
					}
				}), {});

				this.setState(prevState => ({
					services: {
						...prevState.services,
						[name]: {
							...prevState.services[name],
							selected: checked,
							subServices: updatedSubServices
						}
					}
				}));
			} else {
				this.setState(prevState => ({
					services: {
						...prevState.services,
						[name]: {
							...prevState.services[name],
							selected: checked
						}
					}
				}));
			}
		} else {
			if (!checked) {
				this.setState(prevState => ({
					services: {
						...prevState.services,
						[name]: {
							selected: checked,
							montoPagado: '',
							montoRecuperado: ''
						}
					}
				}));
			} else {
				this.setState(prevState => ({
					services: {
						...prevState.services,
						[name]: {
							...prevState.services[name],
							selected: checked
						}
					}
				}));
			}
		}
	};

	handleAmountChange = (event, serviceName, type) => {
		const { value } = event.target;
		this.setState(prevState => ({
			services: {
				...prevState.services,
				[serviceName]: {
					...prevState.services[serviceName],
					[type]: value,
				}
			}
		}));
	};

	handleSubServiceChange = (event, serviceName, subService) => {
		const { checked } = event.target;

		// Si se desselecciona el sub-servicio, reinicia los valores
		if (!checked) {
			this.setState(prevState => ({
				services: {
					...prevState.services,
					[serviceName]: {
						...prevState.services[serviceName],
						subServices: {
							...prevState.services[serviceName].subServices,
							[subService]: {
								selected: checked,
								montoPagado: '',
								montoRecuperado: ''
							}
						}
					}
				}
			}));
		} else {
			this.setState(prevState => ({
				services: {
					...prevState.services,
					[serviceName]: {
						...prevState.services[serviceName],
						subServices: {
							...prevState.services[serviceName].subServices,
							[subService]: {
								...prevState.services[serviceName].subServices[subService],
								selected: checked
							}
						}
					}
				}
			}));
		}
	};

	handleSubServiceAmountChange = (event, serviceName, subService, type) => {
		const { value } = event.target;
		this.setState(prevState => ({
			services: {
				...prevState.services,
				[serviceName]: {
					...prevState.services[serviceName],
					subServices: {
						...prevState.services[serviceName].subServices,
						[subService]: {
							...prevState.services[serviceName].subServices[subService],
							[type]: value,
						}
					}
				}
			}
		}));
	};

	handleOtherServiceChange = (event, serviceName, type) => {
		const { value } = event.target;

		this.setState(prevState => ({
			services: {
				...prevState.services,
				[serviceName]: {
					...prevState.services[serviceName],
					[type]: value
				}
			}
		}));
	};

	renderServiceFields = (serviceName) => {
		const { services } = this.state;

		switch (serviceName) {
			case 'alojamientos':
			case 'excursiones':
				return (
					<Box style={{margin:'5px 0'}}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={4}>
								<CurrencyInput
									placeholder="0,00"
									label="Monto pagado"
									InputLabelProps={{ shrink: true }}
									type="text"
									value={services[serviceName].montoPagado}
									onChange={(event) => this.handleAmountChange(event, serviceName, 'montoPagado')}
									error={this.state.errors[serviceName].montoPagado}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CurrencyInput
									placeholder="0,00"
									label="Monto recuperado"
									InputLabelProps={{ shrink: true }}
									type="text"
									value={services[serviceName].montoRecuperado}
									onChange={(event) => this.handleAmountChange(event, serviceName, 'montoRecuperado')}
									error={this.state.errors[serviceName].montoRecuperado}
								/>
							</Grid>
						</Grid>
					</Box>
				);
			case 'transporte':
				return (
					<Fragment>
						{Object.keys(services[serviceName].subServices).map(subService => (
							<Fragment key={subService}>
								<Box ml={4}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox checked={services[serviceName].subServices[subService].selected} onChange={(event) => this.handleSubServiceChange(event, serviceName, subService)} />}
											label={subService}
										/>
									</FormGroup>
								</Box>
								{services[serviceName].subServices[subService].selected && (
									<Box style={{margin:'5px 0 0 30px'}}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4}>
												<CurrencyInput
													placeholder="0,00"
													label="Monto pagado"
													InputLabelProps={{ shrink: true }}
													type="text"
													value={services[serviceName].subServices[subService].montoPagado}
													onChange={(event) => this.handleSubServiceAmountChange(event, serviceName, subService, 'montoPagado')}
													error={this.state.errors.transporte.subServices[subService].montoPagado}
												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												<CurrencyInput
													placeholder="0,00"
													label="Monto recuperado"
													InputLabelProps={{ shrink: true }}
													type="text"
													value={services[serviceName].subServices[subService].montoRecuperado}
													onChange={(event) => this.handleSubServiceAmountChange(event, serviceName, subService, 'montoRecuperado')}
													error={this.state.errors.transporte.subServices[subService].montoRecuperado}
												/>
											</Grid>
										</Grid>
									</Box>

								)}
							</Fragment>
						))}
					</Fragment>
				);
			case 'otro':
				return (
					<Box style={{margin:'5px 0'}}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={4}>
								<TextField
									label="Nombre del servicio"
									variant="outlined"
									InputLabelProps={{ shrink: true }}
									value={services[serviceName].nombreServicio}
									onChange={(event) => this.handleOtherServiceChange(event, serviceName, 'nombreServicio')}
									style={{ width: '100%' }}
									error={this.state.errors[serviceName].nombreServicio}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CurrencyInput
									placeholder="0,00"
									label="Monto pagado"
									InputLabelProps={{ shrink: true }}
									type="text"
									value={services[serviceName].montoPagado}
									onChange={(event) => this.handleOtherServiceChange(event, serviceName, 'montoPagado')}
									error={this.state.errors[serviceName].montoPagado}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CurrencyInput
									placeholder="0,00"
									label="Monto recuperado"
									InputLabelProps={{ shrink: true }}
									type="text"
									value={services[serviceName].montoRecuperado}
									onChange={(event) => this.handleOtherServiceChange(event, serviceName, 'montoRecuperado')}
									error={this.state.errors[serviceName].montoRecuperado}
								/>
							</Grid>
						</Grid>
					</Box>
				);
			default:
				return null;
		}
	};

	render() {
		const { services } = this.state;
		const getCoberturas = () => {
			let response = []
			let coberRepetidas = []
			let coberturasNoRepetidas = []

			if (Object.keys(this.props.dataCargada).length > 0) {
				this.props.dataCargada.map(cober =>
				(
					coberRepetidas.push(cober.coverage)
				));
			}

			if (Object.keys(this.props.dataCargada).length > 0) {
				for (var i = 0; i < this.props.dataCobertura.coverages.length; i++) {
					var igual = false;
					for (var j = 0; j < coberRepetidas.length & !igual; j++) {
						if (this.props.dataCobertura.coverages[i] == coberRepetidas[j])
							igual = true;
					}
					if (!igual) coberturasNoRepetidas.push(this.props.dataCobertura.coverages[i]);
				}
				coberturasNoRepetidas.map(i =>
				(
					response.push({ "id": i, "description": i })
				));
			} else {
				this.props.dataCobertura.coverages.map(i =>
				(
					response.push({ "id": i, "description": i })
				));
			}

			response = response.sort((a, b) => (a.description > b.description ? 1 : a.description < b.description ? -1 : 0))

			return response;
		}

		return (
			<Fragment>

				<Grid container spacing={1}>
					<Grid item sm={6} xs={12} >
						<p component="h6" variant="h6" >
							País donde ocurrió el evento
						</p>

						<Grid item xs={12} sm={12} style={{ margin: "15px 0 20px 0" }} >
							<Autocomplete
								id="country-select-demo"
								onChange={this.handlerSinisterCountry}
								sx={{ width: 300 }}
								options={getPaises()}
								value={this.state.sinister_country}
								autoHighlight
								getOptionLabel={(option) => option.countryName || ""}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder="Seleccione un país"
										inputProps={{
											...params.inputProps,
										}}
										error={this.state.errors.sinister_country}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item sm={6} xs={12} >
						<p component="h4" variant="h6" >
							Fecha del evento
						</p>

						<Grid item xs={12} sm={12} style={{ margin: "15px 0 20px 0" }} >
							<ValidationDateTodayRefund
								ref={element => { this.state.sinister_date = element }}
								label=""
								name={`sinister_date`}
								id={`sinister_date`}
								error={this.state.errors.sinister_date}
								disabled={false}
								defaultValue={null}
								placeholder="__/__/____"
								classNameCustom={'textField'}

							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={1}>
					<Grid item sm={6} xs={12} >
						<p component="h4" variant="h6" >
							Servicio a reintegrar
						</p>

						<Grid item xs={12} sm={12} style={{ margin: "15px 0 20px 0" }} >
							<Autocomplete
								id="country-select-demo"
								onChange={this.handlerCobertura}
								sx={{ width: 300 }}
								options={getCoberturas()}
								value={this.state.coberturaId}
								autoHighlight
								getOptionLabel={(option) => option.description || ""}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder="Seleccione una cobertura"
										inputProps={{
											...params.inputProps,
										}}
										error={this.state.errors.coberturaId}
									/>
								)}
							/>
						</Grid>
					</Grid>

					<Grid item sm={3} xs={12} >
						<p component="h4" variant="h6" >
							Moneda de gasto
						</p>

						<Grid item xs={12} sm={12} style={{ margin: "15px 0 20px 0" }} >
							<Autocomplete
								id="currency-select-demo"
								onChange={this.handlerCurrency}
								sx={{ width: 300 }}
								options={getMonedasDefault()}
								value={this.state.currency}
								autoHighlight
								getOptionLabel={(option) => option.id || ""}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder="Moneda"
										inputProps={{
											...params.inputProps,
										}}
										error={this.state.errors.currency}
									/>
								)}
							/>
						</Grid>
					</Grid>

					<Grid item sm={3} xs={12} >
						<p component="h4" variant="h6" >
							Monto solicitado
						</p>

						<Grid item xs={12} sm={12} style={{ margin: "15px 0 20px 0" }} >
							<CurrencyInput
								placeholder="0,00"
								type="text"
								value={this.state.amount}
								onChange={this.handlerAmount}
								error={this.state.errors.amount}
							/>

						</Grid>
					</Grid>
				</Grid>

				{this.state.foundItems &&
					<Grid item sm={12} xs={12} style={{ marginBottom: 10 }}>
						<Alert severity="warning">Para la solicitud del reintegro del servicio seleccionado deberá adjuntar los siguientes comprobantes: {this.state.foundItems && this.state.foundItems.map((item, index) => (
							<strong key={index}>{item}{index < this.state.foundItems.length - 1 ? ', ' : ''}</strong>
						))}</Alert>
					</Grid>
				}

				{this.state.cancelacionMulticausa &&
					<Grid container spacing={1}>
						<Grid item sm={12} xs={12} style={{ marginBottom: 10 }}>
							<Box
								style={{
									border: '1px solid #b0bec5',
									borderRadius: '8px',
									backgroundColor: '#f5f5f5',
									padding: '16px',
									maxWidth: '90%',
									margin: '0 auto',
								}}>
								<FormControl component="fieldset" fullWidth>
									<Typography>
										<u>Seleccione los servicios de cancelación multicausa</u>
									</Typography>
									<FormGroup>
										<Fragment>
											{/* Ejemplo de cómo renderizar un servicio */}
											<FormControlLabel
												control={<Checkbox checked={services.alojamientos.selected} onChange={this.handleChangeServiceMulticausa} name="alojamientos" />}
												label="Alojamiento"
											/>
											{services.alojamientos.selected && this.renderServiceFields('alojamientos')}

											<FormControlLabel
												control={<Checkbox checked={services.transporte.selected} onChange={this.handleChangeServiceMulticausa} name="transporte" />}
												label="Transporte"
											/>
											{services.transporte.selected && this.renderServiceFields('transporte')}

											<FormControlLabel
												control={<Checkbox checked={services.excursiones.selected} onChange={this.handleChangeServiceMulticausa} name="excursiones" />}
												label="Excursiones"
											/>
											{services.excursiones.selected && this.renderServiceFields('excursiones')}

											<FormControlLabel
												control={<Checkbox checked={services.otro.selected} onChange={this.handleChangeServiceMulticausa} name="otro" />}
												label="Otro"
											/>
											{services.otro.selected && this.renderServiceFields('otro')}
										</Fragment>
									</FormGroup>

									{this.state.errors.services ? (
										<FormHelperText style={{ color: 'red' }}>
											Debe seleccionar al menos un servicio
										</FormHelperText>)
										: (
											this.state.errors.errorCheckboxesTransporte && <FormHelperText style={{ color: 'red' }}>
												Debe seleccionar al menos un servicio de transporte
											</FormHelperText>
										)
									}
								</FormControl>
							</Box>
						</Grid>
					</Grid>
				}

				<Grid container spacing={1}>
					<Grid item sm={12} xs={12}>
						<Typography component="h4" variant="h6" >
							Comprobantes
						</Typography>
						<Typography variant="caption">(*) Carga máxima: 20 archivos (formatos adminitidos .png, .jpg, .jpeg, .pdf)</Typography>
						<Grid item xs={11} sm={11} style={{ margin: "15px 0 20px 0" }} >
							<Box sx={{ m: 1, position: 'relative' }}>
								<Button variant="contained" component="label" color='primary'>
									Subir comprobantes
									<input
										hidden
										accept='.png, .jpg, .jpeg ,application/pdf'
										multiple
										type="file"
										onChange={this.handleFileEvent}
										disabled={this.state.fileLimit}
										error={this.state.errors.uploadedFiles}
									/>
								</Button>
								{this.state.onLoad &&
									<CircularProgress
										style={{ marginLeft: '10px' }}
										size={24}
										sx={{
											position: 'absolute',
											top: '50%',
											left: '50%',
										}}
									/>}
							</Box>
							{(this.state.errors.uploadedFiles)
								? <Typography variant="caption" style={{ color: "red" }}>Debe cargar al menos 1 archivo para continuar</Typography>
								: <></>
							}
						</Grid>
					</Grid>

					<Typography variant="caption">Archivos subidos: {`${this.state.uploadedFiles.length}`}</Typography>
					<Grid item sm={12} xs={12} className="chipsContainer">
						{this.state.uploadedFiles.map((file, index) => (
							<Chip
								key={index}
								label={`${file.name}`}
								onDelete={() => { this.deleteFile(file.name) }}
								variant="outlined"
								style={{
									margin: 5
								}}
							/>
						))}
					</Grid>

				</Grid>

			</Fragment>

		)
	}
}

export default (ModalRefund)


