import React, { Component } from 'react'
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';


export default class Dividers extends Component {
  constructor(props) {
    super(props);
    this.state = {
        label: this.props.label
    };
  }

  render() {
    return (
      <div className="moduleComponent">
        <br />
        <Divider style={{height: 2, backgroundColor: "#1751a1"}}/>
        <br />
        <Typography variant="h5">{this.props.label}</Typography>
        <br />
      </div>
    )
  }
}
