import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';

function* searchCoverages(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'get', config.credentials.url + '/coverage/?search=' + actions.value, requestOptions)

        yield put({ type: "COVERAGES_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "COVERAGES_RESPONSE", value: error })
    }
}

function* zonesProduct(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'get', config.credentials.url + '/zone/?offset=0&limit=' + actions.limit, requestOptions)

        yield put({ type: "ZONES_RESPONSE", value: response })
    } catch (error) {
        yield put({ type: "ZONES_RESPONSE", value: error })
    }
}

export function* suppliersProduct(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'get', config.credentials.url + '/supplier/?offset=0&limit=' + actions.limit, requestOptions)

        yield put({ type: "SUPPLIER_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "SUPPLIER_RESPONSE", value: error })
    }
}

export function* categoriesProduct(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'get', config.credentials.url + '/product_type/?offset=0&limit=' + actions.limit, requestOptions)

        yield put({ type: "CATEGORY_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "CATEGORY_RESPONSE", value: error })
    }
}

function* officesProduct(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };


        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'get', config.credentials.url + '/office/?offset=0&limit=' + actions.limit, requestOptions)

        yield put({ type: "OFFICES_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "OFFICES_RESPONSE", value: error })
    }
}

function* infoStep(actions) {
    yield put({ type: "INFO_STEP", infoStep: actions.infoStep, step: actions.step })
}

function* infoStepRefund(actions) {

    yield put({ type: "INFO_STEP_REFUND", infoStep: actions.infoStep, step: actions.step, createRefund: actions.createRefund })

}

function* uploadPendingDoc(actions) {
    try {
        const headers = {
            'Content-Type': "multipart/form-data",
        };

        const requestOptions = {
            body: actions.formData
        };
        const response = yield call(apiCall, 'post', config.credentials.url + '/upload_pending_doc/', requestOptions)

        yield put({ type: "UPDATE_FILES_RESPONSE", value: response })
    } catch (error) {
        yield put({ type: "UPDATE_FILES_RESPONSE", value: error })
    }
}

function* patchPendingDoc(actions) {
    try {
        const headers = {
            'Content-Type': "application/json",
        };

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.form)
        };

        const response = yield call(apiCall, 'PATCH', config.credentials.url + '/refund_data/', requestOptions)

        yield put({ type: "UPDATE_PENDING_RESPONSE", value: response })
    } catch (error) {
        yield put({ type: "UPDATE_PENDING_RESPONSE", value: error })
    }
}

function dataStep(numberStep, info) {
    let response = new Object()

    if (numberStep === 0) {
        response["short_name"] = info["category"]["short_name"];
        response["url_image"] = info["category"]["url_image"];
        response["long_name"] = info["category"]["long_name"];
        response["is_enabled"] = info["category"]["is_enabled"];
        response["product_type"] = info["category"]["product_type_id"];
        response["supplier"] = info["category"]["supplier_id"];
        response["zone"] = info["zones"]["zone_id"];
        response["office"] = info["offices"]["offices"];
    }

    if (numberStep === 1) {
        response["product_coverage"] = info["product_coverage"]
    }

    return response;
}

function* createProduct(dataProduct, access) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(dataProduct)
        };

        const productResponse = yield call(apiCall, 'post', config.credentials.url + '/productCreate/', requestOptions)

        yield put({ type: "PRODUCT_RESPONSE_FINAL", response: dataProduct, productResponse: productResponse })

    } catch (error) {
        yield put({ type: "PRODUCT_RESPONSE_FINAL", response: error })
    }
}
function* stepProduct(actions) {
    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        let bodyRequest = '';
        if (actions.currentNumberStep === 1) {
            const current = dataStep(actions.currentNumberStep, actions.currentStep);
            if (actions.view === 'clone')
                current["id"] = actions.id;

            const last = dataStep(actions.lastStep[0]["step"], actions.lastStep[0]["info"])
            bodyRequest = Object.assign(current, last)
        }


        if (actions.currentNumberStep === 2) {
            bodyRequest = actions.currentStep
        }

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(bodyRequest)
        };

        const dataProduct = yield call(apiCall, 'post', config.credentials.url + '/step/', requestOptions)

        if (dataProduct.hash !== undefined && actions.currentNumberStep === 2) {


            yield createProduct(dataProduct, actions.access)

        } else {
            yield put({ type: "PRODUCT_RESPONSE", response: dataProduct })
        }


    } catch (error) {
        yield put({ type: "PRODUCT_RESPONSE", response: error })
    }
}

function* editProduct(actions) {
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        let stepUno = actions.lastStep.filter(e => e.step === 0)[0]
        let stepDos = actions.lastStep.filter(e => e.step === 1)[0]

        const bodyRequest = {
            "id": actions.id,
            "short_name": stepUno["info"]["category"]["short_name"],
            "long_name": stepUno["info"]["category"]["long_name"],
            "is_enabled": stepUno["info"]["category"]["is_enabled"],
            "url_image": stepUno["info"]["category"]["url_image"],
            "product_type": stepUno["info"]["category"]["product_type_id"],
            "supplier": stepUno["info"]["category"]["supplier_id"],
            "zone": stepUno["info"]["zones"]["zone_id"],
            "office": stepUno["info"]["offices"]["offices"],
            "product_coverage": stepDos["info"]["product_coverage"],
            "rate": actions.currentStep["rate"]
        }

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(bodyRequest)
        };

        const dataProduct = yield call(apiCall, 'post', config.credentials.url + '/productEdit/', requestOptions)
        yield put({ type: "PRODUCT_RESPONSE_EDIT", response: dataProduct })
    } catch (error) {
        yield put({ type: "PRODUCT_RESPONSE_EDIT", response: error })
    }
}

function product(actions) {
    if (actions.view === "create") {
        return stepProduct(actions)
    }

    if (actions.view === "edit") {
        return editProduct(actions);
    }

    if (actions.view === 'clone') {
        return stepProduct(actions)
    }
}

function* file(actions) {

    try {

        const headers = {
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers,
            body: actions.file
        };

        const response = yield call(apiCall, 'post', config.credentials.url + '/csv_upload/', requestOptions)

        yield put({ type: "FILE_RESPONSE", value: response, tipoTarifa: actions.tipoTarifa })

    } catch (error) {
        yield put({ type: "FILE_RESPONSE", value: error, tipoTarifa: actions.tipoTarifa })
    }
}

function* coveragesProduct(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/coverage/' : config.credentials.url + '/coverage/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "COVERAGES_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "COVERAGES_RESPONSE", value: error })
    }
}

function* rateDetail(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'get', config.credentials.url + '/rateDetail/' + actions.id + '/', requestOptions)

        yield put({ type: "RATE_DETAIL_RESPONSE", value: response })
    } catch (error) {
        yield put({ type: "RATE_DETAIL_RESPONSE", value: error })
    }
}

function* updateOfficesRate(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const response = yield call(apiCall, 'POST', config.credentials.url + '/updateOfficeRate/', requestOptions)

        yield put({ type: "EDIT_OFFICES_RATE_RESPONSE", value: response })
    } catch (error) {
        yield put({ type: "EDIT_OFFICES_RATE_RESPONSE", value: error })
    }
}


function* image(actions) {

    try {

        const headers = {
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers,
            body: actions.file
        };

        const response = yield call(apiCall, 'post', config.credentials.url + '/upload_image/', requestOptions)

        yield put({ type: "IMAGE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "IMAGE_RESPONSE", value: error })
    }
}

function* postReintegro(actions) {
    try {

        const headers = {
            'Content-Type': "multipart/form-data",
        };

        const requestOptions = {
            body: actions.formData
        };

        const response = yield call(apiCall, 'post', config.credentials.url + '/refund_data/', requestOptions)

        yield put({ type: "REINTEGRO_CREATE_RESPONSE", value: response })
    } catch (error) {
        yield put({ type: "REINTEGRO_CREATE_RESPONSE", value: error })
    }
}

function* getListReintegros(actions) {
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/refund/?offset=0&limit=' + actions.limit : config.credentials.url + '/refund/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)
        response.view = 'list'

        yield put({ type: "REINTEGROS_LIST_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "REINTEGROS_LIST_RESPONSE", value: error })
    }
}

function* reintegro(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = (actions.query === null) ? config.credentials.url + '/refund/' : config.credentials.url + '/refund/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)
        response.view = 'show'

        yield put({ type: "REINTEGRO_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "REINTEGRO_RESPONSE", value: error })
    }
}

function* refundData(actions) {
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };

        let url = config.credentials.url + '/refund_data/?voucher_id=' + actions.query.voucher + '&document=' + actions.query.doc_number + '&refund_id=' + actions.query.id

        const data = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "REFUND_DATA_RESPONSE", value: data })
    } catch (error) {
        yield put({ type: "REFUND_DATA_RESPONSE", value: error })
    }
}

function* refundDataImages(actions) {
    try {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${actions.access}`,
        };

        let jsonRequest = {
            'refund_files_ids': actions.query,
        };

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(jsonRequest),
        };

        const response = yield call(apiCall, 'POST', config.credentials.url + '/refund_download_file/',
            requestOptions);

        yield put({ type: 'PRINT_REFUND_RESPONSE', value: response });
    } catch (error) {
        yield put({ type: 'PRINT_REFUND_RESPONSE', value: error });
    }
}

function* editRefund(actions) {
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const response = yield call(apiCall, 'put', config.credentials.url + '/refund/' + actions.id + '/', requestOptions)

        response.view = 'edit'

        yield put({ type: "REINTEGRO_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "REINTEGRO_RESPONSE", value: error })
    }
}

export function* getSuppliers() {

    yield takeLatest("GET_SUPPLIERS_PRODUCT", suppliersProduct)
}
export function* getCategories() {

    yield takeLatest("GET_CATEGORIES_PRODUCT", categoriesProduct)
}
export function* getCoverages() {
    yield takeEvery("GET_COVERAGES_PRODUCT", coveragesProduct)
}

export function* getZones() {
    yield takeEvery("GET_ZONES_PRODUCT", zonesProduct)
}

export function* getOffices() {
    yield takeEvery("GET_OFFICES_PRODUCT", officesProduct)
}

export function* saveInfoStep() {
    yield takeEvery("SAVE_INFO_STEP", infoStep)
}

export function* saveInfoStepRefund() {
    yield takeEvery("SAVE_INFO_STEP_REFUND", infoStepRefund)
}

export function* updateProduct() {
    yield takeEvery("UPDATE_PRODUCT", product)
}

export function* getSearchCoverages() {
    yield takeEvery("GET_SEARCH_COVERAGES", searchCoverages)
}

export function* uploadFile() {

    yield takeEvery("UPLOAD_FILE", file)
}

export function* getRateDetail() {

    yield takeEvery("GET_RATE_DETAIL", rateDetail)
}

export function* editOfficesRate() {

    yield takeEvery("EDIT_OFFICES_RATE", updateOfficesRate)
}

export function* uploadImage() {

    yield takeEvery("UPLOAD_IMAGE", image)
}

export function* saveReintegro() {

    yield takeEvery("SAVE_REINTEGRO", postReintegro)
}

export function* getReintegros() {

    yield takeEvery("GET_REINTEGROS", getListReintegros)
}

export function* getReintegro() {

    yield takeEvery("GET_REINTEGRO", reintegro)
}

export function* getRefundData() {

    yield takeEvery("GET_REFUND_DATA", refundData)
}

export function* getRefundDataImages() {

    yield takeEvery("GET_REFUND_IMAGES", refundDataImages)
}

export function* editReintegro() {
    yield takeEvery("EDIT_REINTEGRO", editRefund)
}

export function* postUploadPendingDoc() {
    yield takeEvery("POST_UPlOAD_PENDING", uploadPendingDoc)
}

export function* patchUploadPendingDoc() {
    yield takeEvery("PATCH_UPlOAD_PENDING", patchPendingDoc)
}