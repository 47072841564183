import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* businessunit(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/bussines_unit/' : config.credentials.url + '/bussines_unit/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "BUSINESS_UNIT_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "BUSINESS_UNIT_RESPONSE", value: error })
    }
}

function* businessunits(actions) {

    try {
        
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/bussines_unit/?offset=0&limit='+actions.limit : config.credentials.url + '/bussines_unit/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "BUSINESS_UNITS_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "BUSINESS_UNITS_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/bussines_unit/', requestOptions);

      

        yield put({ type: "BUSINESS_UNIT_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "BUSINESS_UNIT_RESPONSE", value: error })
    }
}


function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/bussines_unit/' + actions.data+ '/', requestOptions)


        yield put({ type: "BUSINESS_UNIT_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "BUSINESS_UNIT_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/bussines_unit/' + actions.id+ '/', requestOptions)

        yield put({ type: "BUSINESS_UNIT_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "BUSINESS_UNIT_RESPONSE", value: error })
    }
}


export function* getBusinessUnit() {
    yield takeEvery("GET_BUSINESS_UNIT", businessunit)
}
export function* getBusinessUnitsList() {
    yield takeEvery("GET_BUSINESS_UNITS", businessunits)
}

export function* addBusinessUnit() {
    yield takeEvery("ADD_BUSINESS_UNIT", add)
}

export function* removeBusinessUnit() {
    yield takeEvery("REMOVE_BUSINESS_UNIT", remove)
}

export function* editBusinessUnit() {
    yield takeEvery("EDIT_BUSINESS_UNIT", edit)
}
