import React, { Component, Fragment } from 'react';
import { Grid, Typography, TextField, FormControl, Icon, Button, Chip, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core'
import Dividers from '../../common/fields/Dividers';
import { connect } from "react-redux";
import ContentLoader, { List } from "react-content-loader"
import PdfEjemplo from '../../../assets/files/auto_terceros_reintegros.pdf';
// import { getPaisesEuropeos } from '../../../services/InfoFormService'


class PaymentForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			payment_method: null,
			owner: '',
			bank: '',
			account_type: null,
			account_number: '',
			cbu: '',
			alias: '',
			cuit_cuil: '',
			rut: '',
			cedula: '',
			adress: '',
			phone: '',
			swift: '',
			doc_number: '',
			bank_adress: '',
			cable: '',
			ba_wire: '',
			iban: '',
			authorization_letter: [],
			authorized_document: [],
			showAutorizacion: false,
			fileLimitAuth: false,
			fileLimitDocAuth: false,
			saveInfoParcial: false,
			refund_method: null,
			paisResidencia: '',
			errors: {
				payment_method: false,
				owner: false,
				bank: false,
				account_type: false,
				account_number: false,
				cbu: false,
				alias: false,
				cuit_cuil: false,
				rut: false,
				adress: false,
				phone: false,
				cedula: false,
				swift: false,
				doc_number: false,
				bank_adress: false,
				cable: false,
				ba_wire: false,
				iban: false,
				authorization_letter: false,
				authorized_document: false,
				refund_method: false,
			}
		}
	}

	inputValidate = () => {
		let count = 0;
		let countValid = 0;
		let response = [];
		let status = false;

		count++;
		if (this.state.payment_method !== null) {
			countValid++;
			response['payment_method'] = this.state.payment_method;
		}

		count++;
		if (this.state.refund_method !== null) {
			countValid++;
			response['refund_method'] = this.state.refund_method;
		}

		count++;
		if (this.state.owner !== '') {
			countValid++;
			response['owner'] = this.state.owner;
		}

		count++;
		if (this.state.bank !== '') {
			countValid++;
			response['bank'] = this.state.bank;
		}

		count++;
		if (this.state.account_type !== null) {
			countValid++;
			response['account_type'] = this.state.account_type;
		}

		count++;
		if (this.state.account_number !== '') {
			countValid++;
			response['account_number'] = this.state.account_number;
		}

		if (this.state.paisResidencia === 'ARG') {
			count++;
			count++;
			if ((this.state.cbu !== '' && this.state.cbu.length === 22) && this.state.alias !== '') {
				countValid++;
				response['cbu'] = this.state.cbu;
				response['alias'] = this.state.alias;
			} else if ((this.state.cbu !== '' && this.state.cbu.length === 22) && this.state.alias === '') {
				countValid++;
				response['cbu'] = this.state.cbu;
				response['alias'] = null;
			} else if (this.state.cbu === '' && this.state.alias !== '') {
				countValid++;
				response['cbu'] = null;
				response['alias'] = this.state.alias;
			}
			if (this.state.cuit_cuil !== '' && this.state.cuit_cuil.length === 11) {
				countValid++;
				response['cuit_cuil'] = this.state.cuit_cuil;
			}
		} else {
			response['cbu'] = null;
			response['cuit_cuil'] = null;
		}

		if (this.state.paisResidencia === 'CHL') {
			count++;
			if (this.state.rut !== '') {
				countValid++;
				response['rut'] = this.state.rut;
			}
		} else {
			response['rut'] = null;
		}

		if (this.state.paisResidencia === 'COL') {
			count++;
			count++;
			count++;
			if (this.state.cedula !== '') {
				countValid++;
				response['cedula'] = this.state.cedula;
			}
			if (this.state.adress !== '') {
				countValid++;
				response['adress'] = this.state.adress;
			}
			if (this.state.phone !== '') {
				countValid++;
				response['phone'] = this.state.phone;
			}
		} else {
			response['cedula'] = null;
			response['adress'] = null;
			response['phone'] = null;
		}


		if (this.state.paisResidencia !== null && !(this.state.paisResidencia === 'ARG' || this.state.paisResidencia === 'CHL' || this.state.paisResidencia === 'COL')) {
			count++;
			count++;
			count++;
			if (this.state.swift !== '') {
				if(this.state.swift.length >= 8 && this.state.swift.length <= 11){
					countValid++;
					response['swift'] = this.state.swift;
				}
			}
			if (this.state.doc_number !== '') {
				countValid++;
				response['doc_number'] = this.state.doc_number;
			}
			if (this.state.bank_adress !== '') {
				countValid++;
				response['bank_adress'] = this.state.bank_adress;
			}
		} else {
			response['swift'] = null;
			response['doc_number'] = null;
			response['bank_adress'] = null;
		}

		if (this.state.paisResidencia === 'MEX') {
			count++;
			if (this.state.cable !== '') {
				countValid++;
				response['cable'] = this.state.cable;
			}
		} else {
			response['cable'] = null;
		}

		if (this.state.paisResidencia === 'USA') {
			count++;
			if (this.state.ba_wire !== '') {
				countValid++;
				response['ba_wire'] = this.state.ba_wire;
			}
		} else {
			response['ba_wire'] = null;
		}

		if (this.state.paisResidencia === 'Europa') {
			count++;
			if (this.state.iban !== '') {
				countValid++;
				response['iban'] = this.state.iban;
			}
		} else {
			response['iban'] = null;
		}

		if (this.state.refund_method === 'Autorizar a tercero') {
			count++;
			if (this.state.authorization_letter.length > 0) {
				countValid++;
				response['authorization_letter'] = this.state.authorization_letter;
			}
		} else {
			response['authorization_letter'] = this.state.authorization_letter
		}

		if (this.state.refund_method === 'Autorizar a tercero') {
			count++;
			if (this.state.authorized_document.length > 0) {
				countValid++;
				response['authorized_document'] = this.state.authorized_document;
			}
		} else {
			response['authorized_document'] = this.state.authorized_document;
		}

		let errorCbu = false
		if(this.state.cbu === '' && this.state.alias !== ''){
			errorCbu= false
		}else if(this.state.cbu === '' && this.state.alias === ''){
			errorCbu= true
		}else if(this.state.cbu !== '' && this.state.alias !== ''){
			errorCbu = this.state.cbu.length !== 22 ? true : false
		}else if(this.state.cbu !== '' && this.state.alias === ''){
			errorCbu = this.state.cbu.length !== 22 ? true : false
		}

		this.setState({
			errors: {
				'payment_method': (this.state.payment_method !== null) ? false : true,
				'owner': (this.state.owner !== '') ? false : true,
				'bank': (this.state.bank !== '') ? false : true,
				'account_type': (this.state.account_type !== null) ? false : true,
				'account_number': (this.state.account_number !== '') ? false : true,
				'cbu': errorCbu,
				'alias': (this.state.alias !== '' || this.state.cbu !== '') ? false : true,
				'cuit_cuil': (this.state.cuit_cuil !== '' && this.state.cuit_cuil.length === 11) ? false : true,
				'rut': (this.state.rut !== '') ? false : true,
				'cedula': (this.state.cedula !== '') ? false : true,
				'adress': (this.state.adress !== '') ? false : true,
				'phone': (this.state.phone !== '') ? false : true,
				'swift': (this.state.swift !== '') ? (this.state.swift.length >= 8 && this.state.swift.length <= 11 ? false : true) : true,
				'doc_number': (this.state.doc_number !== '') ? false : true,
				'bank_adress': (this.state.bank_adress !== '') ? false : true,
				'cable': (this.state.cable !== '') ? false : true,
				'ba_wire': (this.state.ba_wire !== '') ? false : true,
				'iban': (this.state.iban !== '') ? false : true,
				'authorized_document': (this.state.refund_method === 'Autorizar a tercero' && this.state.authorized_document.length === 0) ? true : false,
				'authorization_letter': (this.state.refund_method === 'Autorizar a tercero' && this.state.authorization_letter.length === 0) ? true : false,
				'refund_method': (this.state.refund_method !== null) ? false : true,
			}
		});

		if (response) {
			if (countValid !== count) {
				status = false;
			} else {
				status = true;
			}
		}

		return { "status": status, "data": response };
	}

	handleSubmit = () => {
		let responseStatus = [];
		let responseData = [];
		let scrollPersonal = false;

		let inputValidateInformation = this.inputValidate();
		responseStatus.push(inputValidateInformation.status);
		scrollPersonal = inputValidateInformation.status ? false : true;
		responseData["refund_payment"] = inputValidateInformation

		if (!responseStatus.some(item => item === false)) {
			return { 'continue': true, 'data': responseData, 'createRefund': true };
		}
		return { 'continue': false, 'data': [], 'createRefund': false, "view": this.props.view };
	}

	handleSubmitAnterior = () => {

		let responseStatus = [];
		let responseData = [];
		let scrollPersonal = false;

		let inputValidateInformation = this.inputValidate();
		responseStatus.push(inputValidateInformation.status);
		scrollPersonal = inputValidateInformation.status ? false : true;
		responseData["refund_payment"] = inputValidateInformation

		return { 'continue': true, 'data': responseData, 'createRefund': false };

	}

	componentDidMount() {
		if (Object.keys(this.props.responseDataLoginReintegro).length > 0) {

			let residenciaApi = this.props.responseDataLoginReintegro.refunds.country_residence

			// let paisResidenciaPasajero = ''

			// if (getPaisesEuropeos().find((e) => e.id === resideciaApi)) {
			// 	paisResidenciaPasajero = 'Europa'
			// } else {
			// 	paisResidenciaPasajero = resideciaApi
			// }

			this.setState({
				paisResidencia: residenciaApi
			})

		}

		if (Object.keys(this.props.infoStep).length > 0) {
			let arrayStep3 = []
			this.props.infoStep.map(steps => {
				if (steps.step === 2) {
					arrayStep3.push(steps.info)
				}
			})
			let ultimaActuzalicion3 = Object.keys(arrayStep3)[Object.keys(arrayStep3).length - 1]

			if (Object.keys(arrayStep3).length > 0) {
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.bank) {
					this.setState({
						bank: arrayStep3[ultimaActuzalicion3].refund_payment.data.bank,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.owner) {
					this.setState({
						owner: arrayStep3[ultimaActuzalicion3].refund_payment.data.owner,
					})
				}

				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.account_number) {
					this.setState({
						account_number: arrayStep3[ultimaActuzalicion3].refund_payment.data.account_number,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.cbu) {
					this.setState({
						cbu: arrayStep3[ultimaActuzalicion3].refund_payment.data.cbu,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.alias) {
					this.setState({
						alias: arrayStep3[ultimaActuzalicion3].refund_payment.data.alias,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.cuit_cuil) {
					this.setState({
						cuit_cuil: arrayStep3[ultimaActuzalicion3].refund_payment.data.cuit_cuil,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.rut) {
					this.setState({
						rut: arrayStep3[ultimaActuzalicion3].refund_payment.data.rut,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.cedula) {
					this.setState({
						cedula: arrayStep3[ultimaActuzalicion3].refund_payment.data.cedula,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.adress) {
					this.setState({
						adress: arrayStep3[ultimaActuzalicion3].refund_payment.data.adress,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.phone) {
					this.setState({
						phone: arrayStep3[ultimaActuzalicion3].refund_payment.data.phone,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.swift) {
					this.setState({
						swift: arrayStep3[ultimaActuzalicion3].refund_payment.data.swift,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.doc_number) {
					this.setState({
						doc_number: arrayStep3[ultimaActuzalicion3].refund_payment.data.doc_number,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.bank_adress) {
					this.setState({
						bank_adress: arrayStep3[ultimaActuzalicion3].refund_payment.data.bank_adress,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.cable) {
					this.setState({
						cable: arrayStep3[ultimaActuzalicion3].refund_payment.data.cable,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.ba_wire) {
					this.setState({
						ba_wire: arrayStep3[ultimaActuzalicion3].refund_payment.data.ba_wire,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.iban) {
					this.setState({
						iban: arrayStep3[ultimaActuzalicion3].refund_payment.data.iban,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.authorization_letter) {
					this.setState({
						authorization_letter: arrayStep3[ultimaActuzalicion3].refund_payment.data.authorization_letter,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.authorized_document) {
					this.setState({
						authorized_document: arrayStep3[ultimaActuzalicion3].refund_payment.data.authorized_document,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.payment_method) {
					this.setState({
						payment_method: arrayStep3[ultimaActuzalicion3].refund_payment.data.payment_method,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.refund_method) {
					this.setState({
						refund_method: arrayStep3[ultimaActuzalicion3].refund_payment.data.refund_method,
					})
				}
				if (arrayStep3[ultimaActuzalicion3].refund_payment.data.account_type) {
					this.setState({
						account_type: arrayStep3[ultimaActuzalicion3].refund_payment.data.account_type,
					})
				}
			}
		}
	}

	handlerTypePayment = (event, child) => {
		this.setState({ payment_method: event.target.value })
	}

	handlerAccountType = (event, child) => {
		this.setState({ account_type: event.target.value })
	}

	handleUploadFilesDocAuth = files => {
		const maxCountCoberturasDocAuth = 5;
		const uploadedDocAuth = [...this.state.authorized_document];
		let limitExceeded = false;
		files.some((file) => {
			if (uploadedDocAuth.findIndex((f) => f.name === file.name) === -1) {
				uploadedDocAuth.push(file);
				if (uploadedDocAuth.length === maxCountCoberturasDocAuth) {
					this.setState({
						fileLimitDocAuth: true,
					})
				}
				if (uploadedDocAuth.length > maxCountCoberturasDocAuth) {
					this.setState({
						fileLimitDocAuth: false,
					})
					limitExceeded = true;
					return true;
				}
			}
		})
		if (!limitExceeded) {
			this.setState({
				authorized_document: uploadedDocAuth,
			})
		}
	}

	handleUploadFilesAuth = files => {
		const maxCountCoberturasAuth = 5;
		const uploadedAuth = [...this.state.authorization_letter];
		let limitExceeded = false;
		files.some((file) => {
			if (uploadedAuth.findIndex((f) => f.name === file.name) === -1) {
				uploadedAuth.push(file);
				if (uploadedAuth.length === maxCountCoberturasAuth) {
					this.setState({
						fileLimitAuth: true,
					})
				}
				if (uploadedAuth.length > maxCountCoberturasAuth) {
					this.setState({
						fileLimitAuth: false,
					})
					limitExceeded = true;
					return true;
				}
			}
		})
		if (!limitExceeded) {
			this.setState({
				authorization_letter: uploadedAuth,
			})
		}
	}

	handlerBank = (event, child) => {
		this.setState({ bank: event.target.value })
	}

	handlerOwner = (event, child) => {
		this.setState({ owner: event.target.value })
	}

	handlerAccountNumber = (event, child) => {
		this.setState({ account_number: event.target.value })
	}

	handlerCbu = (event, child) => {
		const re = /^[0-9\b]+$/;  // Expresión regular para permitir solo números
		if (event.target.value === '' || re.test(event.target.value)) {
			this.setState({ cbu: event.target.value });
		}
	}

	handlerAlias = (event, child) => {
		this.setState({ alias: event.target.value })
	}

	handlerCuitCuil = (event, child) => {
		const re = /^[0-9\b]+$/;  // Expresión regular para permitir solo números
		if (event.target.value === '' || re.test(event.target.value)) {
			this.setState({ cuit_cuil: event.target.value });
		}
	}

	handlerRut = (event, child) => {
		this.setState({ rut: event.target.value })
	}

	handlerCedula = (event, child) => {
		this.setState({ cedula: event.target.value })
	}

	handlerAdress = (event, child) => {
		this.setState({ adress: event.target.value })
	}

	handlerPhone = (event, child) => {
		this.setState({ phone: event.target.value })
	}

	handlerSwift = (event, child) => {
		this.setState({ swift: event.target.value })
	}

	handlerDoc_number = (event, child) => {
		this.setState({ doc_number: event.target.value })
	}

	handlerBank_adress = (event, child) => {
		this.setState({ bank_adress: event.target.value })
	}

	handlerCable = (event, child) => {
		this.setState({ cable: event.target.value })
	}

	handlerBa_wire = (event, child) => {
		this.setState({ ba_wire: event.target.value })
	}

	handlerIban = (event, child) => {
		this.setState({ iban: event.target.value })
	}

	handleFileEventUploadAuth = (e) => {
		const subidosFilesAuth = Array.prototype.slice.call(e.target.files)
		const chosenFilesAuth = []

		const allowedFileTypes = ["jpg", "png", "pdf", "jpeg"];
		subidosFilesAuth.map((fileExt) => {
			let fileExtension = fileExt.name.split(".").at(-1)
			if (!allowedFileTypes.includes(fileExtension)) {
				window.alert(`Las extensiones admitidas son ${allowedFileTypes.join(", ")}`);
				return false;
			} else {
				chosenFilesAuth.push(fileExt)
			}
		})

		if (chosenFilesAuth.length > 0) {
			this.handleUploadFilesAuth(chosenFilesAuth);
		}
	}

	handleFileEventUploadDocAuth = (e) => {
		const subidosFilesDocAuth = Array.prototype.slice.call(e.target.files)
		const chosenFilesDocAuth = []

		const allowedFileTypes = ["jpg", "png", "pdf", "jpeg"];

		subidosFilesDocAuth.map((fileExt) => {
			let fileExtension = fileExt.name.split(".").at(-1)
			if (!allowedFileTypes.includes(fileExtension)) {
				window.alert(`Las extensiones admitidas son ${allowedFileTypes.join(", ")}`);
				return false;
			} else {
				chosenFilesDocAuth.push(fileExt)
			}
		})

		if (chosenFilesDocAuth.length > 0) {
			this.handleUploadFilesDocAuth(chosenFilesDocAuth);
		}
	}

	deleteFileAuth = (file) => {
		const filterAuth = this.state.authorization_letter.filter(uploadFile => uploadFile.name !== file);
		this.setState({
			authorization_letter: filterAuth,
		})
		const maxFileAuth = 10;
		const uplMaxAuth = [...this.state.authorization_letter];
		let limitExceeded = false;

		if (uplMaxAuth.length === maxFileAuth) {
			this.setState({
				fileLimitAuth: true,
			})
		}
		if (uplMaxAuth.length > maxFileAuth) {
			this.setState({
				fileLimitAuth: false,
			})
			limitExceeded = true;
			return true;
		} else {
			this.setState({
				fileLimitAuth: false,
			})
			limitExceeded = false;
			return false;
		}
	}

	deleteFileDocAuth = (file) => {
		const filterDocAuth = this.state.authorized_document.filter(uploadFile => uploadFile.name !== file);
		this.setState({
			authorized_document: filterDocAuth,
		})
		const maxFileDocAuth = 10;
		const uplMaxDocAuth = [...this.state.authorized_document];
		let limitExceeded = false;

		if (uplMaxDocAuth.length === maxFileDocAuth) {

			this.setState({
				fileLimitDocAuth: true,
			})
		}
		if (uplMaxDocAuth.length > maxFileDocAuth) {
			this.setState({
				fileLimitDocAuth: false,
			})
			limitExceeded = true;
			return true;
		} else {
			this.setState({
				fileLimitDocAuth: false,
			})
			limitExceeded = false;
			return false;
		}
	}

	handlerDownloadModel = () => {
		window.open(PdfEjemplo, '_blank');
	}

	handlerRefund = (event, child) => {
		this.setState({ refund_method: event.target.value })
	}

	getAccountTypeOptions = () => {
		const { paisResidencia } = this.state;
		let options = [];
	
		switch (paisResidencia) {
		  case 'ARG':
			options.push(<MenuItem key="cuenta-corriente-arg" value="Cuenta corriente en pesos">Cuenta corriente en pesos</MenuItem>);
			options.push(<MenuItem key="caja-de-ahorro-arg" value="Caja de ahorro en pesos">Caja de ahorro en pesos</MenuItem>);
			break;
		  case 'CHL':
			options.push(<MenuItem key="cuenta-corriente-chl" value="Cuenta corriente en pesos">Cuenta corriente en pesos</MenuItem>);
			options.push(<MenuItem key="caja-de-ahorro-chl" value="Caja de ahorro en pesos">Caja de ahorro en pesos</MenuItem>);
			break;
		  case 'COL':
			options.push(<MenuItem key="cuenta-corriente-col" value="Cuenta corriente en pesos">Cuenta corriente en pesos</MenuItem>);
			options.push(<MenuItem key="caja-de-ahorro-col" value="Caja de ahorro en pesos">Caja de ahorro en pesos</MenuItem>);
			break;
		  default:
			options.push(<MenuItem key="cuenta-corriente" value="Cuenta corriente">Cuenta corriente</MenuItem>);
			options.push(<MenuItem key="caja-de-ahorro" value="Caja de ahorro">Caja de ahorro</MenuItem>);
			break;
		}
	
		return options;
	  };

	render() {

		const LoaderSkeleton = (props) => {
			return (
				<ContentLoader
					height={"200"}
					width={"auto"}
					viewBox="0 0 100% 100%"
					backgroundColor="#d9d9d9"
					foregroundColor="#ecebeb"
				>

					<rect x="10%" y="0" rx="3" ry="3" width="38%" height="30" />
					<rect x="60%" y="0" rx="3" ry="3" width="38%" height="30" />

					<rect x="10%" y="50" rx="3" ry="3" width="38%" height="30" />
					<rect x="60%" y="50" rx="3" ry="3" width="38%" height="30" />
				</ContentLoader>
			)
		}

		return (
			<Fragment>

				<Dividers></Dividers>
				<Typography component="h3" variant="h5" style={{ margin: "0 0 20px 25px" }}>
					Datos de la cuenta
				</Typography>

				<Grid container spacing={2} className="main_admin" alignItems="center" justify="center">

					<Grid item xs={11} sm={11}>
						<Typography variant="caption" style={{ color: 'red', fontSize: '13.5px' }}>
							IMPORTANTE:
							Tenga en cuenta que su reintegro se encuentra sujeto a análisis de cobertura y en caso de ser aprobado se realizará el pago a la cuenta bancaria indicada.
						</Typography>
					</Grid>

					<Grid item xs={11} sm={5}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="demo-simple-select-label">Cuenta destinataria del reintegro</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id={`refund_method`}
								name={`refund_method`}
								value={this.state.refund_method === null ? '' : this.state.refund_method}
								label="Cuenta destinataria del reintegro"
								onChange={this.handlerRefund}
								error={this.state.errors.refund_method}
							>
								<MenuItem value={'Cuenta propia'}>Cuenta propia</MenuItem>
								<MenuItem value={'Autorizar a tercero'}>Autorizar a tercero</MenuItem>
							</Select>
							<FormHelperText> </FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={11} sm={5}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="demo-simple-select-label">Método de pago</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id={`payment_method`}
								name={`payment_method`}
								value={this.state.payment_method === null ? '' : this.state.payment_method}
								label="Método de pago"
								onChange={this.handlerTypePayment}
								error={this.state.errors.payment_method}
							>
								<MenuItem value={'Transferencia'}>Transferencia</MenuItem>
							</Select>
							<FormHelperText> </FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={11} sm={5}>
						<FormControl fullWidth>
							<TextField
								id={`owner`}
								label="Titular de la cuenta"
								type="text"
								value={this.state.owner}
								disabled={false}
								onChange={this.handlerOwner}
								error={this.state.errors.owner}
								variant="outlined"
								helperText=" "
							/>
						</FormControl>
					</Grid>

					<Grid item xs={11} sm={5}>
						<FormControl fullWidth>
							<TextField
								id={`bank`}
								label="Banco"
								type="text"
								value={this.state.bank}
								disabled={false}
								onChange={this.handlerBank}
								error={this.state.errors.bank}
								variant="outlined"
								helperText=" "
							/>
						</FormControl>

					</Grid>

					<Grid item xs={11} sm={5}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="demo-simple-select-label">Tipo de cuenta</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id={`account_type`}
								name={`account_type`}
								value={this.state.account_type === null ? '' : this.state.account_type}
								label="Tipo de cuenta"
								onChange={this.handlerAccountType}
								error={this.state.errors.account_type}
							>
								{this.getAccountTypeOptions()}
							</Select>
							<FormHelperText> </FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={11} sm={5}>
						<FormControl fullWidth>
							<TextField
								id={`account_number`}
								label="Número de cuenta"
								type="text"
								value={this.state.account_number}
								disabled={false}
								onChange={this.handlerAccountNumber}
								error={this.state.errors.account_number}
								variant="outlined"
								helperText=" "
							/>
						</FormControl>
					</Grid>

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'ARG') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`cbu`}
									label="CBU"
									type="text"
									value={this.state.cbu}
									disabled={false}
									onChange={this.handlerCbu}
									error={this.state.errors.cbu}
									variant="outlined"
									helperText="Ingrese 22 dígitos numéricos"
									inputProps={{ maxLength: 22 }}
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'ARG') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`alias`}
									label="Alias"
									type="text"
									value={this.state.alias}
									disabled={false}
									onChange={this.handlerAlias}
									error={this.state.errors.alias}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'ARG') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`cuit_cuil`}
									label="CUIL/CUIT"
									type="text"
									value={this.state.cuit_cuil}
									disabled={false}
									onChange={this.handlerCuitCuil}
									error={this.state.errors.cuit_cuil}
									variant="outlined"
									helperText="Ingrese 11 dígitos numéricos, sin guiones ni puntos"
									inputProps={{ maxLength: 11 }}
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'CHL') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`rut`}
									label="RUT"
									type="text"
									value={this.state.rut}
									disabled={false}
									onChange={this.handlerRut}
									error={this.state.errors.rut}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'COL') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`cedula`}
									label="Cédula"
									type="text"
									value={this.state.cedula}
									disabled={false}
									onChange={this.handlerCedula}
									error={this.state.errors.cedula}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'COL') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`adress`}
									label="Dirección de Colombia del pasajero"
									type="text"
									value={this.state.adress}
									disabled={false}
									onChange={this.handlerAdress}
									error={this.state.errors.adress}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'COL') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`phone`}
									label="Teléfono"
									type="text"
									value={this.state.phone}
									disabled={false}
									onChange={this.handlerPhone}
									error={this.state.errors.phone}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}


					{(this.state.paisResidencia !== null && !(this.state.paisResidencia === 'ARG' || this.state.paisResidencia === 'CHL' || this.state.paisResidencia === 'COL')) ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`swift`}
									label="SWIFT"
									type="text"
									value={this.state.swift}
									disabled={false}
									onChange={this.handlerSwift}
									error={this.state.errors.swift}
									variant="outlined"
									helperText="Ingrese de 8 a 11 dígitos"
									inputProps={{ maxLength: 11 }}
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && !(this.state.paisResidencia === 'ARG' || this.state.paisResidencia === 'CHL' || this.state.paisResidencia === 'COL')) ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`doc_number`}
									label="Documento"
									type="text"
									value={this.state.doc_number}
									disabled={false}
									onChange={this.handlerDoc_number}
									error={this.state.errors.doc_number}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && !(this.state.paisResidencia === 'ARG' || this.state.paisResidencia === 'CHL' || this.state.paisResidencia === 'COL')) ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`bank_adress`}
									label="Dirección del banco"
									type="text"
									value={this.state.bank_adress}
									disabled={false}
									onChange={this.handlerBank_adress}
									error={this.state.errors.bank_adress}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'MEX') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`cable`}
									label="Número de Cable de la cuenta"
									type="text"
									value={this.state.cable}
									disabled={false}
									onChange={this.handlerCable}
									error={this.state.errors.cable}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}


					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'USA') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`ba_wire`}
									label="BA WIRE"
									type="text"
									value={this.state.ba_wire}
									disabled={false}
									onChange={this.handlerBa_wire}
									error={this.state.errors.ba_wire}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					{(this.state.paisResidencia !== null && this.state.paisResidencia === 'Europa') ?
						<Grid item xs={11} sm={5}>
							<FormControl fullWidth>
								<TextField
									id={`iban`}
									label="IBAN"
									type="text"
									value={this.state.iban}
									disabled={false}
									onChange={this.handlerIban}
									error={this.state.errors.iban}
									variant="outlined"
									helperText=" "
								/>
							</FormControl>
						</Grid>
						: <></>
					}

					<Grid item xs={11} sm={5}>
					</Grid>

					{(this.state.refund_method !== null && this.state.refund_method !== 'Cuenta propia') ?
						<Grid item xs={11} sm={11} style={{ marginLeft: '8%' }}>
							<Button
								variant="outlined"
								color="primary"
								component="label"
								startIcon={<Icon>upload</Icon>}>
								Subir autorización
								<input
									hidden
									accept='.png, .jpg, .jpeg ,application/pdf'
									multiple
									type="file"
									onChange={this.handleFileEventUploadAuth}
									disabled={this.state.fileLimitAuth}
								/>
							</Button>
							{(this.state.authorization_letter.length > 0) ?
								this.state.authorization_letter.map((file, index) => (
									<Chip
										key={index}
										label={`${file.name}`}
										onDelete={() => { this.deleteFileAuth(file.name) }}
										variant="outlined"
										style={{
											margin: 5
										}}
									/>
								))
								: (this.state.errors.authorized_document)
									? <Typography variant="caption" style={{ color: "red" }}> Debe cargar al menos 1 archivo para continuar</Typography>
									: <> </>}
							<div>
								<Typography
									component="span"
									variant="caption"
									onClick={this.handlerDownloadModel}
								>
									<i>*carta de puño y letra firmada de autorización.</i> <u style={{ color: "blue", cursor: "pointer" }}>Descargar Modelo </u>
								</Typography>
							</div>
						</Grid>
						: <></>
					}

					{(this.state.refund_method !== null && this.state.refund_method !== 'Cuenta propia') ?
						<Grid item xs={11} sm={11} style={{ marginLeft: '8%' }}>
							<Button
								variant="outlined"
								color="primary"
								component="label"
								startIcon={<Icon>upload</Icon>}>
								subir documento
								<input
									hidden
									accept='.png, .jpg, .jpeg ,application/pdf'
									multiple
									type="file"
									onChange={this.handleFileEventUploadDocAuth}
									disabled={this.state.fileLimitDocAuth}
								/>
							</Button>
							{(this.state.authorized_document.length > 0) ?
								this.state.authorized_document.map((file, index) => (
									<Chip
										key={index}
										label={`${file.name}`}
										onDelete={() => { this.deleteFileDocAuth(file.name) }}
										variant="outlined"
										style={{
											margin: 5
										}}
									/>
								))
								:
								(this.state.errors.authorized_document)
									? <Typography variant="caption" style={{ color: "red" }}> Debe cargar al menos 1 archivo para continuar</Typography>
									: <>
									</>}
							<Typography component="div" variant="caption" >
								<i> *Foto de frente del documento o pasaporte </i>
							</Typography>
						</Grid>
						: <></>
					}

				</Grid>

			</Fragment>
		);
	}
}

const mapStateToProps = reducers => {

	return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
	return {
		getLoginReintegro: (form) => dispatch({ type: "GET_LOGIN_REINTEGRO", form }),
	};
};

export default connect(
	mapStateToProps,
	mapDispachToProps,
	null,
	{ forwardRef: true }
)
	(PaymentForm)
