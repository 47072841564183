import React, { Component } from 'react';
import { Paper, Chip, TextField, InputAdornment, Typography, Button, Icon } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

export default class Chips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chipData: props.chips,
            id: '',
            autocompleteActive: false,
            autoCompleteValue: '',
            autoCompleteId: '',
            valueTextField:'',
        }
    }

    handleAdd = (chipToAdd, i) => () => {
        let res = [];
        this.state.chipData.map(val => {
            if (val.key === chipToAdd.key) {
                val.selected = true
            }
            res.push(val)
        })

        this.setState({ chipData: res })
    };

    handleDelete = (zoneId) => () => {
        let res = [];

        this.state.chipData.map(val => {
            if (val.id !== zoneId)
                res.push(val)
        })
        this.setState({ chipData: res })
        this.props.callbackAdd(res)

    }

    applyAutocomplete = () => {

        let include = this.state.chipData.filter(e => e.label === this.state.autoCompleteValue);

        if (Object.keys(include).length === 0 && this.state.autoCompleteValue !== "") {
            let res = [];
            this.state.chipData.map(val => {
                res.push(val)
            });

            res.push({ 'id': this.state.autoCompleteId, "label": this.state.autoCompleteValue, 'selected': false })
            this.setState({valueTextField:''})
            this.props.callbackAdd(res)
        }
    }
    UNSAFE_componentWillReceiveProps(next_props){

        this.setState({chipData:next_props.chips})
    }

    getValue = (object, value, reason) => {
        this.setState({
            autoCompleteValue: value[this.props.optionLabel],
            autoCompleteId: value.id
        })
    }

    // [opcional] ejecutar una funcion pasada en las props cada vez que se selecciona un valor nuevo para el textField
    doChangeAction = (event) => {
       if (this.props.changeAction!==undefined){
            this.props.changeAction(event.target.value)
       }
    }

    render() {
        //console.log("valuetextfield", this.state.valueTextField);
        const {title,view,options,label,placeholder,optionLabel}= this.props
        return (
            <Paper className="filter_tours_chips">

                <Typography className="title_filters">{title}</Typography>

                <div className={`chips ${this.state.autoCompleteActive ? 'search-open' : ''}`}>

                    {this.state.chipData.map((data, i) => {
                        return (
                            <Chip key={data.id}
                                size="small"
                                disabled={view === 'show' ? true : false}
                                label={data.label}
                                className={`chip ${data.selected ? 'selected' : ''}`}
                                onDelete={this.handleDelete(data.id)}
                            />
                        );
                    })}
                </div>

                <div className="autocomplete_container">
                    {view !== "show" &&
                        <Autocomplete
                            inputValue={this.state.valueTextField}
                            onInputChange={(event, newInputValue) => {
                                this.setState({valueTextField: newInputValue});
                            }}
                            onChange={this.getValue}
                            fullWidth
                            freeSolo
                            disableClearable
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="autocomplete"
                            options={options}
                            getOptionLabel={(option) => { return option[optionLabel] }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange= {(e) => {this.doChangeAction(e)}}
                                    variant="outlined"

                                    InputProps={{
                                        ...params.InputProps, type: 'search', startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    label={label}
                                    placeholder={placeholder}
                                    fullWidth
                                    error= {this.props.error ? this.props.error : false }
                                    helperText = {this.props.errorText ? this.props.errorText : ''}
                                />
                            )}
                        />}

                </div>
                {view !== "show" &&
                    <div className="button_filter">
                        <Button onClick={this.applyAutocomplete} variant="contained" color="primary" className="ml-05">Aplicar</Button>
                    </div>}
            </Paper>
        );
    }
}