const initialState = {
	listVouchersResponse: [],
	responseVoucher: [],
	responseCancelAllVouchers: [],
	responseChangeStatusVouchers: [],
	responseVoucherError: [],
	responsePrintVoucher: [],
	responsePrintMultivoucher: [],
	responseHashExportVouchers: [],
	responseCheckReportVouchers: [],
	responseUpdateReportVouchers: [],
	responseSendTermsAndConditions: [],
	responseCCGG: [],
	responseMultivoucher: [],
	errorVoucher: false,
	errorChangeStatusVoucher: false,
	errorHashExportVouchers: false,
	errorCheckReportVouchers: false,
	errorUpdateReportVouchers: false,
	errorSendTermsAndConditions: false,
	errorCCGG: false,
	errorMultivoucher: false,
	errorPrintMultivoucher: false,
};

const reducer = (state = initialState, action) => {
	const newState = { ...state };

	switch (action.type) {
		case 'VOUCHERS_LIST_RESPONSE':
			if (action.value.detail !== undefined) {
				newState.listVouchersResponse = [];
				newState.responseVoucherError = action.value;
				newState.errorVoucher = true;
			} else {
				newState.listVouchersResponse = action.value;
				newState.responseVoucherError = [];
				newState.errorVoucher = false;
			}
			newState.responseVoucher = [];
			break;

		case 'VOUCHER_RESPONSE':
			if (action.value.detail !== undefined) {
				newState.responseVoucherError = action.value;
				newState.responseVoucher = [];
				newState.errorVoucher = true;
			} else {
				newState.responseVoucherError = [];
				newState.responseVoucher = action.value;
				newState.errorVoucher = false;
			}
			break;
		case 'CANCEL_VOUCHERS_RESPONSE':
			if (
				action.value.detail !== undefined ||
				action.value.status == undefined
			) {
				newState.responseVoucherError = action.value;
				newState.responseCancelAllVouchers = [];
				newState.errorVoucher = true;
			} else {
				newState.responseVoucherError = [];
				newState.responseCancelAllVouchers = action.value;
				newState.errorVoucher = false;
			}
			break;
		case 'CHANGE_STATUS_VOUCHERS_RESPONSE':
			if (action.value.message != undefined) {
				newState.responseChangeStatusVouchers = action.value;
				newState.responseVoucherError = [];
				newState.errorChangeStatusVoucher = false;
			} else {
				newState.responseChangeStatusVouchers = [];
				newState.responseVoucherError = action.value;
				newState.errorChangeStatusVoucher = true;
			}
			break;

		case 'PRINT_VOUCHER_RESPONSE':
			if (action.value.status !== undefined) {
				if (action.value.status === 'OK') {
					newState.responsePrintVoucher = action.value;
					newState.errorVoucher = false;
				} else if (action.value.status === 'Error') {
					newState.responsePrintVoucher = action.value;
					newState.errorVoucher = true;
				}
			} else {
				newState.responsePrintVoucher = [];
				newState.errorVoucher = true;
			}
			break;

		case 'PRINT_MULTIVOUCHER_RESPONSE':
			if (action.value.status !== undefined) {
				if (action.value.status === 'OK') {
					newState.responsePrintMultivoucher = action.value;
					newState.errorPrintMultivoucher = false;
				} else if (action.value.status === 'Error') {
					newState.responsePrintMultivoucher = action.value;
					newState.errorPrintMultivoucher = true;
				}
			} else {
				newState.responsePrintMultivoucher = [];
				newState.errorPrintMultivoucher = true;
			}
			break;

		case 'SEND_TERMS_AND_CONDITIONS_RESPONSE':
			if (action.value.status !== undefined) {
				if (action.value.status === 'OK') {
					newState.responseSendTermsAndConditions = action.value;
					newState.errorSendTermsAndConditions = false;
				} else if (action.value.status === 'Error') {
					newState.responseSendTermsAndConditions = action.value;
					newState.errorSendTermsAndConditions = true;
				}
			} else {
				newState.responseSendTermsAndConditions = [];
				newState.errorSendTermsAndConditions = true;
			}

			break;

		case 'SEND_CCGG_RESPONSE':
			if (action.value.status !== undefined) {
				if (action.value.status === 'OK') {
					newState.responseCCGG = action.value;
					newState.errorCCGG = false;
				} else if (action.value.status === 'Error') {
					newState.responseCCGG = action.value;
					newState.errorCCGG = true;
				}
			} else {
				newState.responseCCGG = [];
				newState.errorCCGG = true;
			}
			break;

		case 'SEND_MULTIVOUCHER_RESPONSE':
			if (action.value.status !== undefined) {
				if (action.value.status === 'OK') {
					newState.responseMultivoucher = action.value;
					newState.errorMultivoucher = false;
				} else if (action.value.status === 'Error') {
					newState.responseMultivoucher = action.value;
					newState.errorMultivoucher = true;
				}
			} else {
				newState.responseMultivoucher = [];
				newState.errorMultivoucher = true;
			}
			break;

		case 'HASH_EXPORT_VOUCHERS_RESPONSE':
			newState.responseHashExportVouchers = action.value;
			if (action.value.detail !== undefined) {
				newState.errorHashExportVouchers = true;
			} else {
				newState.errorHashExportVouchers = false;
			}
			break;
		case 'REPORT_EXPORT_VOUCHERS_RESPONSE':
			newState.responseCheckReportVouchers = action.value;
			if (action.value.detail !== undefined) {
				newState.errorCheckReportVouchers = true;
			} else {
				newState.errorCheckReportVouchers = false;
			}
			break;
		case 'UPDATE_EXPORT_VOUCHERS_RESPONSE':
			newState.responseUpdateReportVouchers = action.value;
			if (action.value.detail !== undefined) {
				newState.errorUpdateReportVouchers = true;
			} else {
				newState.errorUpdateReportVouchers = false;
			}
			break;
		default:
			return newState;
	}

	return newState;
};

export default reducer;
