import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';

function* getLogin(actions) {

    try {
        const headers = {
            'Content-Type': "application/json"
        };

        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.form)
        }; 
        let url = config.credentials.url + '/token/'
        const data = yield call(apiCall, 'post', url, requestOptions)

        yield put({ type: "GET_LOGIN_RESPONSE", value: data })

    } catch (error) {
        yield put({ type: "GET_LOGIN_RESPONSE", value: error })
    }
}

function* getLoginReintegro(actions) {

    try {
        const headers = {
            'Content-Type': "application/json"
        };

        const requestOptions = {
            headers: headers,
        }; 

        let url =  config.credentials.url + '/refund_data/?voucher_id='+actions.form.voucherId + '&document=' + actions.form.documento
    
        const data = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "GET_LOGIN_REINTEGRO_RESPONSE", value: data })

    } catch (error) {
        yield put({ type: "GET_LOGIN_REINTEGRO_RESPONSE", value: error })
    }
}

function* getLogout(actions) {
    // TO-DO: Enviar actions.access al backend para eliminar/deshabilitar token en la BD ya que no será mas valido.
    put({ type: "GET_LOGOUT" })
}

function* getLogoutReintegro(actions) {
    // TO-DO: Enviar actions.access al backend para eliminar/deshabilitar token en la BD ya que no será mas valido.
    put({ type: "GET_LOGOUT_REINTEGRO" })
}

function* uploadReintegro(actions) {
    try {
        const headers = {
            'Content-Type': "application/json"
        };

        const requestOptions = {
            headers: headers,
        }; 

        let url =  config.credentials.url + '/authentication_pending_doc/?refund_id='+actions.form.refund_id + '&hash=' + actions.form.hash
    
        const data = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "GET_UPLOAD_REINTEGRO_RESPONSE", value: data })

    } catch (error) {
        yield put({ type: "GET_UPLOAD_REINTEGRO_RESPONSE", value: error })
    }
}


/*
* Para usuarios logeado que quieren modificar su contraseña
*/
function* reset(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
        };
        const requestOptions = {
            headers: headers, 
            body: JSON.stringify(actions.data) // email
        };

        const response = yield call(apiCall, 'POST',config.credentials.url+'/password_reset/', requestOptions);

        yield put({ type: "PASSWORD_RESET_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PASSWORD_RESET_RESPONSE", value: error })
    }
}

/*
* Envio de nueva contraseña.
*/
function* confirm(actions) {
    try {

        const headers = {
            'Content-Type': "application/json",
        };
        const requestOptions = {
            headers: headers, 
            body: JSON.stringify(actions.data) // token y nueva contraseña.
        };

        const response = yield call(apiCall, 'POST',config.credentials.url+'/password_reset/confirm/', requestOptions);

        yield put({ type: "PASSWORD_CONFIRM_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PASSWORD_CONFIRM_RESPONSE", value: error })
    }
}

export function* login() {
    yield takeEvery("GET_LOGIN", getLogin)
}

export function* logout() {
    yield takeEvery("GET_LOGOUT", getLogout)
}

export function* logoutReintegro() {
    yield takeEvery("GET_LOGOUT_REINTEGRO", getLogoutReintegro)
}

export function* resetPassword() {
    yield takeEvery("RESET_PASSWORD", reset)
}
export function* confirmPassword() {
    yield takeEvery("CONFIRM_PASSWORD", confirm)
}

export function* loginReintegro() {
    yield takeEvery("GET_LOGIN_REINTEGRO", getLoginReintegro)
}

export function* getUploadReintegro() {
    yield takeEvery("GET_UPLOAD_REINTEGRO", uploadReintegro)
}