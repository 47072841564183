import React, { Component } from 'react';
import StringNumber from '../../common/fields/StringNumber';
import { Redirect } from "react-router-dom";
import { Grid, Typography, Button, Container, } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { connect } from "react-redux";

class LoginReintegro extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            documento: [],
            voucherId: [],
            reponseLogin: false,
            errors: {
                status: {
                    documento: false,
                    voucherId: false
                },
                text: {
                    documento: "",
                    voucherId: ""
                }
            }
        };
    }

    send = async () => {

        let form = new Object();
        let countValid = 0;
        let errors = [];

        if (this.state.documento && this.state.documento !== null) {
            if (this.state.documento.state.defaultValue !== '') {
                countValid++;
                form[this.state.documento.props.name] = this.state.documento.state.defaultValue;
            }
        }

        if (this.state.voucherId && this.state.voucherId !== null) {
            if (this.state.voucherId.state.defaultValue !== '') {
                countValid++;
                form[this.state.voucherId.props.name] = this.state.voucherId.state.defaultValue;
            }
        }

        errors = {
            status: {
                documento: (this.state.documento && this.state.documento.state.defaultValue !== '' && this.state.documento.state.errorText === '') ? false : true,
                voucherId: (this.state.voucherId && this.state.voucherId.state.defaultValue !== '' && this.state.voucherId.state.errorText === '') ? false : true
            },
            text: {
                documento: (this.state.documento && this.state.documento.state.defaultValue !== '' && this.state.documento.state.errorText === '') ? "" : "Ingrese su documento",
                voucherId: (this.state.voucherId && this.state.voucherId.state.defaultValue !== '' && this.state.voucherId.state.errorText === '') ? "" : "Ingrese su número de voucher"
            }
        }

        this.setState({ errors: errors });

        if (2 === countValid) {
            this.props.getLoginReintegro(form);
        }
    }

    collapseAlert = () => {
        this.setState({ open: !this.state.open })
    }

    render() {
        return (
            this.props.responseLoginReintegro
                ? (<Redirect to={{
                    pathname: "/dashboardRefund", state: {
                        loginResponse: this.props.responseDataLoginReintegro
                    }
                }} />)
                : (
                    <Container maxWidth="md" className="mx-auto">
                        <div className="login_container">

                            <div className="form-header">
                                <Typography component="h1" variant="h3" className="title">Solicitud de reintegro</Typography>
                                <hr style={{ borderColor: "#1751a1", color: "#1751a1" }} />
                                <Typography component="h2" variant="h4" className="subtitle">Por favor ingrese sus datos para continuar.</Typography>
                            </div>

                            {this.props.errorLoginReintegro &&
                                (<Alert severity="error" className="mb-2">
                                    <AlertTitle>Datos inválidos</AlertTitle>
                                    {this.props.errorLoginReintegro &&
                                        (this.props.mensajeError)}
                                </Alert>)}

                            <form className="form">
                                <Grid container spacing={2}>
                                    <Grid item md={1}></Grid>
                                    <Grid item md={10} sm={11}>
                                        <StringNumber
                                            ref={element => { this.state.documento = element }}
                                            id={`documento`}
                                            label="Ingrese su documento"
                                            name={`documento`}
                                            placeholder="Ingrese su documento "
                                            defaultValue={''}
                                            disabled={false}
                                            error={this.state.errors.status.documento}
                                            errorText={this.state.errors.text.documento}
                                            classNameCustom={'textField'}    
                                            handleError={true}
                                            variant="standard"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={1}></Grid>
                                    <Grid item md={10} sm={11}>
                                        <StringNumber
                                            ref={element => { this.state.voucherId = element }}
                                            id={`voucherId`}
                                            label="Ingrese el número de voucher"
                                            name={`voucherId`}
                                            placeholder="Ingrese el número de voucher"
                                            defaultValue={''}
                                            disabled={false}
                                            error={this.state.errors.status.voucherId}
                                            errorText={this.state.errors.text.voucherId}
                                            classNameCustom={'textField'}
                                            variant="standard"
                                        />
                                    </Grid>
                                </Grid>

                                <Typography variant="caption" component="div">Importante: recuerde que para solicitar un reintegro tiene
                                    que realizarlo dentro de los 30 días posteriores a la
                                    finalización de la vigencia del voucher.</Typography>

                                <div className="text-right" style={{ marginTop: "10px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="submit"
                                        size="large"
                                        onClick={() => this.send()}
                                    >
                                        Continuar
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Container>
                )

        )
    }
}

const mapStateToProps = reducers => {

    return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
    return {
        //getLogin: (form) => dispatch({ type: "GET_LOGIN", form }),
        getLoginReintegro: (form) => dispatch({ type: "GET_LOGIN_REINTEGRO", form }),
    };
};

export default connect(mapStateToProps, mapDispachToProps)(LoginReintegro)

