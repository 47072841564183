const initialState = {
    responseCategoriesList: [],
    responseCategory: []
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "CATEGORIES_RESPONSE_LIST":
            console.log("CATEGORIES_RESPONSE_LIST")
            if (action.value.detail !== undefined)
                newState.responseCategoriesList = []
            else
                newState.responseCategoriesList = action.value;
            
            newState.responseCategory = []
            break;
        case "CATEGORY_RESPONSE":
           // console.log("CATEGORY_RESPONSE")
            newState.responseCategory = action.value;
            break;
        default: 
            return newState;
    }

    return newState;
};

export default reducer;