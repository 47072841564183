import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* group(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/groups/?offset=0&limit='+actions.limit : config.credentials.url + '/groups/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "GROUP_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "GROUP_RESPONSE", value: error })
    }
}

function* groups(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/groups/?offset=0&limit='+actions.limit : config.credentials.url + '/groups/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
   
        yield put({ type: "GROUPS_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "GROUPS_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        const responseGroups = yield call(apiCall, 'POST',config.credentials.url+'/groups/', requestOptions);

        if(responseGroups.id !== '' ){

            actions.data.status.group = responseGroups.id

            const requestOptionsStatus = {
                headers: headers,
                body: JSON.stringify(actions.data.status)
            };

            const response = yield call(apiCall, 'POST',config.credentials.url+'/status_group/', requestOptionsStatus);

            yield put({ type: "GROUP_RESPONSE", value: response })

        }else{
            yield put({ type: "GROUP_RESPONSE", value: responseGroups })
        }
    } catch (error) {
        yield put({ type: "GROUP_RESPONSE", value: error })
    }
}

function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };

        const getresponse = yield call(apiCall, 'get',config.credentials.url+'/status_group/?group=' + actions.data, requestOptions);
        const responseDeleteGroup = yield call(apiCall, 'delete',config.credentials.url+'/status_group/' + getresponse.results[0].id +'/' , requestOptions);
        
        if(responseDeleteGroup.ok){
            const response = yield call(apiCall, 'delete', config.credentials.url+ '/groups/' + actions.data, requestOptions)
            yield put({ type: "GROUP_RESPONSE", value: response })
        }else{
            yield put({ type: "GROUP_RESPONSE", value: responseDeleteGroup })
        }

       
 
    } catch (error) {
        yield put({ type: "GROUP_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const responseGroups = yield call(apiCall, 'put', config.credentials.url+ '/groups/' + actions.id+ '/', requestOptions)
            
        if(responseGroups.id !== '' ){
            actions.data.status.group = responseGroups.id
            const requestOptionsGetGroup = {
                headers: headers,
            };

            const requestOptionsStatus = {
                headers: headers,
                body: JSON.stringify(actions.data.status)
            };
            const getresponse = yield call(apiCall, 'get',config.credentials.url+'/status_group/?group=' + actions.id, requestOptionsGetGroup);
            const response = yield call(apiCall, 'put',config.credentials.url+'/status_group/' + getresponse.results[0].id +'/' , requestOptionsStatus);

            yield put({ type: "GROUP_RESPONSE", value: response })
        }else{
            yield put({ type: "GROUP_RESPONSE", value: responseGroups })
        }

    } catch (error) {
        yield put({ type: "GROUP_RESPONSE", value: error })
    }
}


export function* getGroup() {
    yield takeEvery("GET_GROUP", group)
}
export function* getGroupsList() {
    yield takeEvery("GET_GROUPS", groups)
}

export function* addGroup() {
    yield takeEvery("ADD_GROUP", add)
}

export function* removeGroup() {
    yield takeEvery("REMOVE_GROUP", remove)
}

export function* editGroup() {
    yield takeEvery("EDIT_GROUP", edit)
}
