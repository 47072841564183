import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* user(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/profile/' : config.credentials.url + '/profile/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions);

        if (response.id == undefined || response.id  == null) {
            response["invalidToken"] = "Hubo un error";
        }

        switch (actions.queryType) {
            case "VIEW":
                yield put({ type: "USER_RESPONSE", value: response })
                break;
            case "LOGIN":
                yield put({ type: "USER_LOGIN_RESPONSE", value: response,access:actions.access })
                break;
            default: 
                break;
        }
    } catch (error) {
        yield put({ type: "USER_RESPONSE", value: error })
    }
}

function* users(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/profile/?offset=0&limit='+actions.limit : config.credentials.url + '/profile/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
   
        yield put({ type: "USERS_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "USERS_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         console.log("saga user envia nuevo usuario a crear");
        const response = yield call(apiCall, 'POST',config.credentials.url+'/profile/', requestOptions);  

        yield put({ type: "USER_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "USER_RESPONSE", value: error })
    }
}

function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/profile/' + actions.data+ '/', requestOptions)

        yield put({ type: "USER_RESPONSE", value: response})    
 
    } catch (error) {
        yield put({ type: "USER_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/profile/' + actions.id+ '/', requestOptions)

        yield put({ type: "USER_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "USER_RESPONSE", value: error })
    }
}

function* permissions(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = config.credentials.url + '/permission/?offset=0&limit='+actions.limit;
        
        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "PERMISSIONS_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PERMISSIONS_RESPONSE", value: error })
    }
}

export function* getUser() {
    yield takeEvery("GET_USER", user)
}
export function* getUsersList() {
    yield takeEvery("GET_USERS", users)
}

export function* addUser() {
    yield takeEvery("ADD_USER", add)
}

export function* removeUser() {
    yield takeEvery("REMOVE_USER", remove)
}

export function* editUser() {
    yield takeEvery("EDIT_USER", edit)
}

export function* getPermission() {
    yield takeEvery("GET_PERMISSIONS", permissions)
}
