import React, { Fragment, Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default class RadioButtons extends Component {

	constructor(props) {
		super(props);
		this.state = {
			defaultValue: props.defaultValue
		}
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		if (next_props.initial !== undefined && next_props.initial) {
			this.setState({ defaultValue: next_props.defaultValue });
		}
	}

	handleChange = event => {
		this.setState({ defaultValue: event.target.value })
		if(this.props.callback !== undefined)
			this.props.callback(event.target.value)

	};
	render() {
		const { id, name, label, defaultValue, optionsSelect, disabled } = this.props;
		return (
			<Fragment>
				<FormControl component="fieldset" variant="outlined" fullWidth className="radio">
					<RadioGroup
						aria-label={label}
						name={name}
						id={id}
						onChange={this.handleChange}
						defaultValue={defaultValue}

						row
						className={this.props.classNameCustom}
					>
						{optionsSelect.map((option, i) => (
							<FormControlLabel key={i}  value={option.code} disabled={disabled} control={<Radio color="primary" />} label={option.description} />
						))}
					</RadioGroup>
				</FormControl>
			</Fragment>
		);
	}
}
