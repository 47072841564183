import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* currency(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/currency/' : config.credentials.url + '/currency/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "CURRENCY_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "CURRENCY_RESPONSE", value: error })
    }
}

function* currencies(actions) {

    try {
        
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/currency/?offset=0&limit='+actions.limit : config.credentials.url + '/currency/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "CURRENCIES_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "CURRENCIES_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/currency/', requestOptions);

      

        yield put({ type: "CURRENCY_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "CURRENCY_RESPONSE", value: error })
    }
}


function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/currency/' + actions.data+ '/', requestOptions)


        yield put({ type: "CURRENCY_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "CURRENCY_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/currency/' + actions.id+ '/', requestOptions)

        yield put({ type: "CURRENCY_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "CURRENCY_RESPONSE", value: error })
    }
}


export function* getCurrency() {
    yield takeEvery("GET_CURRENCY", currency)
}
export function* getCurrenciesList() {
    yield takeEvery("GET_CURRENCIES", currencies)
}

export function* addCurrency() {
    yield takeEvery("ADD_CURRENCY", add)
}

export function* removeCurrency() {
    yield takeEvery("REMOVE_CURRENCY", remove)
}

export function* editCurrency() {
    yield takeEvery("EDIT_CURRENCY", edit)
}
