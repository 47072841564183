import React, { Component, Fragment, useEffect, useState } from "react";
import { Collapse, List, IconButton, ListItem, ListItemIcon, ListItemText, Icon, Drawer } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import config from '../../../config/config.json';
import clsx from 'clsx';
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight } from '@material-ui/icons/';
import {enabledPermissions} from '../../../services/enabledPermissions';
import { connect } from "react-redux";

const Sidebar = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const { label, submenu, icon, onClick: onClickProp } = props.sidebar
    const [depth, setDepth] = useState(0);
    const [enabledSidebarItems, setEnabledSidebarItems] = useState([]);
    
    function toggleCollapse() {
        setCollapsed(prevValue => !prevValue);
    }
    const handleDrawerClose = () => {
        setCollapsed(!collapsed);
    };

    function onClick(e) {
        if (Array.isArray(submenu)) {
            toggleCollapse();
        }
        if (onClickProp) {
            onClickProp(e, props.item);
        }
    }

    /*
    * Actualiza el state EnabledSisebarItems ni bien se renderiza el componente 
    */
    useEffect(() => {
        if (window.localStorage.getItem( 'userData' )!=undefined){
			console.log("[useEffect] actualizando items del sidebar permitidos");
            let permissionsItems = JSON.parse( window.localStorage.getItem( 'userData' ) ).modules;    
            setEnabledSidebarItems( enabledPermissions(props.sidebar, permissionsItems) )
        }
    },[props.userData]);


    const SidebarItem = (props) => {
        /* console.log(props.item) */
        const [collapsed, setCollapsed] = React.useState(true);
        const { listItemText: { primary }, submenu, routing, icon, onClick: onClickProp } = props.item;

        function toggleCollapse() {
            setCollapsed(prevValue => !prevValue);
        }

        function onClick(e) {
            if (Array.isArray(submenu)) {
                toggleCollapse();
            }
            if (onClickProp) {
                onClickProp(e, props.item);
            }
        }

        let expandIcon;

        if (Array.isArray(submenu) && submenu.length) {
            expandIcon = !collapsed ? (
                <ExpandLessIcon />
            ) : (
                <ExpandMoreIcon />
            );
        }

        return (
            <Fragment>
                {routing !== "" ?
                    (<ListItem button component="a" href={`/${routing}`}>
                        <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
                        <ListItemText primary={primary} />
                        {expandIcon}
                    </ListItem>)
                    :
                    (<ListItem button onClick={onClick}{...props}>
                        <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
                        <ListItemText primary={primary} />
                        {expandIcon}
                    </ListItem>)}


                <Collapse in={!collapsed} timeout="auto" unmountOnExit className={'nested'} >
                    {Array.isArray(submenu) ? (                        
                        <List component="div" disablePadding>
                            {submenu.map((subItem, index) => (
                                <SidebarItem key={index}
                                    depth={depth + 1}
                                    item={subItem}
                                />
                            ))}
                           {/*  {submenu.map((subItem, index) => (
                                <SidebarItem key={index}
                                    depth={depth + 1}
                                    item={subItem}
                                />
                            ))} */}
                        </List>
                    ) : null}
                </Collapse>
            </Fragment>
        );
    }

    return (
        <Drawer variant="permanent" classes={{ paper: clsx('drawerPaper', !collapsed && 'drawerPaperClose') }} open={collapsed} >
            <List component="nav" aria-labelledby="nested-list-subheader" className="sidebarnav-list" style={{ marginTop: '64px' }}>
                {enabledSidebarItems.map((sidebarItem, index) => (
                    <SidebarItem 
                        key={index}
                        depth={depth}
                        item={sidebarItem}
                    />
                ))}
            </List>
            <IconButton className="toolbarDrawerButton" onClick={handleDrawerClose}>
                {!collapsed ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
        </Drawer>
    );
}

class SidebarNavigation extends Component {
    constructor(props) {
		super(props);
	}
    render() {
        return (
            <Sidebar userData={this.props.responseUserLogin} sidebar={config.sidebar} />
        )
    }
}

const mapStateToProps = reducers => {
	return reducers.loginReducer;
};

export default connect(mapStateToProps)(SidebarNavigation);