import { combineReducers } from "redux";
import productsReducer  from "./productsReducer";
import coveragesReducer from "./coveragesReducer";
import countriesReducer from "./countriesReducer";
import municipiosReducer from "./municipiosReducer";
import suppliersReducer  from "./suppliersReducer";
import zonesReducer from "./zonesReducer";
import categoriesReducer from "./categoriesReducer";
import groupsReducer from "./groupsReducer";
import usersReducer from "./usersReducer";
import officesReducer from "./officesReducer";
import promotionsReducer from "./promotionsReducer";
import tagsReducer from "./tagsReducer";
import businessUnitsReducer from "./businessUnitsReducer";
import currenciesReducer from "./currenciesReducer";
import passengersReducer from "./passengersReducer";
import loginReducer from "./loginReducer";
import reservationsReducer from "./reservationsReducer";
import vouchersReducer from "./vouchersReducer";
import productsSpertaReducer from "./productsSpertaReducer";
import facturacionReducer from "./facturacionReducer";
import blacklistReducer from "./blacklistReducer";
import paymentDetailReducer from "./paymentDetailReducer";

export default combineReducers({
  productsReducer,
  coveragesReducer,
  countriesReducer,
  municipiosReducer,
  suppliersReducer,
  zonesReducer,
  categoriesReducer,
  groupsReducer,
  usersReducer,
  officesReducer,
  promotionsReducer,
  tagsReducer,
  businessUnitsReducer,
  currenciesReducer,
  passengersReducer,
  loginReducer,
  reservationsReducer,
  vouchersReducer,
  productsSpertaReducer,
  facturacionReducer,
  blacklistReducer,
  paymentDetailReducer,
});
