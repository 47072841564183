import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* zone(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/zone/' : config.credentials.url + '/zone/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "ZONE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "ZONE_RESPONSE", value: error })
    }
}

function* zones(actions) {

    try {
        
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/zone/?offset=0&limit='+actions.limit : config.credentials.url + '/zone/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "ZONES_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "ZONES_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/zone/', requestOptions);

      

        yield put({ type: "ZONE_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "ZONE_RESPONSE", value: error })
    }
}


function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/zone/' + actions.data+ '/', requestOptions)


        yield put({ type: "ZONE_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "ZONE_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/zone/' + actions.id+ '/', requestOptions)

        yield put({ type: "ZONE_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "ZONE_RESPONSE", value: error })
    }
}


export function* getZone() {
    yield takeEvery("GET_ZONE", zone)
}
export function* getZonesList() {
    yield takeEvery("GET_ZONES", zones)
}

export function* addZone() {
    yield takeEvery("ADD_ZONE", add)
}

export function* removeZone() {
    yield takeEvery("REMOVE_ZONE", remove)
}

export function* editZone() {
    yield takeEvery("EDIT_ZONE", edit)
}
