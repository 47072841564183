import React, { Component, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import config from './../../../config/config.json';
import { IconButton, Grid, Collapse } from '@material-ui/core';
import DashboardCard from './DashboardCard';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import {enabledPermissions} from '../../../services/enabledPermissions';
import { connect } from "react-redux";

class Dashboard extends Component {
	constructor(props) {
		super(props);
	
		this.state = {
			open: true,
			userData: {},
			cards: [],
			loginStatus: false,
			passwordResetStatus: null,
			responseLogin: '',
			openAlert: true
		}
	}

	handleLogout = () => {
		this.props.getLogout(window.localStorage.getItem('headersSetCookie'));
	}

	componentDidMount = async () => {
		if (this.props.location.state === undefined) {
			this.setState({ loginStatus: false });
		} else {
			this.setState({ loginStatus: true, cards: config.cards });
		}
	}

	componentDidUpdate(prevProps,prevState,snapshot){
		//Recibe los cambios de valores del store correspondiente al usuario y lo almacena en el local storage.
		if (prevProps.responseUserLogin !== this.props.responseUserLogin){
			if (window.localStorage.getItem('userData') !== null){
					console.log("[componentDidUpdate] actualizando cards permitidas");
					let permissionsItems = JSON.parse(window.localStorage.getItem('userData')).modules;
					console.log("permissionItems",permissionsItems);
					console.log("enabledPermissions function", enabledPermissions( config.cards , permissionsItems))
					this.setState( { cards : enabledPermissions( config.cards , permissionsItems) } )
			}	
		}	

		//Recibe los cambios del store correspondientes a la solicitud de cambio de contraseña.
		if ((prevProps.responsePasswordReset != this.props.responsePasswordReset) 
		|| (prevProps.responseError != this.props.responseError && this.props.errorPassword)) {
			if(this.props.responsePasswordReset.status!= undefined && this.props.responsePasswordReset.status== 'OK'){   // no hubo errores
				console.log("Redireccionando al login por reset password");
				this.handleLogout();
				this.setState( { passwordResetStatus : this.props.responsePasswordReset.status } );
			}else{ // hubo errores
				this.setState({ passwordResetStatus : 'FAILED' })                
			}
		}
	}

	collapseAlert = () => {
		this.setState({ openAlert: false });
	}


	render() {
		return (
			this.state.passwordResetStatus == "OK"	// Si recibe una respuesta con status OK autorizando cambio de contraseña.
			?  <Redirect to={{ pathname: `/login`, state: { resetPassword: true } }} />
			: (window.localStorage.getItem('headersSetCookie')==undefined // Si no tiene info del usuario en localStorage
				? (<Redirect to={"/login"} exact />) 					  // Redirecciona al login
				: (<Fragment>
						{this.state.responseLogin !== "" && (		
							<Collapse in={this.state.openAlert}>
								<Alert severity="info" className="mb-2"
									action={
										<IconButton
											aria-label="close"
											color="inherit"
											size="small"
											onClick={() => {
												this.collapseAlert(false);
											}}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									}
								>
									{this.state.responseLogin}
								</Alert>
							</Collapse>
						)}
						
						{this.state.passwordResetStatus == "FAILED" && ( 
						        <Collapse in={this.state.open}>
                                        <Grid container spacing={1}>
                                            <Grid item sm={12}>
                                                <Alert severity="warning"
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={this.collapseAlert}
                                                        >
                                                            <CloseIcon fontSize="inherit" />
                                                        </IconButton>
                                                    }
                                                >
                                                    No podemos autorizar su cambio de contraseña. Verifique que tenga los permisos necesarios y/o intentelo nuevamente.
                                                </Alert>
                                            <br/>
                                            </Grid>
                                        </Grid>
                                    {/* </Container> */}
                                </Collapse>
						)}

						<Grid container spacing={3}>
							{this.state.cards.map((card, i) => {
								return (<DashboardCard
									key={i}
									indice={i}
									card={card}
								/>)
							})}
						</Grid>
					</Fragment>
				)
			)
		)
	}
}

const mapStateToProps = reducers => {
	return reducers.loginReducer;
};
const mapDispachToProps = dispatch => {
	return {
		getLogout: (access) => dispatch({ type: "GET_LOGOUT", access }),
	};
};

export default connect(mapStateToProps,mapDispachToProps)(Dashboard);