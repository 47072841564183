import React, { Component } from 'react'
import { MenuItem, Menu, Badge, IconButton, Popper, Grow, Paper, MenuList } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { NavLink as RouterLink } from "react-router-dom";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connect } from "react-redux";
import { saveAs } from "file-saver";

class MenuNotifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            hasNotifications: false,
            notifications: []
        }
        this.secondsToWait = 15
        this.intervalCheckNotifications = null
        this.intervalExportVouchers = null
        this.intervalExportOffices = null
        this.headersSetCookie = window.localStorage.getItem('headersSetCookie')
    }

    componentDidMount() {
        this.intervalCheckNotifications = window.setInterval(this.checkNotifications, this.secondsToWait * 1000);

        // Verifica si tiene que consultar estado de reporte de voucher
        if (window.localStorage.getItem('hashExportVouchers')) {
            let hashObj = JSON.parse(window.localStorage.getItem('hashExportVouchers'))
            if ((hashObj.status == "Pending") || (hashObj.status == undefined && hashObj.hash !== undefined)) {

                this.intervalExportVouchers = window.setInterval(this.checkReportExportVouchers, 10000, hashObj.hash);
            }
        }

        // Verifica si tiene que consultar estado de reporte de voucher
        if (window.localStorage.getItem('hashExportOffices')) {
            let hashObj = JSON.parse(window.localStorage.getItem('hashExportOffices'))
            if ((hashObj.status == "Pending") || (hashObj.status == undefined && hashObj.hash !== undefined)) {

                this.intervalExportOffices = window.setInterval(this.checkReportExportOffices, 10000, hashObj.hash);
            }
        }
    }

    /**
 * Recibe los cambios de valores del store correspondiente al export voucher.
  */
    componentDidUpdate(prevProps, prevState, snapshot) {

        //export voucher
        if (prevProps.vouchersReducer.responseCheckReportVouchers !== this.props.vouchersReducer.responseCheckReportVouchers) {
            // si no hubo error en respuesta.
            if (!this.props.vouchersReducer.errorCheckReportVouchers) {
                //verifica el estado.
                if (this.props.vouchersReducer.responseCheckReportVouchers.status !== "Pending") {
                    // detiene las peticiones 
                    clearInterval(this.intervalExportVouchers)
                    // actualiza item en localStorage. 
                    if (window.localStorage.getItem('hashExportVouchers')) {
                        let hashObj = JSON.parse(window.localStorage.getItem('hashExportVouchers'))
                        hashObj.status = this.props.vouchersReducer.responseCheckReportVouchers.status
                        hashObj.url = this.props.vouchersReducer.responseCheckReportVouchers.message
                        window.localStorage.setItem('hashExportVouchers', JSON.stringify(hashObj));
                    }

                }
                //Si esta pendiente todavía, no hago nada. El intervalo seguirá corriendo.
            }
        }

        //export Offices
        if (prevProps.officesReducer.responseCheckReportOffices !== this.props.officesReducer.responseCheckReportOffices) {
            // si no hubo error en respuesta.
            if (!this.props.officesReducer.errorCheckReportOffices) {
                //verifica el estado.
                if (this.props.officesReducer.responseCheckReportOffices.status !== "Pending") {
                    // detiene las peticiones 
                    clearInterval(this.intervalExportOffices)
                    // actualiza item en localStorage. 
                    if (window.localStorage.getItem('hashExportOffices')) {
                        let hashObj = JSON.parse(window.localStorage.getItem('hashExportOffices'))
                        hashObj.status = this.props.officesReducer.responseCheckReportOffices.status
                        hashObj.url = this.props.officesReducer.responseCheckReportOffices.message
                        window.localStorage.setItem('hashExportOffices', JSON.stringify(hashObj));
                    }

                }
                //Si esta pendiente todavía, no hago nada. El intervalo seguirá corriendo.
            }
        }
    }

    checkReportExportVouchers = (hash) => {
        this.props.checkExportVouchersList(this.headersSetCookie, hash)
    }
    checkReportExportOffices = (hash) => {
        this.props.checkExportOfficesList(this.headersSetCookie, hash)
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    // Ejecutará la acción de aquella notificacion seleccionada y luego la borrará de la lista.
    handleNotification = (id) => {
        let objNotification = this.state.notifications.filter(noti => noti.id = id)[0];
        objNotification.action()

        let newNotifications = this.state.notifications.filter(noti => noti !== objNotification);
        this.setState({ notifications: newNotifications })
    }


    // Funciones que se dispararán segun la notificacion clickeada

    // accion report voucher
    actionExportVoucher = () => {
        if (window.localStorage.getItem('hashExportVouchers')) {
            var hashObj = JSON.parse(window.localStorage.getItem('hashExportVouchers'))
            if (hashObj.status == "OK") {
                this.props.updateExportVouchersList(this.headersSetCookie, hashObj.hash)    //actualizo estado de report
                saveAs(hashObj.url, "filter_voucher.xlsx")                                  //guardo archivo.
            }
            window.localStorage.removeItem('hashExportVouchers');                           // borro data en localStorage
        }
    }

    // accion report offices
    actionExportOffice = () => {
        if (window.localStorage.getItem('hashExportOffices')) {
            var hashObj = JSON.parse(window.localStorage.getItem('hashExportOffices'))
            if (hashObj.status == "OK") {
                this.props.updateExportOfficesList(this.headersSetCookie, hashObj.hash)    //actualizo estado de report
                saveAs(hashObj.url, "filter_offices.xlsx")                                  //guardo archivo.
            }
            window.localStorage.removeItem('hashExportOffices');                           // borro data en localStorage
        }
    }


    checkNotifications = () => {
        // Verifica notificaciones de export report voucher
        if (window.localStorage.getItem('hashExportVouchers')) {
            var hashVouchers = JSON.parse(window.localStorage.getItem('hashExportVouchers'))
            if (this.state.notifications.filter(noti => noti.name = "EXPORT_VOUCHER").length === 0) {
                if (hashVouchers.status !== 'Pending') {
                    let message = ""
                    if (hashVouchers.status == "OK") {
                        message = "Listado de vouchers disponible para descargar"
                    } else {
                        message = "Error al descargar listado de vouchers."
                    }
                    var objVouchers = {
                        "id": 1,
                        "name": "EXPORT_VOUCHER",
                        "message": message,
                        "action": this.actionExportVoucher,
                    }
                    var objNotifications = this.state.notifications
                    objNotifications.push(objVouchers)
                    this.setState({
                        hasNotifications: true,
                        notifications: objNotifications
                    })
                }
            } else {
                // console.log("Ya existe la notificacion EXPORT_VOUCHER.")
            }
        }

        if (window.localStorage.getItem('hashExportOffices')) {
            var hashOffices = JSON.parse(window.localStorage.getItem('hashExportOffices'))
            if (this.state.notifications.filter(noti => noti.name = "EXPORT_VOUCHER").length === 0) {
                if (hashOffices.status !== 'Pending') {
                    let message = ""
                    if (hashOffices.status == "OK") {
                        message = "Listado de oficinas disponible para descargar."
                    } else {
                        message = "Error al descargar listado de oficinas."
                    }
                    var objOffices = {
                        "id": 1,
                        "name": "EXPORT_VOUCHER",
                        "message": message,
                        "action": this.actionExportOffice,
                    }
                    var objNotifications = this.state.notifications
                    objNotifications.push(objOffices)
                    this.setState({
                        hasNotifications: true,
                        notifications: objNotifications
                    })
                }
            } else {
                // console.log("Ya existe la notificacion EXPORT_VOUCHER.")
            }
        }

        //Verifica otras notificaciones...
    }


    render() {

        function handleListKeyDown(event) {
            if (event.key === 'Tab') {
                event.preventDefault();
                this.setState({ anchorEl: null });
            }
        }

        return (
            <>
                <IconButton className="menuNotificationButton" aria-label="nuevas notificaciones" color="inherit" onClick={this.handleClick} >
                    <Badge badgeContent={this.state.notifications.length} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>



                <Popper
                    className="menuNotificationPopper"
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList autoFocusItem={Boolean(this.state.anchorEl)} id="menu-list-grow" onKeyDown={handleListKeyDown}>

                                        {this.state.notifications.map((notification, index) => {
                                            return (
                                                <MenuItem key={index} onClick={() => this.handleNotification(notification.id)}>{notification.message}</MenuItem>
                                            )
                                        })
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>


            </>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers;
};

const mapDispachToProps = dispatch => {
    return {
        updateExportVouchersList: (access, hash) => dispatch({ type: "UPDATE_EXPORT_VOUCHERS", access, hash }),
        checkExportVouchersList: (access, hash) => dispatch({ type: "CHECK_EXPORT_VOUCHERS", access, hash }),
        updateExportOfficesList: (access, hash) => dispatch({ type: "UPDATE_EXPORT_OFFICES", access, hash }),
        checkExportOfficesList: (access, hash) => dispatch({ type: "CHECK_EXPORT_OFFICES", access, hash })
    };
};
export default connect(mapStateToProps, mapDispachToProps)(MenuNotifications);