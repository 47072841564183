
function checkResponse(response,method){
    if (response.status > 499){ // contempla errores 300, 400, y 500.
        return {
            "detail":"Error", // TO-DO: Verificar si ya existe clave detail de error, insertala aqui si luego se usará.
            "status": response.status
        }
    }else{
        if (method === "delete") return response 
        else return response.json()
    }
};

export default function apiCall(method, url,options = null) {
    
    if(options === null){
        let body = null;
        return fetch(url, {
            method,
            body,
        })
        .then(response =>(method === "delete") ? response : response.json())
    }else{
        let body = options.body;
        let headers = options.headers;
         /*const headers = {
             'Content-Type': "application/json",
             'Authorization': `Bearer fewihfiwehfio`
         };*/

        return fetch(url,{
            method,
            body,
            headers,
            
        }).then(response => (method === "delete") ? response : response.json())
    }
  
}