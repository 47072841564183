import React, { Component } from 'react';
import Theme from "../../assets/Theme";
import style from "./../../config/style.json";
import config from './../../config/config.json';
import clsx from 'clsx';
import { Toolbar, AppBar, Typography, IconButton, Container, Icon, Avatar, Collapse } from '@material-ui/core';
import SidebarNavigation from './partials/SidebarNavigation';
import MenuDropdown from './partials/MenuDropdown';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import LogoBy from '../../assets/images/logo-by-white.png';
import { connect } from "react-redux";
import jwt_decode from 'jwt-decode';
import { Redirect } from 'react-router-dom';

/* Menu de notificaciones */
import MenuNotifications from './partials/MenuNotifications';
/* ------ */

class MainAdmin extends Component {
	constructor(props) {
		super(props);

		this.user = window.localStorage.getItem('user');
		this.state = {
			open: true,
			userData: {},
			statusLogin: false,
			cards: config.cards,
			responseForgot: '',
			openAlert: true,
			showSidebar: true,
			errorInvalidToken: false,
		};
		this.headersSetCookie = window.localStorage.getItem('headersSetCookie')
	}

	/**
	 * Decodifica el token de login para obtener el ID del usuario y hacer la petición de sus datos apartir de éste.
 	*/
	componentDidMount = () => {

		let validaLogin = window.localStorage.getItem('headersSetCookie')

		if (validaLogin !== "undefined"){
			console.log('entro aca')
			let userDataJWT =  jwt_decode(this.headersSetCookie);
			this.props.getUser(this.headersSetCookie, userDataJWT.user_id, 'LOGIN')
		}else{
			this.setState({ errorInvalidToken: true })
		}
	}

	/**
	 * Recibe los cambios de valores del store correspondiente al usuario y lo almacena en el local storage.
 	*/
	componentDidUpdate(prevProps,prevState,snapshot){
		//console.log("paso por didUpdate mainAdmin")
		//login
		if (prevProps.responseUserLogin != this.props.responseUserLogin){
			if (this.props.responseUserLogin.id != undefined) {
					this.setState({ userData: this.props.responseUserLogin })
			}

			if(this.props.responseError.invalidToken != null){
				console.log(prevProps)
				this.setState({ errorInvalidToken: true })
			}
		}
	}

	callbackCard = (indiceCard) => {
		this.state.cards.map((value, i) => {
			if (i === indiceCard) {
				value.expanded = true;
				value.visibility = true;
			} else {
				value.expanded = false;
				value.visibility = false;
			}
		})

		this.setState({ cards: this.state.cards });
	}

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};
	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	callbackForgot = (res) => {
		console.log("main admin", res)
		this.setState({ responseForgot: res });
	};

	collapseAlert = () => {
		this.setState({ openAlert: false })
	}

	handleError = () => {
        return (
            <Redirect to={{
                pathname: `/login`, state: {
                    accessTokenError: true
                }
            }} />
        )
    }

	
	render() {

		//console.log("MAIN ADMIN", this.props.children)
		console.log("MAIN ADMIN", this.state)

		return (

			this.state.errorInvalidToken == true ? 
				this.handleError() :

			<Theme>

				<div className={`wrapper MainLayout_wrapper`} styles={style}>

					{/* START HEADER */}
					<AppBar position="absolute" className={clsx('appBar', this.state.open && 'appBarShift')}>
						<Toolbar className="toolbar_Appbar">
							<div>
								<Typography component="h1" variant="h3" color="inherit" noWrap className="title_Dashboard">BENJAMIN</Typography>
							</div>
							<img className={clsx('toolbarLogo', this.state.open && 'toolbarLogoShow')} src={LogoBy} alt='SafeTravelAssistance' />
							<IconButton edge="start" color="inherit" aria-label="open drawer" onClick={this.handleDrawerOpen}
								className={clsx('menuButton', this.state.open && 'menuButtonHidden')}>
								<Icon>menu</Icon>
							</IconButton>


							{Object.keys(this.state.userData).length > 0 ? (

								<>
									<MenuNotifications />
										
										
									<MenuDropdown
										user={this.state.userData.email}
										buttonStartIcon={<Avatar className="MenuHeader_Avatar">
											<Icon>person</Icon>
										</Avatar>}
										buttonEndIcon={<Icon>expand_more</Icon>}
										buttonText={<Typography component="span" color="inherit" noWrap >{this.state.userData.email}</Typography>}
										callbackForgot={this.callbackForgot}
									/>
								</>
							) : ""}
						</Toolbar>
					</AppBar>
					{/* END HEADER */}

					{/* START SIDEBAR */}
					{this.state.showSidebar && <SidebarNavigation />}
					{/* END SIDEBAR */}

					<main className="main_admin">
						<div className="app_bar_spacer" />

						{this.state.responseForgot !== "" && (
							<Collapse in={this.state.openAlert}>
								<Container maxWidth={false} className="container pb-0">
									<Alert severity="info" variant="filled"
										action={
											<IconButton
												aria-label="close"
												color="inherit"
												size="small"
												onClick={() => {
													this.collapseAlert(false);
												}}
											>
												<CloseIcon fontSize="inherit" />
											</IconButton>
										}
									>
										{this.state.responseForgot}
									</Alert>
								</Container>
							</Collapse>
						)}

						<Container maxWidth={false} className="container mainLayout_container">
							{this.props.children}
						</Container>

					</main>
				</div>
			</Theme>
		)
	}
}

const mapStateToProps = reducers => {

	return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
	return {
		getUser: (access, query, queryType) => dispatch({ type: "GET_USER", access, query, queryType }),
		
		checkExportVouchersList: (access, hash) => dispatch({ type: "CHECK_EXPORT_VOUCHERS", access, hash })
	};
};
export default connect(mapStateToProps, mapDispachToProps)(MainAdmin);