const initialState = {
    responseMunicipiosList: [],
    responseMunicipio: [],
    errorMunicipio: false,
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "MUNICIPIOS_RESPONSE_LIST":
            newState.responseMunicipiosList = action.value;
            break;
        case "MUNICIPIO_RESPONSE":
            newState.responseMunicipio = action.value;
            break;
        default: 
            return newState;

    }
    
    if (action.value.detail !== undefined) newState.errorMunicipio = true;
    else newState.errorMunicipio = false;

    return newState;
};

export default reducer;