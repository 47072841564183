import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* municipio(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/karing/get_municipios/' : config.credentials.url + '/karing/get_municipios/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
       // console.log('response')
        yield put({ type: "MUNICIPIO_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "MUNICIPIO_RESPONSE", value: error })
    }
}

function* municipios(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/karing/get_municipios/?offset=0&limit='+actions.limit : config.credentials.url + '/karing/get_municipios/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "MUNICIPIOS_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "MUNICIPIOS_RESPONSE_LIST", value: error })
    }
}


export function* getMunicipio() {
    yield takeEvery("GET_MUNICIPIO", municipio)
}
export function* getMunicipiosList() {
    yield takeEvery("GET_MUNICIPIOS", municipios)
}


