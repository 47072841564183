import React, { Component } from 'react';
import { Grid, Typography, Button, Link, Container } from '@material-ui/core';
import Email from '../../common/fields/Email';
import { forgot } from '../../../services/LoginService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { connect } from "react-redux";

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email : null,
            responseStatus: null,

            errors: {
                status: {
                    email: false
                },
                text: {
                    email: ""
                }
            }
        };
    }

    send = async () => {
        let form = new Object();
        let countValid = 0;
        let errors = [];

        if (this.state.email && this.state.email !== null) {
            if (this.state.email.state.defaultValue !== '') {
                countValid++;
                form[this.state.email.props.name] = this.state.email.state.defaultValue;
            }
        }

        if (1 === countValid) {
            this.props.resetPassword(form); 
        }
    }

    componentDidUpdate(prevProps,prevState,snapshot){
		if ((prevProps.responsePasswordReset != this.props.responsePasswordReset) 
			|| (prevProps.responseError != this.props.responseError && this.props.errorPassword)) {

            if(this.props.responsePasswordReset.status!= undefined && this.props.responsePasswordReset.status=='OK'){
				this.setState( { responseStatus :  this.props.responsePasswordReset.status } )
			}else{ //hubo errores
                	this.setState({ responseStatus: 'FAILED' })                
            }
		}
	}

    render() {
        return (

                this.state.responseStatus!=null && this.state.responseStatus == 'OK' ?
                (
                    <Container maxWidth="md" className="mx-auto">
                        <div className="login_container">
                            <div className="form-header">
                                <Typography component="h1" variant="h4" className="title">Verifique su email</Typography>
                            </div>
                            <div className="form-content">
                                <Alert severity="info" className="mb-2">
                                    {/*  <AlertTitle>Error</AlertTitle> */}
                                    Verifique su email para seguir con el proceso de cambio de contraseña.
                                </Alert>
                            </div>
                        </div>
                    </Container>
                ) : (
                    <Container maxWidth="md" className="mx-auto">
                        <div className="login_container">
                            <div className="form-header">
                                <Typography component="h1" variant="h4" className="title">Olvidó su contraseña?</Typography>
                                <Typography component="h2" variant="h5" className="subtitle">Ingresa tu email para recuperar tu cuenta.</Typography>
                            </div>
                            <form className="form" noValidate>
                                <Grid container spacing={1}>
                                    <Grid item md={6}>
                                        <Email
                                            ref={element => { this.state.email = element }}
                                            id="email"
                                            label="Email"
                                            name="email"
                                            placeholder="Email here..."
                                            defaultValue={''}
                                            disabled={false}
                                            error={this.state.errors.status.email}
                                            errorText={this.state.errors.text.email}
                                            classNameCustom={'textField'}
                                            autoComplete="email"
                                            required={true}
                                        />
                                    </Grid>
                                </Grid>
                                {this.state.responseStatus=='FAILED' && (
                                    <>
                                        <Alert severity="error">
                                            <AlertTitle>Error</AlertTitle>
                                                No hemos encontrado ninguna cuenta asociada a este email o no posee permisos para cambiar de contraseña.
                                        </Alert>
                                        <br/>
                                    </>
                                )}
                                <div /* className="text-right" */>

                                    <Button
                                        component={Link} href={'/login'}
                                        variant="text"
                                        className="mr-1"
                                        size="large"
                                    >Ingresar con otra cuenta</Button>

                                    <Button
                                        onClick={() => this.send()}
                                        variant="contained"
                                        color="primary"
                                        className="submit"
                                        size="large"
                                    >
                                        Cambiar contraseña
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Container>
                )
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
	return {
		resetPassword: (data) => dispatch({ type: "RESET_PASSWORD", data }),
	};
};

export default connect(mapStateToProps, mapDispachToProps)(Forgot)