import React, { Component, Fragment } from "react";
import {MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// pick a date util library
import enLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import {formatDateBar} from '../../../services/formatDate';

export default class ValidationDateTodayRefund extends Component {
  	constructor(props){
		super(props);
		
		let today = new Date();
		//let minDate  = new Date(today.getFullYear() - 99,today.getMonth(),today.getDate(),0,0,0);
		let maxDate  = new Date(); 
		//let minDate  = formatDateBar(new Date());

		this.state={
			locale:"es",
			selectedDate:(props.defaultValue !== null) ? props.defaultValue : null,
			//minDate:minDate,
			maxDate:maxDate,
			localeMap:{
				es:enLocale
			},
			errorText:'',
			error:props.error
		}
	  }
    
	  componentDidUpdate(prevProps,prevState) {
		if (prevProps != this.props){
			if(this.props.initial !== undefined && this.props.initial){
				this.setState({ error: this.props.error, errorText:'', selectedDate:this.props.defaultValue});
			}else{
				this.setState({ error: this.props.error});
			}
		}
    } 
	
	

   	handleChange = date => {
		
		let response = formatDateBar(date);
		
		this.setState({selectedDate:response})
		if(!response || date === null){
			
			this.setState({selectedDate:null,errorText: 'Ingrese una fecha con formato DD/MM/AAAA',error:true});	
		}else{
			if(response > this.state.maxDate || response < this.state.minDate )this.setState({error:true, selectedDate:null, errorText:"Fecha fuera de rango"});
				else
					this.setState({errorText: '',error:false});
		
		}  
   };
   
   resetDate = (newDate) => {
	this.setState({selectedDate: newDate})
   }

  render(){
	  //console.log("La fecha de DateValid es ",this.state.selectedDate);
    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.state.localeMap[this.state.locale]}>
  
        <KeyboardDatePicker
			  autoOk
		
          	variant="inline"
          	inputVariant="outlined"
          	error={this.state.error}
          	label={this.props.label}
			  name={this.props.name}
			helperText={this.state.errorText}
          	format="dd/MM/yyyy"
          	invalidDateMessage={this.state.errorText}
          	value={this.state.selectedDate}
			InputAdornmentProps={{ position: "start" }}
			onChange={(date) =>{this.handleChange(date)}}
			maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
			minDateMessage="La fecha no debe ser anterior a la fecha mínima"
			minDate={this.state.minDate}
			maxDate={this.state.maxDate}
		    disabled={this.props.disabled}
		    placeholder={this.props.placeholder}
			id={this.props.id}
			className={`datepicker ${this.state.initial ? 'initialInput' : ''} ${this.props.classNameCustom}`}
			autoComplete="off"
        />
  
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
  
}


