const initialState = {
    responseUsersList: [],
    responseUser: [],
    responsePermissions:[],
    responseError : []
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "USERS_RESPONSE_LIST":
            console.log("USERS_RESPONSE_LIST")
            if (action.value.detail !== undefined){ //si hubo error
                newState.responseUsersList = [];
                newState.responseError = action.value;
            }else{ //si NO hubo error
                newState.responseUsersList = action.value;
                newState.responseError = [];
            }
            newState.responseUser = [];
            break;

        case "USER_RESPONSE":
            if (action.value.detail !== undefined)  { //si hubo error
               newState.responseUser = [];    
               newState.responseError = action.value;
            }else{ //si NO hubo error
                newState.responseUser = action.value;
                newState.responseError = [];
            }
            break;
        case "PERMISSIONS_RESPONSE":
                // console.log("USER_RESPONSE")
                 newState.responsePermissions = action.value;
        break;
        default: 
            return newState;
    }

    return newState;
};

export default reducer;