const initialState = {
    init: false,
    headersSetCookie: '',
    responseCountries: [],
    statusCountries: '',
    responseCoverages: [],
    statusCovarages: '',
    responseBussinesUnit: [],
    responseSupplier: [],
    responseCategory: [],
    responseZoneList: [],
    countryId: '',
    responseOffice: [],
    infoStep: [],
    hashProduct: '',
    hashFileParticular: '',
    hashFileGeneral: '',
    responseZones: [],
    status: '',
    responseWs: '',
    responseProducts: [],
    view: 'create',
    responseDeleteProduct: null,
    responseRateDetail: {},
    responseOfficesRate: null,
    errorOfficesRate: false,
    image: null,
    errorProduct: false,
    errorReintegros: false,
    responseRefundData: [],
    errorDataReintegro: false,
    responsePrintRefundData: [],
    errorPrintRefundData: false,
    errorCreateRefund: false,
    finalStep: false,
    completedForm: false,
    responseCreate: null,
    responseUpdateFilesReintegro: {},
    errorUpdateFilesReintegro: false,
    responseUpdatePendingReintegro: {},
    errorUpdatePendingReintegro: false
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "COUNTRIES_RESPONSE":
            if (action.statusCountries !== undefined) {
                newState.statusCountries = action.statusCountries
            }
            if (action.value.detail !== undefined)
                newState.responseCountries = []
            else
                newState.responseCountries = action.value
            break;
        case "BUSSINESUNIT_RESPONSE":
            //newState.nameCountries = [...newState.nameCountries, { 'bussinesUnit': action.value.results, 'tab': newState.nameCountries.length, 'id': action.countryId, 'name': action.countryName }]
            newState.responseBussinesUnit = action.value
            break;
        case "SUPPLIER_RESPONSE":
            if (action.value.detail !== undefined)
                newState.responseBussinesUnit = []
            else
                newState.responseBussinesUnit = action.value.results
            newState.responseSupplier = action.value.results

            break;
        case "CATEGORY_RESPONSE":
            if (action.value.detail !== undefined)
                newState.responseCategory = []
            else
                newState.responseCategory = action.value.results

            newState.responseCategory = action.value.results

            break;
        case "COVERAGES_RESPONSE":

            if (action.statusCovarages !== undefined) {
                newState.statusCovarages = action.statusCovarages
            }
            if (action.value.detail !== undefined)
                newState.responseCoverages = []
            else
                newState.responseCoverages = action.value
            break;
        case "ZONE_RESPONSE":

            if (action.value.detail !== undefined)
                newState.responseZones = []
            else
                newState.responseZones = action.value
            break;
        case "ZONELIST_RESPONSE":

            if (action.value.detail !== undefined)
                newState.responseZoneList = []
            else
                newState.responseZoneList = action.value
            break;
        case "ZONES_RESPONSE":
            if (action.value.detail !== undefined)
                newState.responseZones = []
            else

                newState.responseZones = action.value.results
            break;
        case "OFFICES_RESPONSE":
            if (action.value.detail !== undefined)
                newState.responseOffice = []
            else
                newState.responseOffice = action.value
            break;
        case "INFO_STEP":
            newState.infoStep = [...newState.infoStep, { "step": action.step, "info": action.infoStep }];
            break;
        case "INFO_STEP_REFUND":
            if (action.step === 2 && action.infoStep.refund_payment && action.infoStep.refund_payment.status === true && action.createRefund === true) {
                newState.infoStep = [...newState.infoStep, { "step": action.step, "info": action.infoStep }];
                newState.finalStep = true;
            } else {
                newState.infoStep = [...newState.infoStep, { "step": action.step, "info": action.infoStep }];
                newState.finalStep = false;
            }
            break;
        case 'UPDATE_FILES_RESPONSE':
            if (action.value.status !== undefined) {
                if (action.value.status === 'ok') {
                    newState.responseUpdateFilesReintegro = action.value.detail;
                    newState.errorUpdateFilesReintegro = false;
                } else if (action.value.status === 'ko') {
                    newState.responseUpdateFilesReintegro = {};
                    newState.errorUpdateFilesReintegro = true;
                }
                else if (action.value.status === 'error') {
                    newState.responseUpdateFilesReintegro = {};
                    newState.errorUpdateFilesReintegro = true;
                }
            } else {
                newState.responseUpdateFilesReintegro = {};
                newState.errorUpdateFilesReintegro = true;
            }
            break;
        case 'UPDATE_PENDING_RESPONSE':
            if ((action.value.detail !== undefined) || (action.value.status === 404)) {
                newState.responseUpdatePendingReintegro = {};
                newState.errorUpdatePendingReintegro = true;
            } else {
                newState.responseUpdatePendingReintegro = action.value.detail;
                newState.errorUpdatePendingReintegro = false;
            }
            break;

        case "REINTEGROS_LIST_RESPONSE":
            newState.responseReintegrosList = action.value
            if ((action.value.detail !== undefined) || (action.value.status == 404)) {
                newState.errorReintegros = true;
            } else {
                newState.errorReintegros = false;
            }
            break;
        case "REINTEGRO_RESPONSE":
            newState.responseReint = action.value;
            //Cuando la response da error de edición en un campo no va a tener un atributo "detail", entonces se lo inserta manualmente.
            if (action.value.view == 'edit' || action.value.view == 'create') {
                if (action.value.id == undefined) {
                    newState.responseReint.detail = {};
                }
            }
            if ((action.value.detail !== undefined) || (action.value.status == 404)) {
                newState.errorReintegros = true;
            } else {
                newState.errorReintegros = false;
            }
            break;

        case 'PRINT_REFUND_RESPONSE':
            if (action.value.status !== undefined) {

                if (action.value.status === 'ok') {
                    newState.responsePrintRefundData = action.value;
                    newState.errorPrintRefundData = false;
                } else if (action.value.status === 'error') {
                    newState.responsePrintRefundData = action.value;
                    newState.errorPrintRefundData = true;
                }
            } else {

                newState.responsePrintRefundData = [];
                newState.errorPrintRefundData = true;
            }
            break;

        case 'REINTEGRO_CREATE_RESPONSE':
            if (action !== undefined) {
                newState.completedForm = true;
                if (action.value.status !== undefined && action.value.status === 'Error') {
                    newState.errorCreateRefund = true;
                    newState.responseCreate = null;
                } else {
                    newState.errorCreateRefund = false;
                    newState.responseCreate = action.value;
                }
            }
            break;

        case "REFUND_DATA_RESPONSE":
            if (action.value.detail !== undefined) {
                newState.responseRefundData = [];
            } else {
                newState.responseRefundData = action.value
            }
            if ((action.value.detail !== undefined) || (action.value.status == 404)) {
                newState.errorDataReintegro = true;
            } else {
                newState.errorDataReintegro = false;
            }
            break;
        case "PRODUCT_RESPONSE":
            if (action.response.hash !== undefined) {
                newState.status = "SUCCESS";
                newState.hashProduct = action.response.hash;
            } else {

                newState.status = "ERROR";
                newState.responseWs = action.response;
            }
            break;
        case "FILE_RESPONSE":

            newState.status = '';
            if (action.tipoTarifa === 'general')
                newState.hashFileGeneral = action.value.hash;
            else
                newState.hashFileParticular = action.value.hash;

            break;

        case "PRODUCT_RESPONSE_FINAL":

            if (action.productResponse.status === "OK") {
                newState.status = "SUCCESS";

            } else {
                newState.status = "ERROR";
                newState.responseWs = action.createProduct;
            }

            break;
        case "PRODUCTS_RESPONSE":
            newState.responseProducts = action.response
            newState.view = action.view

            if (action.response.detail !== undefined) newState.errorProduct = true;
            else newState.errorProduct = false

            if (action.responseDelete !== undefined) {
                if (action.responseDelete.status === 204) {
                    newState.responseDeleteProduct = { "status": true, "id": action.id }
                } else
                    newState.responseDeleteProduct = { "status": false, "id": action.id };
            }
            break;
        case "PRODUCTS_RESPONSE_REDUCIDA":
            newState.responseProducts = action.response
            newState.view = action.view

            if (action.response.detail !== undefined) newState.errorProduct = true;
            else newState.errorProduct = false

            if (action.responseDelete !== undefined) {
                if (action.responseDelete.status === 204) {
                    newState.responseDeleteProduct = { "status": true, "id": action.id }
                } else
                    newState.responseDeleteProduct = { "status": false, "id": action.id };
            }
            break;
        case "PRODUCT_RESPONSE_EDIT":

            if (action.response.status === "OK") {
                newState.status = "SUCCESS";
            } else {
                newState.status = "ERROR";
                newState.responseWs = action.response;
            }

            break;
        case "PRODUCT_RESPONSE_REMOVE":

            break;
        case "RATE_DETAIL_RESPONSE":

            newState.responseRateDetail = (action.value.detail !== undefined) ? {} : action.value;
            newState.status = '';
            break;

        case "EDIT_OFFICES_RATE_RESPONSE":

            newState.responseOfficesRate = action.value;
            if (action.value.detail !== undefined) {
                newState.errorOfficesRate = true;
            }
            break;

        case "IMAGE_RESPONSE":
            newState.image = (action.value.id !== undefined) ? action.value : false;
            break;
        default:
            return newState;

    }

    return newState;
};

export default reducer;