const initialState = {
    responseBusinessUnitsList: [],
    responseBusinessUnit: []
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "BUSINESS_UNITS_RESPONSE_LIST":
            
            if (action.value.detail !== undefined)
                newState.responseBusinessUnitsList = []
            else
                newState.responseBusinessUnitsList = action.value;
            
            newState.responseBusinessUnit = []
            break;
        case "BUSINESS_UNIT_RESPONSE":
      
            newState.responseBusinessUnit = action.value;
      
            break;
            
        default: 
            return newState;
    }

    return newState;
};

export default reducer;