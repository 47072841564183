const initialState = {
    responseGroupsList: [],
    responseGroup: []
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "GROUPS_RESPONSE_LIST":
            console.log("GROUPS_RESPONSE_LIST")
            if (action.value.detail !== undefined)
                newState.responseGroupsList = []
            else
                newState.responseGroupsList = action.value;
            
            newState.responseGroup = []
            break;
        case "GROUP_RESPONSE":
           // console.log("GROUP_RESPONSE")
            newState.responseGroup = action.value;
            break;
        default: 
            return newState;
    }

    return newState;
};

export default reducer;