const initialState = {
    responsePassengersList: [],
    responsePassenger: [],
    errorPassenger: false
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "PASSENGERS_RESPONSE_LIST":
            newState.responsePassengersList = action.value;
            break;
        case "PASSENGER_RESPONSE":
            newState.responsePassenger = action.value;
            break;
            
        default: 
            return newState;
    }

    if (action.value.detail !== undefined){
        newState.errorPassenger= true
    }else{
        newState.errorPassenger= false
    }


    return newState;
};

export default reducer;