import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* promotion(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/promotion/' : config.credentials.url + '/promotion/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "PROMOTION_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PROMOTION_RESPONSE", value: error })
    }
}

function* promotions(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/promotion/?offset=0&limit='+actions.limit : config.credentials.url + '/promotion/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
   
        yield put({ type: "PROMOTIONS_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "PROMOTIONS_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const promotionResponse = yield call(apiCall, 'POST',config.credentials.url+'/promotion/', requestOptions);
    
        yield put({ type: "PROMOTION_RESPONSE", value: promotionResponse})
    
    } catch (error) {
        yield put({ type: "PROMOTION_RESPONSE", value: error })
    }
}

function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/promotion/' + actions.data+ '/', requestOptions)


        yield put({ type: "PROMOTION_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "PROMOTION_RESPONSE", value: error })
    }
}

function* edit(actions) {
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const promotionResponse = yield call(apiCall, 'put', config.credentials.url+ '/promotion/' + actions.id+ '/', requestOptions)

        yield put({ type: "PROMOTION_RESPONSE", value: promotionResponse})
 
    } catch (error) {
        yield put({ type: "PROMOTION_RESPONSE", value: error })
    }
}

function* addOffices(actions){
    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const bodyRequest = {
            "promotion_product": actions.idPromotionProduct,
            "office": actions.offices
        }
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(bodyRequest)
        };

        if(actions.offices.length != 0){
            let url = (actions.query === null || actions.query === undefined) ? config.credentials.url + '/promotion_product_office/' : config.credentials.url + '/promotion_product_office/' + actions.query
            const promotionProductOfficesResponse = yield call(apiCall, 'post', url, requestOptions)
            yield put({ type: "PROMOTION_PRODUCT_OFFICES_RESPONSE", value: promotionProductOfficesResponse, final:actions.final})
        }else{
            const promotionProductOfficesResponse = false;
            yield put({ type: "PROMOTION_PRODUCT_OFFICES_RESPONSE", value: promotionProductOfficesResponse, final:actions.final})
        }

    } catch (error) {
        yield put({ type: "PROMOTION_PRODUCT_OFFICES_RESPONSE", value: error })
    }
}

function* editOffices(actions){
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const bodyRequest = {
            "promotion_product": actions.idPromotionProduct,
            "office": actions.offices
        }
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(bodyRequest)
        };
        
    
        const promotionProductOfficesResponse = yield call(apiCall, 'put', config.credentials.url+ '/promotion_product_office/' + actions.idPromotionProductOffices+ '/', requestOptions)

        yield put({ type: "PROMOTION_PRODUCT_OFFICES_RESPONSE", value: promotionProductOfficesResponse, final:actions.final})
 
    } catch (error) {
        yield put({ type: "PROMOTION_PRODUCT_OFFICES_RESPONSE", value: error })
    }
}

function* product(actions){

    for(let i=0; i < actions.product.length; i++){
        try {
                const headers = {
                    'Content-Type': "application/json",
                    'Authorization': `Bearer ${actions.access}`
                };
                const bodyRequest = {
                    "promotion": actions.idPromotion,
                    "product": actions.product[i]
                }
                const requestOptions = {
                    headers: headers,
                    body: JSON.stringify(bodyRequest)
                };
                
                //acá tengo que hacer un for llamando a cada promotion por cada prod

                let url = (actions.query === null || actions.query === undefined) ? config.credentials.url + '/promotion_product/' : config.credentials.url + '/promotion_product/' + actions.query

                const promotionProductResponse = yield call(apiCall, 'post', url, requestOptions);
            
                if(promotionProductResponse.id !== undefined){
                    actions.idPromotionProduct = promotionProductResponse.id;
                    yield addOffices(actions);
                }else{
                    yield put({ type: "PROMOTION_PRODUCT_RESPONSE", value: promotionProductResponse})
                }
            
            } catch (error) {
                yield put({ type: "PROMOTION_PRODUCT_RESPONSE", value: error })
        }
    }
}

function* deleteProduct(actions){ // Endpoint para borrar productos asociados a promociones
    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 
        const response = yield call(apiCall, 'delete', config.credentials.url+ '/promotion_product/' + actions.data+ '/', requestOptions)
        yield put({ type: "DELETE_PRODUCT_PROMOTION_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "DELETE_PRODUCT_PROMOTION_RESPONSE", value: error })
    }
}

function* deletePromotionProductOffice(actions){ // Endpoint para borrar productos asociados a promociones y a oficinas via FK
    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 
        const response = yield call(apiCall, 'delete', config.credentials.url+ '/promotion_product_office/' + actions.data+ '/', requestOptions)
        yield put({ type: "DELETE_PRODUCT_PROMOTION_OFFICE_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "DELETE_PRODUCT_PROMOTION_OFFICE_RESPONSE", value: error })
    }
}


function* productOffices(actions){
    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
      
        const requestOptions = {
            headers: headers
        };
        let url = (actions.query === null || actions.query === undefined) ? config.credentials.url + '/promotion_product_office/' : config.credentials.url + '/promotion_product_office/' + actions.query
        
        const promotionProductOfficesResponse = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "PRODUCT_OFFICES_RESPONSE", value: promotionProductOfficesResponse})
 
    } catch (error) {
        yield put({ type: "PRODUCT_OFFICES_RESPONSE", value: error })
    }
}

export function* getPromotion() {
    yield takeEvery("GET_PROMOTION", promotion)
}
export function* getPromotionsList() {
    yield takeEvery("GET_PROMOTIONS", promotions)
}

export function* addPromotion() {
    yield takeEvery("ADD_PROMOTION", add)
}

export function* removePromotion() {
    yield takeEvery("REMOVE_PROMOTION", remove)
}

export function* editPromotion() {
    yield takeEvery("EDIT_PROMOTION", edit)
}

export function* addProductOffices(){
    yield takeEvery("ADD_PRODUCT_OFFICES", product)
}

export function* deleteProductOffices(){
    yield takeEvery("DELETE_PRODUCT_OFFICES", deleteProduct)
}

export function* getProductOffices(){
    yield takeEvery("GET_PRODUCT_OFFICES", productOffices)
}

export function* editProductOffices(){
    yield takeEvery("EDIT_PRODUCT_OFFICES", editOffices)
}

export function* deletePromotionProductOffices(){
    yield takeEvery("DELETE_PRODUCT_PROMOTION_OFFICES", deletePromotionProductOffice)
}
