import React, { Component, Fragment } from 'react';
import { Grid, Paper, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Button, FormControl, TextField, Icon } from '@material-ui/core';
import Dividers from '../../common/fields/Dividers';
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { Check as CheckIcon, Delete as DeleteIcon } from '@material-ui/icons';

class UploadForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			uploadedFiles: [],
			fileLimit: false,
			allowFiles: 0,
			errors: {
				uploadedFiles: false,
			},
			commentPending: ''
		};
	}

	inputValidate = () => {
		let count = 0;
		let countValid = 0;
		let response = [];
		let status = false;

		count++
		if (this.state.uploadedFiles.length > 0) {
			countValid++;
			response['pending_files'] = this.state.uploadedFiles;
		}

		response['comment_pending'] = this.state.commentPending;

		this.setState({
			errors: {
				'uploadedFiles': (this.state.uploadedFiles.length > 0) ? false : true,
			}
		});

		if (response) {
			if (countValid !== count) {
				status = false;
			} else {
				status = true;
			}
		}

		return { "status": status, "data": response };
	}

	handleSubmit = () => {
		let responseStatus = [];
		let responseData = [];

		let inputValidateInformation = this.inputValidate();
		responseStatus.push(inputValidateInformation.status);
		responseData["refund_pending"] = inputValidateInformation.data

		if (!responseStatus.some(item => item === false)) {
			return { 'continue': true, 'data': responseData, 'createPost': true };
		}
		return { 'continue': false, 'data': [], 'createPost': false };
	}

	componentDidMount() {
		const { responseDataUpdateReintegro } = this.props.loginReducer;
		if (Object.keys(responseDataUpdateReintegro).length !== 0) {
			this.setState({ allowFiles: responseDataUpdateReintegro.remaining_files });
		}
	}

	handleFileEvent = (e) => {
		const files = Array.from(e.target.files);
		const allowedFileTypes = ["jpg", "png", "pdf", "jpeg"];

		const chosenFiles = files.filter(file => {
			const fileExtension = file.name.split('.').pop().toLowerCase();
			return allowedFileTypes.includes(fileExtension);
		});

		if (chosenFiles.length === 0) {
			window.alert(`Las extensiones admitidas son ${allowedFileTypes.join(", ")}`);
			return;
		}

		this.handleUploadFiles(chosenFiles);
	};

	handleUploadFiles = async (files) => {
		const maxCountFiles = this.state.allowFiles;
		const uploadedFiles = [...this.state.uploadedFiles];

		for (const file of files) {
			let compressedFile = file;
			if (file.type !== 'application/pdf') {
				compressedFile = await this.compressImage(file, {
					quality: 0.5,
					type: 'image/jpeg',
				});
			}

			if (!uploadedFiles.some(f => f.name === compressedFile.name)) {
				uploadedFiles.push(compressedFile);

				if (uploadedFiles.length >= maxCountFiles) {
					this.setState({
						fileLimit: true,
					});
					break;
				}
			}
		}

		this.setState({ uploadedFiles, fileLimit: uploadedFiles.length >= maxCountFiles });
	};

	deleteFile = (fileName) => {
		const uploadedFiles = this.state.uploadedFiles.filter(file => file.name !== fileName);
		this.setState({ uploadedFiles, fileLimit: false });
	};

	compressImage = async (file, { quality = 1, type = file.type }) => {
		const imageBitmap = await createImageBitmap(file);

		const canvas = document.createElement('canvas');
		canvas.width = imageBitmap.width;
		canvas.height = imageBitmap.height;
		const ctx = canvas.getContext('2d');
		ctx.drawImage(imageBitmap, 0, 0);

		const blob = await new Promise(resolve => canvas.toBlob(resolve, type, quality));

		return new File([blob], file.name, { type: blob.type });
	};

	handlerCommentPending = (event) => {
		this.setState({ commentPending: event.target.value });
	}

	render() {
		const { uploadedFiles, allowFiles, fileLimit } = this.state;

		const LoaderSkeleton = () => (
			<ContentLoader
				height={"200"}
				width={"auto"}
				viewBox="0 0 100% 100%"
				backgroundColor="#d9d9d9"
				foregroundColor="#ecebeb"
			>
				<rect x="10%" y="0" rx="3" ry="3" width="38%" height="30" />
				<rect x="60%" y="0" rx="3" ry="3" width="38%" height="30" />
				<rect x="10%" y="50" rx="3" ry="3" width="38%" height="30" />
				<rect x="60%" y="50" rx="3" ry="3" width="38%" height="30" />
			</ContentLoader>
		);

		return (
			<Fragment>
				<Dividers />
				<Typography component="h3" variant="h5" style={{ margin: "0 0 20px 25px" }}>
					Carga de documentación
				</Typography>
				<Grid container spacing={5} className="main_admin" justify="center">
					<Grid item xs={11} sm={5}>
						<Typography variant="h6" gutterBottom>
							Documentos
						</Typography>
						<Paper style={{ padding: 16, border: '1px solid rgba(0, 0, 0, 0.2)' }} variant="outlined">
							<List>
								{uploadedFiles.length === 0 ? (
									<ListItem>
										<ListItemText primary="Aún no se ha cargado ningún archivo" />
									</ListItem>
								) : (
									uploadedFiles.map((file, index) => (
										<ListItem key={index} divider>
											<ListItemText primary={file.name} />
											<ListItemSecondaryAction>
												<IconButton style={{ color: 'darkgreen' }} edge="end" aria-label="check">
													<CheckIcon />
												</IconButton>
												<IconButton
													edge="end"
													aria-label="delete"
													onClick={() => this.deleteFile(file.name)}
												>
													<DeleteIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))
								)}
							</List>
							<Typography variant='caption' gutterBottom>
								Archivos permitidos {uploadedFiles.length}/{allowFiles}
							</Typography>
							<div style={{ padding: '10px 0 10px' }}>
								<Button
									variant="outlined"
									color="primary"
									startIcon={<Icon>add</Icon>}
									style={{ fontWeight: 'bold' }}
									component="label"
									disabled={fileLimit}
								>
									Agregar Documentos
									<input
										hidden
										accept=".png, .jpg, .jpeg, application/pdf"
										multiple
										type="file"
										onChange={this.handleFileEvent}
									/>
								</Button>
								{(this.state.errors.uploadedFiles && this.state.uploadedFiles.length === 0) &&
									<>
										<br/>
										<Typography variant="caption" style={{ color: "red" }}>Debe cargar al menos 1 archivo para continuar</Typography>
									</>
								}
							</div>
						</Paper>
					</Grid>
					<Grid item xs={11} sm={5}>
						<Typography variant="h6" gutterBottom>
							Comentarios
						</Typography>
						<FormControl fullWidth>
							<TextField
								id="comment"
								type="text"
								variant="outlined"
								multiline
								rows={2}
								inputProps={{ maxLength: 255 }}
								placeholder='...'
								onChange={this.handlerCommentPending}
								value={this.state.commentPending}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Fragment>
		);
	}
}

const mapStateToProps = (reducers) => reducers.loginReducer;

const mapDispatchToProps = (dispatch) => ({
	getLoginReintegro: (form) => dispatch({ type: "GET_LOGIN_REINTEGRO", form }),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UploadForm);
