import React, { Component, Fragment } from 'react';
import {
	TablePagination, TableBody, Table, TableHead, TableRow, TableCell, Checkbox, TextField, Chip, InputAdornment,
	CardActions, FormControlLabel, Button, Grid, Typography, Card, CardHeader, CardContent, Icon, Link
} from '@material-ui/core';
import { connect } from "react-redux";
import { Alert } from '@material-ui/lab';
import { Redirect } from "react-router-dom";
import Chips from '../../common/fields/Chips';
import Radio from '../../common/fields/RadioButtons';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Divider from '../../common/fields/Dividers';
import ContentLoader from "react-content-loader"

class StepUno extends Component {

	constructor(props) {
		super(props);
		this.state = {
			productsChip: [],
			idPromotion: (this.props.match === undefined) ? this.props.idPromotion : this.props.match.params.id,
			promoProveedor : false,
			mensajeError: false,
		}
		this.productId = []
		this.promotionTypeProveedor = {}
		this.headersSetCookie = window.localStorage.getItem('headersSetCookie')
	}

	callbackChips = (products) => {
		// if (products.length > 1 ) {
			
		// } else {
		// 	this.productId = ''
		// 	let chip = []
		// 	products.map(product => {
		// 		this.productId = product.id;
		// 		chip.push(product)
		// 	})
		// 	this.setState({ productsChip: chip })
		// }
		if(this.state.promoProveedor === true){
			this.productId = []
			let chip = []
			products.map(product => {
				//this.productId = product.id;
				this.productId.push(product.id)
				chip.push(product)
			})
			this.setState({ productsChip: chip })
		}else{
			if (products.length > 1 ) {
				this.setState({ mensajeError: true })
			} else {
				//this.productId = ''
				this.setState({ mensajeError: false })
				this.productId = []
				let chip = []
				products.map(product => {
					//this.productId = product.id;
					this.productId.push(product.id)
					chip.push(product)
				})
				this.setState({ productsChip: chip })
			}
		}
	}

	UNSAFE_componentWillMount() {
		this.props.getPromotion(this.headersSetCookie, this.state.idPromotion)
	}

	UNSAFE_componentWillReceiveProps(next_props) {

		this.promotionTypeProveedor = next_props.promotionsReducer.responsePromotion.promotion_type;
		if(next_props.promotionsReducer.responsePromotion.promotion_type === 'proveedor'){
			this.setState({promoProveedor:true})
		}
	}

	render() {
		const LoaderSkeletonProduct = (props) => {
			return (
				<ContentLoader
					height={"90"}
					width={"auto"}
					viewBox="0 0 100% 100%"
					backgroundColor="#d9d9d9"
					foregroundColor="#ecebeb"
				>
					<rect x="0%" y="10%" rx="4" ry="4" width="100%" height="80" />
				</ContentLoader>
			)
		}
		return (
			<Grid container spacing={1}>
				<Grid item sm={12}>
					<Typography variant="h5">Asignar producto</Typography>
					{/* 	{Object.keys(this.props.promotion.product).length > 0 &&
						this.props.promotion.product.map((prod, i) =>
							<Chip key={i} size="small" label={prod} />)}
 */}
					{Object.keys(this.props.productsReducer.responseProducts).length === 0 
					? <LoaderSkeletonProduct/>
					:
						<Chips
							size="small"
							view={this.props.view}
							placeholder="Ingrese un producto"
							label="Ingrese un producto"
							{...this.props}
							callbackAdd={this.callbackChips}
							chips={this.state.productsChip}
							options={this.props.productsReducer.responseProducts.results}
							title="Producto"
							optionLabel={"long_name"}
							className="chipsSpaceContainer"
						/>
					}
					<Typography  variant="caption" style={{ color: "red" }}>{this.state.mensajeError ? 'No puede ingresar más de un producto en Promociones Venta' : ''}</Typography>
				</Grid>
			</Grid>
		)
	}
}
const FilterAutocomplete = (props) => {

	const getValue = (e) => {

		props.callbackOffice(e.target.value)
	}
	const input_props = {
		type: 'search', startAdornment: (
			<InputAdornment position="start">
				<Icon>search</Icon>
			</InputAdornment>
		)
	}
	return (
		<Fragment>
			<div className="autocomplete_container">
				<TextField type="text"
					onChange={getValue}
					label="Buscar oficinas por su nombre:"
					placeholder="filtrar..."
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={input_props}
				/>
			</div>
		</Fragment>
	)
}
class StepDos extends Component {

	constructor(props) {
		super(props);

		this.state = {
			officesChip: [],
			options: [],
			//optionsAutocomplete: this.props.officesReducer.responseOfficesList.results,
			defaultValueRadio: 'id',
			checked: false,
			optionLabel: 'long_name',
			selected: [],
			officesId: [],
			cards: [],
			rowsPerPage: 2,
			page: 0,
			optionsAutocomplete: [],
			officeAutocomplete:[],
			businessAutocomplete: [],
			tagsAutocomplete: [],
			countriesAutocomplete: [],
			initial: false,
			configClavesFilters: ["short_name", "long_name"],
			filter:'',
			valueAutocomplete:'',
			//auxiliares
			sentBusines: false,
			sentTags: false,
			sentCountries: false,
			getOfficesSent: false,
			bloqueoOficinas: false,
			promoProveedor : false,
			idPromotion: (this.props.match === undefined) ? this.props.idPromotion : this.props.match.params.id,
		}
		this.promotionTypeProveedor = {}
		this.columns = [{ field: 'long_name', headerName: 'Nombre', width: 130 },]
		this.headersSetCookie = window.localStorage.getItem('headersSetCookie')
	}

	callbackRadio = (value) => {
		let optionsAutocomplete = [];
		let optionLabel = '';

		if (value === 'id') {
			optionLabel = 'long_name'
			optionsAutocomplete = this.state.officeAutocomplete
		}

		if (value === 'bussines_unit') {
			optionLabel = 'long_name';
			optionsAutocomplete = this.state.businessAutocomplete
		}

		if (value === 'tags') {
			optionLabel = 'long_name';
			optionsAutocomplete = this.state.tagsAutocomplete
		}

		if (value === 'country') {

			optionLabel = 'long_name';
			optionsAutocomplete = this.state.countriesAutocomplete
		}

		this.setState({ valueAutocomplete:'',defaultValueRadio: value, optionsAutocomplete: optionsAutocomplete, optionLabel: optionLabel })
	}

	componentDidMount() {
		this.props.getBusinessUnitsList(this.headersSetCookie, null, 10000);
		this.props.getTagsList(this.headersSetCookie, null,10000);
		this.props.getCountriesList(this.headersSetCookie, null,10000);
		if (Object.keys(this.props.officesReducer.responseOfficesList).length === 0 ){
			this.props.getOfficesListLimited(this.headersSetCookie, null,10000)
		}
		this.setState({
			sentBusines:true, 
			getOfficesSent:true, 
			sentTags:true,
			sentCountries: true
		})
	}

	UNSAFE_componentWillMount() {
		this.props.getPromotion(this.headersSetCookie, this.state.idPromotion)
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		//Edito las oficinas
		if (!this.state.initial && next_props.offices !== undefined && Object.keys(next_props.offices).length > 0) {
			let long_name = next_props.offices.map(off => off.long_name);
			let officesId = next_props.offices.map(off => off.id);
			this.setState({
				cards: next_props.offices,
				officesChip: next_props.offices,
				selected: long_name,
				officesId: officesId,
				initial: true
			})
		}

		//recibe oficinas
		if (Object.keys(next_props.officesReducer.responseOfficesList).length > 0 && this.state.getOfficesSent) {
				console.log("setea officesAutocomplete");
				this.setState({
					officeAutocomplete:next_props.officesReducer.responseOfficesList.results,
				})

				this.setState({ 
					valueAutocomplete:'',
					defaultValueRadio: 'id', 
					optionsAutocomplete: next_props.officesReducer.responseOfficesList.results, 
					optionLabel: 'long_name',
					getOfficesSent : false
				})
		}

		//recibe unidades de negocio
		if (Object.keys(next_props.businessUnitsReducer.responseBusinessUnitsList).length > 0 && this.state.sentBusines) {
			console.log("setea businesAutocomplete");
			this.setState({
				businessAutocomplete: next_props.businessUnitsReducer.responseBusinessUnitsList.results,
				sentBusines: false
			})
		}

		//recibe tags
		if (Object.keys(next_props.officesReducer.responseTagsList).length > 0 && this.state.sentTags) {
			console.log("setea tagssAutocomplete");
			this.setState({
				tagsAutocomplete: next_props.officesReducer.responseTagsList.results,
				sentTags: false
			})
		}

		//recibe paises
		if (Object.keys(next_props.countriesReducer.responseCountriesList).length > 0 && this.state.sentCountries) {
			console.log("setea countriesAutocomplete");
			this.setState({
				countriesAutocomplete: next_props.countriesReducer.responseCountriesList.results,
				sentCountries: false
			})
		}

		if(next_props.promotionsReducer.responsePromotion.promotion_type === 'proveedor'){
			this.setState({promoProveedor:true, bloqueoOficinas:true})
		}

	}

	getValue = (object, value, reason) => {
		let filterOffices = []
		if (this.state.defaultValueRadio === 'tags') {

			this.state.officeAutocomplete.map(off => {
				if (Object.keys(off.tags.filter(e => e === value.id)).length > 0) filterOffices.push(off)
			})
		} else {
			filterOffices = this.state.officeAutocomplete.filter(e => e[this.state.defaultValueRadio] === value.id);
		}

		this.setState({
			options: filterOffices
		})
	}

	handleChange = (e) => {
		this.setState({ checked: e.target.value })
	}

	applyCheckbox = () => {
		//this.setState({ officesChip: this.state.cards })

		const newSelectedsObject = this.state.options.map((n) => n);				
		//Concatena anteriores y nuevos valores.
		let newCards = []
		let newOfficesId = []
		for(var element of newSelectedsObject) {		
			for (var selectedName of this.state.selected) {
				if (selectedName == element.long_name){
					if (this.state.cards.indexOf(element)==-1) {
						newCards.push(element)
					}
					if (this.state.officesId.indexOf(element.id)==-1) {
						newOfficesId.push(element.id)
					} 
				}
			}
		}

		newCards = this.state.cards.concat(newCards)
		newOfficesId = this.state.officesId.concat(newOfficesId)
		this.setState({ 
			cards: newCards, 
			officesId: newOfficesId,
			officesChip: newCards
		});
	}

	handleDeleteAllOffices = () => {
		this.setState({ 
			cards: [], 
			officesId: [],
			officesChip: []
		});
	}

	callbackAutocomplete = (value) => {

		this.setState({ filter: value })
	}

	handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelectedsObject = this.state.options.map((n) => n);	
			
			let newSelected = []
			for(var element of newSelectedsObject) {    	
				if (this.state.selected.indexOf(element.long_name)==-1) {
					newSelected.push(element.long_name)
				}
			}
			//console.log("nuevo selected", newSelected)
			this.setState({ selected: this.state.selected.concat(newSelected) });
			return;
		}else{
			this.setState({ selected: [] });
		}
	};

	handleClickChips = (row) => {
		const officeIndex = this.state.officesId.indexOf(row.id);
		const cardIndex = this.state.cards.indexOf(row);

		let newOfficesId = [];
		let newCards = [];
		
		//-1 si no se encuentra.
		if (officeIndex === -1) {
			newOfficesId = newOfficesId.concat(this.state.officesId, row.id);
			newCards = newCards.concat(this.state.cards, row);

		//0 indicaría que existe una ocurrencia en la primera posición (primer carácter) de la cadena
		} else  {
			let officeIdAux	 = this.state.officesId
			officeIdAux.splice( officeIndex, 1 );
			newOfficesId = officeIdAux

			let cardsAux = this.state.cards
			cardsAux.splice( cardIndex, 1 );
			newCards = cardsAux
		}

		this.setState({ officesChip: newCards, cards: newCards, officesId: newOfficesId });
	};

	handleClickCheckbox = (row) => {
		const selectedIndex = this.state.selected.indexOf(row.long_name);
		this.state.officesId.indexOf(row.id);
		let newSelected = [];

		//-1 si no se encuentra.
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(this.state.selected, row.long_name);
			//0 indicaría que existe una ocurrencia en la primera posición (primer carácter) de la cadena
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(this.state.selected.slice(1));
		} else if (selectedIndex === this.state.selected.length - 1) {
			newSelected = newSelected.concat(this.state.selected.slice(0, -1));
			//1 que existe en la segunda posición (segundo carácter), 2 en la tercera, etc.
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				this.state.selected.slice(0, selectedIndex),
				this.state.selected.slice(selectedIndex + 1),
			);
		}
		//console.log("nuevo selected", newSelected)
		this.setState({ selected: newSelected});
	};

	render() {
		const { filter, options, configClavesFilters } = this.state;
		const lowercasedFilter = filter.toLowerCase();
		const filteredData = options.filter(item => {
			return configClavesFilters.some(key =>
				item[key].toLowerCase().includes(lowercasedFilter)
			);
		});

		const LoaderSkeletonAutocomplete = (props) => {
			return (
				<ContentLoader
					height={"40"}
					width={"auto"}
					viewBox="0 0 100% 100%"
					backgroundColor="#d9d9d9"
					foregroundColor="#ecebeb"
				>
					<rect x="0%" y="0" rx="4" ry="4" width="100%" height="40" />
				</ContentLoader>
			)
		}

		const LoaderSkeletonFilters= (props) => {
			return (
				<ContentLoader
					height={"80"}
					width={"auto"}
					viewBox="0 0 100% 100%"
					backgroundColor="#d9d9d9"
					foregroundColor="#ecebeb"
				>					
					<rect x="0%" y="50" rx="4" ry="4" width="20%" height="10" />
					<rect x="21%" y="50" rx="4" ry="4" width="20%" height="10" />
					<rect x="42%" y="50" rx="4" ry="4" width="20%" height="10" />
					<rect x="63%" y="50" rx="4" ry="4" width="20%" height="10" />
					
				</ContentLoader>
			)
		}
		
		const Head = (props) => {
			const { order, orderBy } = props;
			return (
				<TableHead>
					<TableRow>

						{this.columns.map((headCell,index) => (
							<TableCell
								key={index}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.label}

							</TableCell>
						))}
					</TableRow>
				</TableHead>
			);
		}

		const isSelected = (name) => this.state.selected.indexOf(name) !== -1;

		const handleChangeRowsPerPage = (event) => {
			this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })

		};
		const handleChangePage = (event, newPage) => {
			this.setState({ page: newPage });

		};

		return (
			<Grid container spacing={1}>
				<Grid item sm={12}>
					<Typography variant="h5">Asignar oficinas</Typography>
					{this.state.optionsAutocomplete.length===0 
					? <LoaderSkeletonFilters/>
					:	<Radio

							id={`price_rule`}
							label="Regla precio"
							name={`price_rule`}
							placeholder="Regla precio"
							defaultValue={this.state.defaultValueRadio}
							classNameCustom={'textField'}
							optionsSelect={[
								{ "code": "id", "description": "Buscar por nombre" }, 
								{ "code": "bussines_unit", "description": "Buscar por unidad de negocio" }, 
								{ "code": "tags", "description": "Buscar por tags" },
								{ "code": "country", "description": "Buscar por país" }
							]}
							disabled={this.state.bloqueoOficinas? true : false}
							callback={this.callbackRadio}
							initial={true}
						/>
					}

				</Grid>
				<Grid item sm={12}>
					{this.state.optionsAutocomplete.length===0 
					? <LoaderSkeletonAutocomplete/>

					: <Autocomplete
						 inputValue={this.state.valueAutocomplete}
						 onChange={this.getValue}
						 onInputChange={(event, newInputValue) => {
							 this.setState({valueAutocomplete: newInputValue});
						   }}
						fullWidth
						freeSolo
						disableClearable
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						id="autocomplete"
						options={this.state.optionsAutocomplete}
						getOptionLabel={(option) => { return option[this.state.optionLabel] }}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								InputProps={{
									...params.InputProps, type: 'search', startAdornment: (
										<InputAdornment position="start">
											<Icon>search</Icon>
										</InputAdornment>
									)
								}}
								label={'Buscar'}
								placeholder={'Buscar'}
								fullWidth
							/>
						)}
						disabled={this.state.bloqueoOficinas? true : false}
					/>}

					{Object.keys(filteredData).length > 0 &&
						<Fragment>
							<Grid item sm={12}>
								<Divider label="Oficinas" />
							</Grid>
							<Grid item md={12}>

								<FilterAutocomplete
									callbackOffice={this.callbackAutocomplete}
								/>

							</Grid>
							<Grid item sm={12}>
								<Table
									aria-labelledby="tableTitle"
									size={'medium'}
									aria-label="enhanced table"
								>
									<Head
										numSelected={this.state.selected.length}
										rowCount={this.state.options.length}
									/>
									<TableBody>
										<TableRow hover role="checkbox">
											<TableCell colSpan={2} component="th" scope="row" padding="default" style={{ backgroundColor: '#dedede' }}>
												<FormControlLabel
													control={
														<Checkbox
															color="primary"
															indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.options.length}
															checked={this.state.options.length > 0 && this.state.selected.length === this.state.options.length}
															onChange={this.handleSelectAllClick}
															inputProps={{ 'aria-label': 'select all desserts' }}
														/>
													}
													label="Seleccionar todas las oficinas"
												/>
											</TableCell>
										</TableRow>
										{filteredData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row, index) => {
											const isItemSelected = isSelected(row.long_name);
											const labelId = `enhanced-table-checkbox-${index}`;

											return (
												<Fragment key={index}>
													<TableRow
														hover
														onClick={(event) => this.handleClickCheckbox(row)}
														role="checkbox"
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.long_name}
														selected={isItemSelected}
													>
														<TableCell padding="checkbox">
															<Checkbox
																checked={isItemSelected}
																inputProps={{ 'aria-labelledby': labelId }}
																color="primary"
															/>
														</TableCell>
														<TableCell component="th" id={labelId} scope="row" padding="none">
															{row.long_name}
														</TableCell>
													</TableRow>
												</Fragment>
											);
										})}

									</TableBody>
								</Table>
								<br />
								<Button onClick={this.applyCheckbox} color="primary" variant={`${this.state.selected.length > 0 ? 'contained' : 'outlined'}`}>
									Aplicar
								</Button>
								<br />
								<TablePagination
									rowsPerPageOptions={[2, 4, 8]}
									component="div"
									count={this.state.options.length}
									rowsPerPage={this.state.rowsPerPage}
									page={this.state.page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
									labelRowsPerPage={"Filas por página"}
								/>
							</Grid>

						</Fragment>
					}
					<Grid item sm={12}>
						<br />
						{Object.keys(this.state.officesChip).length > 0 &&
							<Grid container spacing={3}>
								<Grid item md={12}>
									<Typography variant="h5">{`Oficinas seleccionadas (${this.state.officesChip.length}):`}</Typography>
									<Button
										variant={"contained"}
										size={"small"}
										color={"secondary"}
										className={"buttonDeleteAllChips"}
										onClick = {this.handleDeleteAllOffices}
									>
										Quitar oficinas
									</Button>
								</Grid>
								<Grid item md={12} className="chipsContainer">
									{this.state.officesChip.map((select, i) =>
										<Chip key={i} size="small" label={select.long_name} onDelete={() => this.handleClickChips(select)} />
									)}
								</Grid>
							</Grid>}
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

class ProductStep extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			activeStep: this.props.step === undefined ? 1 : parseInt(this.props.step),
			totalSteps: ['1', '2'],
			button: [],
			finish: false,
			dataStepUno: '',
			offices: [],
			product: [],
			errorStepUno: false,
			errors: [{ "status": false, "text": "Debe ingresar un producto" }, { "status": false, "text": "Debe ingresar al menos una oficina" }],
			idPromotion: (this.props.match === undefined) ? this.props.idPromotion : this.props.match.params.id,
			promoProveedor : false,
			bloqueoOficinas : false,
		}
		this.promotion = {}
		this.headersSetCookie = window.localStorage.getItem('headersSetCookie')

	}

	handleFinish = () => {
		if(this.state.promoProveedor != true){
			if (Object.keys(this.stepDos.state.officesId).length > 0) {
				//this.setState({ finish: true })
				this.props.addProductOffices(this.headersSetCookie, this.state.product, this.stepDos.state.officesId, this.state.idPromotion,true)
			} else {
				this.state.errors[1] = { "status": true, 'text': 'Debe ingresar al menos una oficina' };
				this.setState({ errors: this.state.errors })
			}
		}else{
			if(Object.keys(this.state.product).length == 0){
				this.state.errors[0] = { "status": true, 'text': 'Debe ingresar un producto' };
				this.setState({ errors: this.state.errors })
			}
			else{
			this.props.addProductOffices(this.headersSetCookie, this.state.product, this.stepDos.state.officesId, this.state.idPromotion,true)
			}
		}
		
	}

	handleApplyEditOffices = () => {
		
		if (Object.keys(this.stepDos.state.officesId).length > 0) {

			//this.setState({ finish: true })
			this.props.editProductOffices(this.headersSetCookie, this.props.idPromotionProduct, this.stepDos.state.officesId, this.state.idPromotion, this.props.idPromotionProductOffices,true)
		} else {
			this.state.errors[1] = { "status": true, 'text': 'Debe ingresar al menos una oficina' };
			this.setState({ errors: this.state.errors })
		}
	}

	handleApply = () => {
		if (Object.keys(this.stepDos.state.officesId).length > 0) {
			this.setState({ apply: true, activeStep: 1 })
			this.props.addProductOffices(this.headersSetCookie, this.state.product, this.stepDos.state.officesId, this.state.idPromotion,false)

		} else {
			this.state.errors[1] = { "status": true, 'text': 'Debe ingresar al menos una oficina' };
			this.setState({ errors: this.state.errors })
		}
	}

	//aca bloqeuar el agregar oficinas.
	handleNext = () => {
		if (Object.keys(this.stepUno.productId).length > 0) {
			this.state.errors[0] = { "status": false, 'text': '' };
			this.setState({ product: this.stepUno.productId, activeStep: this.state.activeStep + 1, errors: this.state.errors })
			if(this.stepUno.state.promoProveedor == true){
				this.setState({ promoProveedor: true,  bloqueoOficinas: true })
			}
		} else {
			this.state.errors[0] = { "status": true, 'text': 'Debe ingresar un producto' };
			this.setState({ errors: this.state.errors })
		}
	};

	handleBack = () => {
		this.setState({ activeStep: this.state.activeStep - 1 })
	};

	handleReset = () => {
		this.setState({ activeStep: 1 })
	};

	getSteps() {
		return [1, 2];
	}

	UNSAFE_componentWillMount() {

		this.props.getPromotion(this.headersSetCookie, this.state.idPromotion)
		//this.props.getProductsList(this.headersSetCookie, null, 'list',10000)
		this.props.getProductsListReducida(this.headersSetCookie, null, 'list',10000)
		this.props.getOfficesListLimited(this.headersSetCookie, null,10000)
	}

	UNSAFE_componentWillReceiveProps(next_props) {

		this.promotion = next_props.promotionsReducer.responsePromotion;
		if(next_props.promotionsReducer.responsePromotionProductOffices !== null){
			this.setState({finish:true})
		}
	}

	getStepContent(step, props) {

		switch (step) {
			case 1:
				return (<StepUno {...props} ref={element => { this.stepUno = element }} />);

			case 2:

				return (<StepDos {...props} ref={element => { this.stepDos = element }} />);

			case 3:
				return 'end';

			default:
				return 'Unknown step';
		}
	}

	render() {
		const steps = this.getSteps();
		const LinkButtonShow = React.forwardRef((props, ref) => <Link href={`/dashboard/promociones/show/${this.state.idPromotion}`} {...props} ref={ref} />);
		
		return (
			<Fragment>
				{(this.state.finish && this.props.promotionsReducer.finalPromo) &&
					<Redirect to={{
						pathname: `/dashboard/promociones/show/${this.state.idPromotion}`
					}} />
				}
				<Grid container spacing={3}>
					<Grid item md={12}>
						<Card className="card-box">
							<CardHeader className="Detail_Action_ViewHeader"
								title={<div className="actions_top">
									<Button component={LinkButtonShow} startIcon={<Icon>arrow_back</Icon>} variant="outlined" color="primary">Volver</Button>
								</div>}
							/>
							{}
							<CardContent className="Detail_Action_ViewContainer">
								<Grid container spacing={1}>
									<Grid item sm={12}>
										{this.state.errors[this.state.activeStep - 1].status &&
											<Alert severity="warning">
												{this.state.errors[this.state.activeStep - 1].text}
											</Alert>}
										{this.getStepContent(this.state.activeStep, this.props)}
									</Grid>
								</Grid>
								<br />
							</CardContent>

							<CardActions>
								<br />
								<br />
								<br />
								<br />
								<br />
								<Grid container spacing={3}>
									<Grid item sm={6}>
										<Grid container spacing={1}>
											{this.props.idPromotion !== undefined ?
												(
													<Grid item>
														<Button onClick={this.handleApplyEditOffices} color="primary">
															Finalizar
														</Button>
													</Grid>
												)
												:
												(
													<Fragment>
														<Grid item>
															<Button color="primary" disabled={this.state.activeStep === 1} onClick={this.handleBack} >
																Volver
															</Button>
														</Grid>
														{this.state.activeStep === this.state.totalSteps.length ?
															(
																<Fragment>
																	<Grid item>
																		<Button onClick={this.handleFinish} color="primary">
																			Finalizar
																		</Button>
																	</Grid>
																	<Grid item>
																		<Button 
																			onClick={this.handleApply} 
																			color="primary"
																			disabled={this.state.promoProveedor ? true : false}
																			>
																			Aplicar y asignar nuevo producto
																		</Button>
																	</Grid>
																</Fragment>

															) : (
																<Fragment>
																	<Grid item>
																		<Button
																			variant="contained"
																			color="primary"
																			onClick={this.handleNext}>
																			Siguiente
																		</Button>
																	</Grid>
																</Fragment>
															)}
													</Fragment>
												)
											}

										</Grid>
									</Grid>
									<Grid item sm={6} >
										<Grid container spacing={1} justify="flex-end">
											<Grid item sm={2} >
												<Typography component="p" variant="h5"> {this.state.activeStep} de {this.state.totalSteps.length}</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</Fragment>
		)
	}
}
const mapStateToProps = reducers => {

	return reducers;
};

const mapDispachToProps = dispatch => {
	return {
		//getProductsList: (access, query, view,limit) => dispatch({ type: "GET_PRODUCTS", access, query, view,limit }),
		getCountriesList: (access, query,limit) => dispatch({ type: "GET_COUNTRIES", access, query,limit }),
		//getOfficesList: (access, query,limit) => dispatch({ type: "GET_OFFICES", access, query,limit }),
		addProductOffices: (access, product, offices, idPromotion, final) => dispatch({ type: "ADD_PRODUCT_OFFICES", access, product, offices, idPromotion, final }),
		editProductOffices: (access, idPromotionProduct, offices, idPromotion, idPromotionProductOffices,final) => dispatch({ type: "EDIT_PRODUCT_OFFICES", access, idPromotionProduct, offices, idPromotion, idPromotionProductOffices,final }),
		getPromotion: (access, query) => dispatch({ type: "GET_PROMOTION", access, query }),
		getBusinessUnitsList: (access, query, limit) => dispatch({ type: "GET_BUSINESS_UNITS", access, query, limit }),
		getTagsList: (access, query,limit) => dispatch({ type: "GET_TAGS", access, query,limit }),
		getProductsListReducida: (access, query, view,limit) => dispatch({ type: "GET_PRODUCTS_REDUCIDA", access, query, view,limit }),
		getOfficesListLimited: (access, query, limit) => dispatch({ type: "GET_OFFICES_LIMITED", access, query, limit }),
	};
};
export default connect(mapStateToProps, mapDispachToProps)(ProductStep)
