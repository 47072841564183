import React, { Component } from 'react'
import Theme from "../../assets/Theme";
import style from "../../config/style.json";
import { Container, Grid, Hidden } from '@material-ui/core';
import Background from '../../assets/images/home-login.jpg';
import Logo from '../../assets/images/logo-white.png';
import LogoColor from '../../assets/images/logo-color.png';


export default class MainLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hash: '',
            statesLogin: {
                login: false,
                forgot: false,
                reset: false,
                loginReintegro: true
            }
        }
    }

    render() {
        return (
            <Theme>
                <div className={`wrapper`} styles={style}>
                    <main className={`main login`}>
                        <Container disableGutters={true} maxWidth={false} className="h-100">
                            <Grid container className="h-100">
                                <Hidden smDown>
                                    <Grid item md={4}>
                                        <div className="bg-login h-100 align-items-center" style={{ backgroundImage: `url(${Background})` }}>
                                            <img className="logo mx-auto" src={Logo} alt='SafeTravelAssistance' />
                                        </div>
                                    </Grid>
                                    <Grid item md={8} className="align-items-center d-flex">
                                        {this.props.children}
                                    </Grid>
                                </Hidden>
                                <Hidden mdUp>
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item sm={0} className="align-items-start" style={{marginTop: '33px', marginLeft: 'auto', marginRight: 'auto'}}>
                                            <img className="logo mx-auto" src={LogoColor} alt='SafeTravelAssistance' style={{ display: 'block',width: '50%'}} />
                                        </Grid>
                                        <Grid item md={8} className="align-items-center d-flex">
                                            {this.props.children}
                                        </Grid>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Container>
                    </main>
                </div>
            </Theme>
        )
    }
}

