import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';

function* reservation(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/reservation/' : config.credentials.url + '/reservation/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "RESERVATION_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "RESERVATION_RESPONSE", value: error })
        console.log("hubo un error");
    }
}

function* reservations(actions) {

    try {
        
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/reservation/?offset=0&limit='+actions.limit : config.credentials.url + '/reservation/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "RESERVATIONS_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "RESERVATIONS_RESPONSE_LIST", value: error })
    }
}

function* filteredReservations(actions) {
    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/get_reservation/', requestOptions);        

        yield put({ type: "FILTERED_RESERVATIONS_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "FILTERED_RESERVATIONS_RESPONSE", value: error })
    }
}

function* exportReservations(actions) {

    try {
        
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/csv_reservation_filter/?offset=0&limit='+actions.limit : config.credentials.url + '/csv_reservation_filter/' + actions.query
        yield put({ type: "EXPORT_RESERVATIONS_RESPONSE", url: url})

    } catch (error) {
        yield put({ type: "EXPORT_RESERVATIONS_RESPONSE", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/reservation/', requestOptions);

      

        yield put({ type: "RESERVATION_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "RESERVATION_RESPONSE", value: error })
    }
}


function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/reservation/' + actions.data+ '/', requestOptions)


        yield put({ type: "RESERVATION_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "RESERVATION_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/reservation/' + actions.id+ '/', requestOptions)

        yield put({ type: "RESERVATION_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "RESERVATION_RESPONSE", value: error })
    }
}


export function* getReservation() {
    yield takeEvery("GET_RESERVATION", reservation)
}
export function* getReservationsList() {
    yield takeEvery("GET_RESERVATIONS", reservations)
}

export function* addReservation() {
    yield takeEvery("ADD_RESERVATION", add)
}

export function* removeReservation() {
    yield takeEvery("REMOVE_RESERVATION", remove)
}

export function* editReservation() {
    yield takeEvery("EDIT_RESERVATION", edit)
}

export function* getFilteredReservationsList() {
    yield takeEvery("GET_FILTERED_RESERVATIONS", filteredReservations)
}

export function* getExportReservationsList() {
    yield takeEvery("GET_EXPORT_RESERVATIONS", exportReservations)
}