const initialState = {
    responseFacturacion: [],
    errorFacturacion: false,
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };  
   switch (action.type) {
        case "FACTURACION_RESPONSE":
            console.log("respuesta facturacion",action.value)
            newState.responseFacturacion = action.value;
            break;
        default: 
            return newState;
    }

    //Posibles errores
    if (action.value.detail !== undefined){
            
        newState.errorFacturacion = true;
        newState.responseFacturacion.detail = {}
    }else{ 
        newState.errorFacturacion=false;
    }
    console.log("response facturacion",action.value);
    return newState;
};

export default reducer;