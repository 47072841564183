import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* passengerBlacklist(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/pax_black_list/' : config.credentials.url + '/pax_black_list/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "PAX_BLACKLIST_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PAX_BLACKLIST_RESPONSE", value: error })
    }
}

function* blacklist(actions) {

    try {
        
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/pax_black_list/?offset=0&limit='+actions.limit : config.credentials.url + '/pax_black_list/' + actions.query

        const response = yield call(apiCall, 'get', url, requestOptions)

        yield put({ type: "BLACKLIST_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "BLACKLIST_RESPONSE", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/pax_black_list/', requestOptions);  

        yield put({ type: "PAX_BLACKLIST_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PAX_BLACKLIST_RESPONSE", value: error })
    }
}

function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/pax_black_list/' + actions.data+ '/', requestOptions)

        yield put({ type: "PAX_BLACKLIST_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "PAX_BLACKLIST_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
      
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };

        const response = yield call(apiCall, 'put', config.credentials.url+ '/pax_black_list/' + actions.id+ '/', requestOptions)
 
        yield put({ type: "PAX_BLACKLIST_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "PAX_BLACKLIST_RESPONSE", value: error })
    }
}


export function* getPassengerBlacklist() {
    yield takeEvery("GET_PASSENGER_BLACKLIST", passengerBlacklist)
}
export function* getBlacklist() {
    yield takeEvery("GET_BLACKLIST", blacklist)
}

export function* addPassengerBlacklist() {
    yield takeEvery("ADD_PASSENGER_BLACKLIST", add)
}

export function* removePassengerBlacklist() {
    yield takeEvery("REMOVE_PASSENGER_BLACKLIST", remove)
}

export function* editPassengerBlacklist() {
    yield takeEvery("EDIT_PASSENGER_BLACKLIST", edit)
}
