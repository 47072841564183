import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import apiCall from '../services';
import config from '../config/config.json';
import { saveAs } from 'file-saver';

function* voucher(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
		};

		let url =
			actions.query === null
				? config.credentials.url + '/voucher/'
				: config.credentials.url + '/voucher/' + actions.query;
		const response = yield call(apiCall, 'get', url, requestOptions);
		yield put({ type: 'VOUCHER_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'VOUCHER_RESPONSE', value: error });
	}
}

function* vouchers(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
		};

		let url =
			actions.query === null
				? config.credentials.url + '/voucher/?offset=0&limit=' + actions.limit
				: config.credentials.url + '/voucher/' + actions.query;
		const response = yield call(apiCall, 'get', url, requestOptions);
		yield put({ type: 'VOUCHERS_LIST_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'VOUCHERS_LIST_RESPONSE', value: error });
	}
}

function* add(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
			body: JSON.stringify(actions.data),
		};

		const response = yield call(
			apiCall,
			'POST',
			config.credentials.url + '/voucher/',
			requestOptions
		);
		yield put({ type: 'VOUCHER_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'VOUCHER_RESPONSE', value: error });
	}
}

function* remove(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
		};

		const response = yield call(
			apiCall,
			'delete',
			config.credentials.url + '/voucher/' + actions.data + '/',
			requestOptions
		);
		yield put({ type: 'VOUCHER_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'VOUCHER_RESPONSE', value: error });
	}
}

function* edit(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
			body: JSON.stringify(actions.data),
		};

		const response = yield call(
			apiCall,
			'put',
			config.credentials.url + '/voucher/' + actions.id + '/',
			requestOptions
		);
		yield put({ type: 'VOUCHER_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'VOUCHER_RESPONSE', value: error });
	}
}

function* hashExportVouchers(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
		};

		let url =
			actions.query === null
				? config.credentials.url + '/csv_voucher_filter/?offset=0&limit=1000000'
				: config.credentials.url +
				'/csv_voucher_filter/' +
				actions.query +
				'&offset=0&limit=100000';

		const response = yield call(apiCall, 'get', url, requestOptions);
		//saveAs(response.message, "filter_voucher.xlsx")
		yield put({ type: 'HASH_EXPORT_VOUCHERS_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'HASH_EXPORT_VOUCHERS_RESPONSE', value: error });
	}
}

function* checkExportVouchers(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
		};

		const response = yield call(
			apiCall,
			'GET',
			config.credentials.url + '/report/' + actions.hash + '/',
			requestOptions
		);

		yield put({ type: 'REPORT_EXPORT_VOUCHERS_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'REPORT_EXPORT_VOUCHERS_RESPONSE', value: error });
	}
}

function* updateExportVouchers(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
		};

		const response = yield call(
			apiCall,
			'PUT',
			config.credentials.url + '/report/' + actions.hash + '/',
			requestOptions
		);

		yield put({ type: 'UPDATE_EXPORT_VOUCHERS_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'UPDATE_EXPORT_VOUCHERS_RESPONSE', value: error });
	}
}

function* cancelAll(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
			body: JSON.stringify(actions.data),
		};

		let url = config.credentials.url + '/cancelAllVouchers/';
		const response = yield call(apiCall, 'POST', url, requestOptions);
		yield put({ type: 'CANCEL_VOUCHERS_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'CANCEL_VOUCHERS_RESPONSE', value: error });
	}
}

function* changeStatus(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};

		let jsonRequest = {
			reservation_id: null,
			voucher_id: actions.data.selectedVouchers,
			status: actions.data.setStatus,
		};

		const requestOptions = {
			headers: headers,
			body: JSON.stringify(jsonRequest),
		};

		let url = config.credentials.url + '/status_reservation';
		const response = yield call(apiCall, 'POST', url, requestOptions);

		yield put({ type: 'CHANGE_STATUS_VOUCHERS_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'CHANGE_STATUS_VOUCHERS_RESPONSE', value: error });
	}
}

function* print(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
			body: JSON.stringify(actions.data),
		};

		const response = yield call(
			apiCall,
			'POST',
			config.credentials.url + '/pdfVoucher/',
			requestOptions
		);

		yield put({ type: 'PRINT_VOUCHER_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'PRINT_VOUCHER_RESPONSE', value: error });
	}
}

function* printMultivoucher(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};

		let jsonRequest = {
			id_reservation: actions.id,
		};

		const requestOptions = {
			headers: headers,
			body: JSON.stringify(jsonRequest),
		};
		const response = yield call(
			apiCall,
			'POST',
			config.credentials.url + '/pdfMultiVoucher/',
			requestOptions
		);
		yield put({ type: 'PRINT_MULTIVOUCHER_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'PRINT_MULTIVOUCHER_RESPONSE', value: error });
	}
}

function* sendCCGG(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
			body: JSON.stringify(actions.data),
		};

		const response = yield call(
			apiCall,
			'POST',
			config.credentials.url + '/sendEmailCCGG/',
			requestOptions
		);

		yield put({ type: 'SEND_CCGG_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'SEND_CCGG_RESPONSE', value: error });
	}
}

function* sendMultivoucher(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
			body: JSON.stringify(actions.data),
		};

		const response = yield call(
			apiCall,
			'POST',
			config.credentials.url + '/sendMultiVoucher/',
			requestOptions
		);
		yield put({ type: 'SEND_MULTIVOUCHER_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'SEND_MULTIVOUCHER_RESPONSE', value: error });
	}
}

function* sendTermsandConditions(actions) {
	try {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${actions.access}`,
		};
		const requestOptions = {
			headers: headers,
		};

		const response = yield call(
			apiCall,
			'GET',
			config.credentials.url + '/sendAcceptConditions/' + actions.data,
			requestOptions
		);

		yield put({ type: 'SEND_TERMS_AND_CONDITIONS_RESPONSE', value: response });
	} catch (error) {
		yield put({ type: 'SEND_TERMS_AND_CONDITIONS_RESPONSE', value: error });
	}
}

export function* getVoucher() {
	yield takeEvery('GET_VOUCHER', voucher);
}

export function* getVouchersList() {
	yield takeEvery('GET_VOUCHERS', vouchers);
}

export function* addVoucher() {
	yield takeEvery('ADD_VOUCHER', add);
}

export function* removeVoucher() {
	yield takeEvery('REMOVE_VOUCHER', remove);
}

export function* editVoucher() {
	yield takeEvery('EDIT_VOUCHER', edit);
}

export function* cancelAllVouchers() {
	yield takeEvery('CANCEL_ALL_VOUCHER', cancelAll);
}

export function* changeStatusVouchers() {
	yield takeEvery('CHANGE_STATUS_VOUCHERS', changeStatus);
}

export function* printVoucher() {
	yield takeEvery('PRINT_VOUCHER', print);
}

export function* printReservationMultivoucher() {
	yield takeEvery('PRINT_RESERVATIONVOUCHER', printMultivoucher);
}

export function* sendMailCCGG() {
	yield takeEvery('SEND_MAIL_CCGG', sendCCGG);
}

export function* sendMailMultivoucher() {
	yield takeEvery('SEND_MAIL_MULTIVOUCHER', sendMultivoucher);
}

export function* sendTermsAndConditions() {
	yield takeEvery('SEND_TERMS_AND_CONDITIONS', sendTermsandConditions);
}

export function* getHashExportVouchersList() {
	yield takeEvery('GET_HASH_EXPORT_VOUCHERS', hashExportVouchers);
}

export function* checkExportVouchersList() {
	yield takeEvery('CHECK_EXPORT_VOUCHERS', checkExportVouchers);
}

export function* updateExportVouchersList() {
	yield takeEvery('UPDATE_EXPORT_VOUCHERS', updateExportVouchers);
}
