import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* country(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/country/' : config.credentials.url + '/country/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
       // console.log('response')
        yield put({ type: "COUNTRY_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "COUNTRY_RESPONSE", value: error })
    }
}

function* countries(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/country/?offset=0&limit='+actions.limit : config.credentials.url + '/country/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "COUNTRIES_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "COUNTRIES_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/country/', requestOptions);

      

        yield put({ type: "COUNTRY_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "COUNTRY_RESPONSE", value: error })
    }
}


function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/country/' + actions.data+ '/', requestOptions)


        yield put({ type: "COUNTRY_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "COUNTRY_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/country/' + actions.id+ '/', requestOptions)

        yield put({ type: "COUNTRY_RESPONSE", value: response})
        
    } catch (error) {
        yield put({ type: "COUNTRY_RESPONSE", value: error })
    }
}


export function* getCountry() {
    yield takeEvery("GET_COUNTRY", country)
}
export function* getCountriesList() {
    yield takeEvery("GET_COUNTRIES", countries)
}

export function* addCountry() {
    yield takeEvery("ADD_COUNTRY", add)
}

export function* removeCountry() {
    yield takeEvery("REMOVE_COUNTRY", remove)
}

export function* editCountry() {
    yield takeEvery("EDIT_COUNTRY", edit)
}
