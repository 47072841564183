import React, { Component, Fragment } from 'react'
import { Chip, IconButton, Tooltip, Dialog, Select, MenuItem, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Card, CardHeader, CardContent, Icon, Link, Checkbox } from '@material-ui/core';
import { connect } from "react-redux";
import ProductStep from './ProductStep';
import Divider from '../../common/fields/Dividers';

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {

            offices: []
        }

        this.headersSetCookie = window.localStorage.getItem('headersSetCookie');
    }

    UNSAFE_componentWillMount() {
        console.log(this.props.match.params)
        this.props.getProductOffices(this.headersSetCookie, this.props.match.params.idPromotionProductOffices, this.props.match.params.view)
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        if (next_props.responsePromotionProductOffices !== null){
            this.setState({
                offices: next_props.responsePromotionProductOffices.offices === null ? [] : next_props.responsePromotionProductOffices.offices
            })
        }
    }

    render() {
        const LinkButtonShow = React.forwardRef((props, ref) => <Link href={`/dashboard/promociones/show/${this.props.match.params.id}`} {...props} ref={ref} />);
        const LinkAddProduct = React.forwardRef((props, ref) => <Link href={`/dashboard/promociones/${this.props.match.params.id}/producto/create?pp=${this.props.match.params.idPromotionProduct}&p=${this.props.match.params.idProduct}&o=${this.props.match.params.idPromotionProductOffices}&step=2`} {...props} ref={ref} />);

        return (
            <Fragment>
                {this.props.match.params.view === 'show' &&
                <Card className="card-box">
                    <CardHeader className="Detail_Action_ViewHeader"
                        title={<div className="actions_top">
                            <Button component={LinkButtonShow} startIcon={<Icon>arrow_back</Icon>} variant="outlined" color="primary">Volver</Button></div>}
                        action={this.props.match.params.view !== 'show' &&
                            <Button component={LinkAddProduct} color="primary" startIcon={<Icon>add</Icon>} variant="contained" className="buttonEdit" >Asignar oficina</Button>}
                    />

                    <CardContent className="Detail_Action_ViewContainer">
                        <Grid container spacing={1}>
                            <Grid item sm={12}>
                                <Typography variant="h5">Oficinas</Typography>
                            </Grid>
                            {Object.keys(this.state.offices).length > 0 &&
                                <Grid item sm={12} className="chipsContainer">
                                    <table className="tableMaterial">
                                        {this.state.offices.map((office, i) => (
                                            <Chip key={i} size="small" label={office.long_name} />
                                        ))}
                                    </table>
                                </Grid>
                            }
                        </Grid>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </CardContent>
                </Card>}

            {this.props.match.params.view === 'edit' &&
                <ProductStep
                    step={2}
                    offices={this.state.offices}
                    idPromotion={this.props.match.params.id}
                    idProduct={this.props.match.params.idProduct}
                    idPromotionProduct={this.props.match.params.idPromotionProduct}
                    idPromotionProductOffices={this.props.match.params.idPromotionProductOffices}
                />}
            </Fragment>
        )
    }
}

const mapStateToProps = reducers => {

    return reducers.promotionsReducer;
};

const mapDispatchToProps = dispatch => {
    return {

        getProductOffices: (access, query, view) => dispatch({ type: "GET_PRODUCT_OFFICES", access, query, view }),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Product)
