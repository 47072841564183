import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/facturacion/', requestOptions);
        yield put({ type: "FACTURACION_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "FACTURACION_RESPONSE", value: error })
    }
}

export function* addFacturacion() {
    yield takeEvery("ADD_FACTURACION", add)
}

