import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* spertaProduct(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/product_sperta/' : config.credentials.url + '/product_sperta/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
        response.view= 'show'    
        yield put({ type: "PRODUCT_SPERTA_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PRODUCT_SPERTA_RESPONSE", value: error })
    }
}

function* spertaProducts(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/product_sperta/?offset=0&limit='+actions.limit : config.credentials.url + '/product_sperta/' + actions.query
        const response = yield call(apiCall, 'get', url, requestOptions)
        response.view= 'list'     
        yield put({ type: "PRODUCT_SPERTA_LIST_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PRODUCT_SPERTA_LIST_RESPONSE", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
         
        const response = yield call(apiCall, 'POST',config.credentials.url+'/product_sperta/', requestOptions);
        response.view= 'create'
        yield put({ type: "PRODUCT_SPERTA_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "PRODUCT_SPERTA_RESPONSE", value: error })
    }
}


function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/product_sperta/' + actions.data+ '/', requestOptions)
        response.view= 'remove'
        yield put({ type: "PRODUCT_SPERTA_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "PRODUCT_SPERTA_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/product_sperta/' + actions.id+ '/', requestOptions)
        response.view= 'edit'
        yield put({ type: "PRODUCT_SPERTA_RESPONSE", value: response})
        
    } catch (error) {
        yield put({ type: "PRODUCT_SPERTA_RESPONSE", value: error })
    }
}


export function* getProductSperta() {
    yield takeEvery("GET_PRODUCT_SPERTA", spertaProduct)
}
export function* getProductsSpertaList() {
    yield takeEvery("GET_PRODUCTS_SPERTA", spertaProducts)
}

export function* addProductSperta() {
    yield takeEvery("ADD_PRODUCT_SPERTA", add)
}

export function* removeProductSperta() {
    yield takeEvery("REMOVE_PRODUCT_SPERTA", remove)
}

export function* editProductSperta() {
    yield takeEvery("EDIT_PRODUCT_SPERTA", edit)
}
