import React, { Component } from 'react';
import { Grid, Typography, Button, Link, Container } from '@material-ui/core';
import Password from '../../common/fields/Password';
import { reset } from '../../../services/LoginService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { NavLink as RouterLink } from "react-router-dom";
import { connect } from "react-redux";

class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordConfirm: '',
            tokenPassword: null,
            responseStatus: null,
            responseErrors: [],
            errors: {
                status: {
                    password: false,
                    passwordConfirm: false
                },
                text: {
                    password: '',
                    passwordConfirm: 'La contraseña no coincide'
                }

            }
        }
    }

    send = async () => {
        
        let form = new Object();
        let countValid = 0;
        let errors = [];
        form["token"] = this.state.tokenPassword;

        if (this.state.password && this.state.password !== null) {
            if (this.state.password.state.defaultValue !== '') {
                countValid++;
            }
        }

        if (this.state.passwordConfirm && this.state.passwordConfirm !== null) {
            if (this.state.passwordConfirm.state.defaultValue !== '' && (this.state.passwordConfirm.state.defaultValue === this.state.password.state.defaultValue)) {
                countValid++;
                form[this.state.password.props.name] = this.state.password.state.defaultValue;
            }
        }

        errors = {
            status: {
                password: (this.state.password && this.state.password.state.defaultValue !== '' && this.state.password.state.errorText === '') ? false : true,
                passwordConfirm: (this.state.passwordConfirm && this.state.passwordConfirm.state.defaultValue !== '' && this.state.passwordConfirm.state.errorText === '' && (this.state.passwordConfirm.state.defaultValue === this.state.password.state.defaultValue)) ? false : true
            },
            text: {
                password: (this.state.password && this.state.password.state.defaultValue !== '' && this.state.password.state.errorText === '') ? '' : "Enter an password",
                passwordConfirm: (this.state.passwordConfirm && this.state.passwordConfirm.state.defaultValue !== '' && this.state.passwordConfirm.state.errorText === '' && (this.state.passwordConfirm.state.defaultValue === this.state.password.state.defaultValue)) ? "" : "Incorrect password. Repeat the same password"
            }

        }

        this.setState({ errors: errors });

        console.log(errors);
        console.log(form);

        if (2 === countValid) {
            this.props.confirmPassword(form); 
        }
    }

    componentDidMount(){
        this.setState( { tokenPassword: this.props.match.params.hash} );
    }

	componentDidUpdate(prevProps, prevState) {
        if ((prevProps.responsePasswordConfirm != this.props.responsePasswordConfirm) || (prevProps.responseError != this.props.responseError)){
            if(this.props.responsePasswordConfirm.status!= undefined && this.props.responsePasswordConfirm.status== 'OK'){     
                console.log("Respuesta status OK");
				this.setState( { responseStatus : this.props.responsePasswordConfirm.status } )
                this.setState({ responseErrors : [] });
			}else{
                    console.log("Respuesta status FAILED");
                    this.setState({ responseStatus : 'FAILED' })
                    if (this.props.responseError.password!= undefined ){
                        console.log("Y errores varios");
                        this.setState({ responseErrors : this.props.responseError.password })
                    }
                
            }
		}
    }

    render() {
        return (
                    this.state.responseStatus == 'OK'
                    ?   (
                            <Container maxWidth="md" className="mx-auto">
                                <div className="login_container">
                                    <div className="form-header">
                                        <Typography component="h1" variant="h4" className="title">Se ha modificado la contraseña con exito.</Typography>
                                    </div>
                                    <div className="form-content">

                                        <Alert severity="info" className="mb-2">
                                            <AlertTitle>{this.state.responseReset}</AlertTitle>
                                            Ya puedes usar tu nueva contraseña para logearte en tu cuenta!
                                        </Alert>

                                        <Button
                                            component={RouterLink}
                                            variant="contained"
                                            color="primary"
                                            className="submit"
                                            size="large"
                                            to={{ pathname: `/login` }}
                                    
                                        >
                                            Login
                                        </Button>

                                        
                                    </div>

                                </div>
                            </Container>
                        ) 
                    :  (
                            <Container maxWidth="md" className="mx-auto">
                                {this.state.responseStatus == 'FAILED' &&
                                
                                    (this.state.responseErrors.length>0
                                    ?   (
                                            <Container maxWidth="md" className="mx-auto">
                                                <div className="login_container">
                                                    <Alert severity="error" className="mb-2">
                                                        <AlertTitle>Contraseña insegura</AlertTitle>
                                                        Asegurese de que cumpla con los siguientes requisitos:
                                                        <ul>
                                                            <li> No debe ser similar a su nombre de usuario</li>
                                                            <li> Debe contener al menos 8 caracteres.</li>
                                                        </ul>
                                                    </Alert>
                                                </div>
                                            </Container>
                                        )
                                    :   (
                                            <Container maxWidth="md" className="mx-auto">
                                                <div className="login_container">
                                                    <Alert severity="error" className="mb-2">
                                                        {/* <AlertTitle>{this.state.responseReset}</AlertTitle> */}
                                                        El token de recuperación de contraseña ha expirado o es invalido.
                                                    </Alert>
                                                </div>
                                            </Container>
                                        )
                                    )
                                }

                                <div className="login_container">
                                    <div className="form-header">
                                        <Typography component="h1" variant="h4" className="title">Cambiar contraseña</Typography>
                                    </div>
                                    <form className="form" noValidate>
                                        <Grid container spacing={1}>
                                            <Grid item md={6}>
                                                <Password
                                                    ref={element => { this.state.password = element }}
                                                    id="password"
                                                    label="Contraseña"
                                                    name="password"
                                                    placeholder="Ingresa una nueva contraseña"
                                                    defaultValue={''}
                                                    disabled={false}
                                                    error={this.state.errors.status.password}
                                                    errorText={this.state.errors.text.password}
                                                    classNameCustom={'textField'}
                                                    autoComplete="current-password"
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <Password
                                                    ref={element => { this.state.passwordConfirm = element }}
                                                    id="confirmPassword"
                                                    label="Confirmar contraseña"
                                                    name="confirmPassword"
                                                    placeholder="Confirma nueva contraseña"
                                                    defaultValue={''}
                                                    disabled={false}
                                                    error={this.state.errors.status.passwordConfirm}
                                                    errorText={this.state.errors.text.passwordConfirm}
                                                    classNameCustom={'textField'}
                                                    autoComplete="current-password"
                                                    required={true}
                                                />
                                            </Grid>
                                        </Grid>

                                        <hr />

                                        <div className="text-right">

                                            <Button 
                                                variant="text"
                                                component={RouterLink} to={{ pathname: `/login` }}
                                                className="mr-1"
                                                size="large"
                                            >Ingresar con otra cuenta</Button>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="submit"
                                                size="large"
                                                onClick={() => this.send()}
                                            >
                                                Cambiar contraseña
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </Container>
                        )
            
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
	return {
		getLogout: (access) => dispatch({ type: "GET_LOGOUT", access }),
		confirmPassword: (data) => dispatch({ type: "CONFIRM_PASSWORD", data }),
	};
};

export default connect(mapStateToProps, mapDispachToProps)(Reset)