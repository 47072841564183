const initialState = {
    errorLogin: false,
    errorPassword: false,
    responseLogin: false,
    responseUserLogin: [],
    responsePasswordReset: [],
    responsePasswordConfirm: [],
    responseError: [],
    responseLoginReintegro: false,
    responseDataLoginReintegro: {},
    responseDataUpdateReintegro: {},
    responseErrorUpdateReintegro: false,
    responseMsjerrorUpdateReintegro: null,
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "USER_LOGIN_RESPONSE":
            if (action.value.detail !== undefined) { //si hubo error
                newState.responseError = action.value;
                newState.responseUserLogin = [];
            } else { //si NO hubo error
                newState.responseUserLogin = action.value;
                newState.responseError = [];
                console.log("[USER_LOGIN_RESPONSE] Se actualizo userData en localStorage");
                let newUserData = JSON.stringify(action.value)
                window.localStorage.setItem('userData', newUserData);
                window.localStorage.setItem('headersSetCookie', action.access);

            }
            break;
        case "GET_LOGIN_RESPONSE":
            if (action.value.access !== undefined) {
                console.log("[GET_LOGIN_RESPONSE] Se actualizo acces token en localStorage");
                newState.headersSetCookie = action.value.access;
                newState.responseLogin = true;
                newState.errorLogin = false;
                window.localStorage.setItem('headersSetCookie', action.value.access);
            } else {
                newState.errorLogin = true;
            }
            break;
        case "GET_LOGIN_REINTEGRO_RESPONSE":
            if (action.value.refunds !== undefined) {
                newState.headersSetCookie = action.value.access;
                newState.responseLoginReintegro = true;
                newState.errorLoginReintegro = false;
                newState.responseDataLoginReintegro = action.value;
                window.localStorage.setItem('headersSetCookie', action.value.access);
                newState.mensajeError = false;
            } else {
                newState.errorLoginReintegro = true;
                if (action.value.detail !== undefined) {
                    newState.mensajeError = action.value.detail;
                }
            }
            break;
        case "GET_UPLOAD_REINTEGRO_RESPONSE":
            if (action.value.status !== undefined) {
                if(action.value.status === 'ok'){
                    newState.responseDataUpdateReintegro = action.value.detail;
                    newState.responseErrorUpdateReintegro = false;
                    newState.responseMsjerrorUpdateReintegro = null
                }else if(action.value.status === 'ko'){
                    newState.responseDataUpdateReintegro = {};
                    newState.responseErrorUpdateReintegro = true;
                    newState.responseMsjerrorUpdateReintegro = action.value.message
                }
                else if(action.value.status === 'error'){
                    newState.responseDataUpdateReintegro = {};
                    newState.responseErrorUpdateReintegro = true;
                    newState.responseMsjerrorUpdateReintegro = 'Hubo un error, por favor inténtelo nuevamente.'
                }
            } else {
                newState.responseDataUpdateReintegro = {};
                newState.responseErrorUpdateReintegro = true;
                newState.responseMsjerrorUPdateReintegro = 'Hubo un error, por favor inténtelo nuevamente.'
            }
            break;
        case "GET_LOGOUT":
            newState.responseLogin = false;
            newState.headersSetCookie = '';
            window.localStorage.removeItem('headersSetCookie');
            window.localStorage.removeItem('userData');
            break;
        case "GET_LOGOUT_REINTEGRO":
            newState.responseLoginReintegro = false;
            sessionStorage.clear();
            window.localStorage.clear();
            newState.headersSetCookie = '';
            window.localStorage.removeItem('headersSetCookie');
            window.localStorage.removeItem('userData');
            break;
        case "PASSWORD_RESET_RESPONSE":
            console.log("[PASSWORD_RESET_RESPONSE] respuesta recibida", action.value);
            if (action.value.detail !== undefined || action.value.email !== undefined) { //si hubo error
                newState.responsePasswordReset = [];
                newState.responseError = action.value;
                newState.errorPassword = true;
            } else { //si NO hubo error
                newState.responsePasswordReset = action.value;
                newState.responseError = [];
                newState.errorPassword = false;
            }
            break;
        case "PASSWORD_CONFIRM_RESPONSE":
            if (action.value.detail !== undefined || action.value.password !== undefined) {
                console.log("[PASSWORD_CONFIRM_RESPONSE] se recibio respuesta status FAILED", action.value);
                newState.responsePasswordConfirm = [];
                newState.responseError = action.value;
                newState.errorPassword = true;
            } else { // no hubo errores
                console.log("[PASSWORD_CONFIRM_RESPONSE] se recibio respuesta status OK", action.value);
                newState.responsePasswordConfirm = action.value;
                newState.responseError = [];
                newState.errorPassword = false;
            }
            break;
        default:
            return newState;
    }

    return newState;
};

export default reducer;