import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';


function* supplier(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/supplier/' : config.credentials.url + '/supplier/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "SUPPLIER_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "SUPPLIER_RESPONSE", value: error })
    }
}

function* suppliers(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };

        const requestOptions = {
            headers: headers
        };
        
        let url = (actions.query === null) ? config.credentials.url + '/supplier/?offset=0&limit='+actions.limit : config.credentials.url + '/supplier/' + actions.query
        
        const response = yield call(apiCall, 'get', url, requestOptions)
     
        yield put({ type: "SUPPLIERS_RESPONSE_LIST", value: response })

    } catch (error) {
        yield put({ type: "SUPPLIERS_RESPONSE_LIST", value: error })
    }
}

function* add(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        // console.log('add proveedores',requestOptions)
        const response = yield call(apiCall, 'POST',config.credentials.url+'/supplier/', requestOptions);
        //console.log('response proveedores',response)
      

        yield put({ type: "SUPPLIER_RESPONSE", value: response })

    } catch (error) {
        yield put({ type: "SUPPLIER_RESPONSE", value: error })
    }
}


function* remove(actions) {

    try {

        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers
        };
 

        const response = yield call(apiCall, 'delete', config.credentials.url+ '/supplier/' + actions.data+ '/', requestOptions)


        yield put({ type: "SUPPLIER_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "SUPPLIER_RESPONSE", value: error })
    }
}

function* edit(actions) {

    try {
        const headers = {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${actions.access}`
        };
 
        const requestOptions = {
            headers: headers,
            body: JSON.stringify(actions.data)
        };
        
        const response = yield call(apiCall, 'put', config.credentials.url+ '/supplier/' + actions.id+ '/', requestOptions)

        yield put({ type: "SUPPLIER_RESPONSE", value: response})
 
    } catch (error) {
        yield put({ type: "SUPPLIER_RESPONSE", value: error })
    }
}



export function* getSupplier() {
    yield takeEvery("GET_SUPPLIER", supplier)
}
export function* getSuppliersList() {
    yield takeEvery("GET_SUPPLIERS", suppliers)
}

export function* addSupplier() {
    yield takeEvery("ADD_SUPPLIER", add)
}

export function* removeSupplier() {
    yield takeEvery("REMOVE_SUPPLIER", remove)
}

export function* editSupplier() {
    yield takeEvery("EDIT_SUPPLIER", edit)
}

