import React, { Component } from 'react';
import { Button, MenuItem, Menu, Link } from '@material-ui/core';
import {forgot} from './../../../services/LoginService';
import { NavLink as RouterLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import config from "../../../config/config.json"

class MenuDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			tokenResetPassword: null,
			officeRequestSent: false,
			countryRequestSent: false,
		}
	}

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	/*
	* Se desloguea redireccionando al login y eliminando cookies.
 	*/
	handleLogout = () => {
		this.props.getLogout(window.localStorage.getItem('headersSetCookie'));
	}

	/*
	* Redirecciona a la B2B con el mismo token de sesion.
 	*/
	handleRedirectB2B = () => {
		let urlB2B = config.B2B.url;
		let office = JSON.parse(window.localStorage.getItem('userData')).office;
		if (office!==null){
			this.props.getOffice(window.localStorage.getItem('headersSetCookie'), office)
			this.setState( {officeRequestSent: true} )
		}
	}

	/*
	* Redirecciona al admin viejo.
 	*/
	handleRedirectOldSite = () => {
		let url = config.old_site.url;
		window.open(url, '_blank');
	}

	/*
	* Pide token de autorizacion para cambio de contraseña.
 	*/
	handleResetPassword = async() => {
		let form = new Object();
		form["email"] = JSON.parse(window.localStorage.getItem('userData')).email;
		this.props.resetPassword(form); 
		this.setState({ anchorEl: null });
	};

	componentDidUpdate(prevProps, prevState){
		let token = window.localStorage.getItem('headersSetCookie');
		if (this.state.officeRequestSent) {
			if (prevProps.officesReducer.responseOffice != this.props.officesReducer.responseOffice ){
				if (this.props.officesReducer.responseOffice.detail == undefined) {
					let country = this.props.officesReducer.responseOffice.country;
					if (country != null){
						this.props.getCountry(token, country)
						this.setState({countryRequestSent: true})
					}
				}
				this.setState({officeRequestSent: false}) // ya no se espera respuesta de office, por si cambia responseOffice en reducer.
			}
		}
		if (this.state.countryRequestSent){
			if (prevProps.countriesReducer.responseCountry != this.props.countriesReducer.responseCountry ){
				if (this.props.countriesReducer.responseCountry.detail == undefined) {
					let urlB2B;
					//Si el pais tiene una url, redirige a esa
					if (this.props.countriesReducer.responseCountry.url_redirect!=null){
						urlB2B = this.props.countriesReducer.responseCountry.url_redirect;
					}else{ //caso contrario, pongo url por defecto
						urlB2B = config.B2B.url
					}
					
					if (!token){
						urlB2B += '/login';
					}else{
						urlB2B += '/?access='+token;
					}
					window.open(urlB2B, '_blank');
				}
				this.setState( {countryRequestSent : false} ); // ya no se espera respuesta de country, por si cambia responseCountry en reducer.
			}
		}
		
	}

	render() {

		return (
				<div>
					<Button
						startIcon={this.props.buttonStartIcon}
						endIcon={this.props.buttonEndIcon}
						color="inherit"
						className="button_lowecase"
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={this.handleClick}>
						{this.props.buttonText}
					</Button>
					<Menu
						id="simple-menu"
						anchorEl={this.state.anchorEl}
						keepMounted
						open={Boolean(this.state.anchorEl)}
						onClose={this.handleClose}
						getContentAnchorEl={null}
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						transformOrigin={{ vertical: "top", horizontal: "right" }}
					>
						{/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
						<MenuItem onClick={this.handleRedirectB2B}>Ir a mi sitio</MenuItem>
						<MenuItem onClick={this.handleRedirectOldSite}>Ir a Admin viejo</MenuItem>
						<MenuItem onClick={this.handleResetPassword}>Cambiar contraseña</MenuItem>
						<MenuItem onClick={this.handleLogout} component={RouterLink} to={{ pathname: `/login` }}>
							Logout
						</MenuItem>
					</Menu>
				</div>
			)
	}
}

const mapStateToProps = reducers => {
    return reducers;
};

const mapDispachToProps = dispatch => {
	return {
		getLogout: (access) => dispatch({ type: "GET_LOGOUT", access }),
		resetPassword: (data) => dispatch({ type: "RESET_PASSWORD", data }),
		getOffice: (access, query) => dispatch({ type: "GET_OFFICE", access, query }),
		getCountry: (access, query) => dispatch({ type: "GET_COUNTRY", access, query }),
	};
};

export default connect(mapStateToProps, mapDispachToProps)(MenuDropdown)