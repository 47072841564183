import React, { Component } from "react";
import {Redirect, BrowserRouter as Router, Route, Switch} from "react-router-dom";

//Dashboard
import Dashboard from "./components/views/admin/Dashboard";
import DashboardCardExpanded from "./components/views/admin/DashboardCardExpanded";
import DashboardCardViews from "./components/views/admin/DashboardCardViews";
import ProductStep from './components/forms/partials/ProductStep';
import Product from './components/forms/partials/Product';

//Layout principales
import Main from './components/layouts/Main';
import MainAdmin from './components/layouts/MainAdmin';
import MainLogin from './components/layouts/MainLogin';

//Login
import Login from './components/views/login/Login';
import Forgot from './components/views/login/Forgot';
import Reset from './components/views/login/Reset';
import LoginReintegro from "./components/views/login/LoginReintegro";
import Steps from "./components/layouts/MainReintegro";
import StepsUpload from "./components/layouts/MainRefundUpload";

class Routing extends Component {
    render() {
        return (
            <Router>
            <Switch>
                <Route path='/dashboardRefund' exact component={Steps} />
                <Route path='/' exact component={Steps}  />
                <Route path='/upload_data' exact component={StepsUpload} />
                <Route >
                    <MainLogin>
                        <Switch>
                            <Route path={"/refund"} component={LoginReintegro} exact />
                        </Switch>
                    </MainLogin>
                </Route>
            </Switch>
        </Router>
            
        );
    }
}

export default Routing;