import { all } from 'redux-saga/effects';
import { login, logout, resetPassword, confirmPassword, loginReintegro, logoutReintegro, getUploadReintegro } from "./sagaLogin";

import {
    getSuppliers, getCoverages, getZones, getCategories, getOffices, saveInfoStep, saveInfoStepRefund, updateProduct,
    uploadFile, getSearchCoverages, getRateDetail, uploadImage, editOfficesRate, saveReintegro, getReintegros, getReintegro, getRefundData, getRefundDataImages, editReintegro, postUploadPendingDoc, patchUploadPendingDoc
} from "./saga";
import { getCoverage, getCoveragesList, addCoverage, removeCoverage, editCoverage } from "./sagaCoveragesAbm";
import { getCategory, getCategoriesList, addCategory, removeCategory, editCategory } from "./sagaCategoriesAbm";
import { getGroup, getGroupsList, addGroup, removeGroup, editGroup } from "./sagaGroupsAbm";
import { getUser, getUsersList, addUser, removeUser, editUser, getPermission } from "./sagaUsersAbm";
import { getCountry, getCountriesList, addCountry, removeCountry, editCountry } from "./sagaCountriesAbm";
import { getMunicipio, getMunicipiosList } from "./sagaMunicipiosAbm";
import { getSupplier, getSuppliersList, addSupplier, removeSupplier, editSupplier } from "./sagaSuppliersAbm";
import { getZone, getZonesList, addZone, removeZone, editZone } from "./sagaZonesAbm";
import {
    getOffice, getOfficesList, getOfficeParent, addOffice, removeOffice, duplicateOffice,addDuplicateOffice, editOffice, getSperta, getKaring, getPayment, getPaymentHistory,
    getAditionalOffices, addAditionalOffice, removeAditionalOffice, editAditionalOffice, getHashExportOfficesList, checkExportOfficesList, updateExportOfficesList, getOfficeListLimited
} from "./sagaOfficesAbm";
//import { getOffice, getOfficesList, getOfficeParent, addOffice, removeOffice, editOffice, getSperta, getPayment, getPaymentHistory, 
//    getAditionalOffices, addAditionalOffice, removeAditionalOffice, editAditionalOffice} from "./sagaOfficesAbm";
import { getProductsList, getProductsListReducida, removeProduct} from "./sagaProductsAbm";
import {getPromotionsList, editPromotion,addPromotion,removePromotion,getPromotion,addProductOffices, deleteProductOffices, getProductOffices,editProductOffices, deletePromotionProductOffices} from "./sagaPromotionsAbm";
import { getTag, getTagsList,addTag, removeTag, editTag } from "./sagaTagsAbm";
import { getBusinessUnit, getBusinessUnitsList,addBusinessUnit, removeBusinessUnit, editBusinessUnit } from "./sagaBusinessUnitsAbm";
import { getCurrency, getCurrenciesList,addCurrency, removeCurrency, editCurrency } from "./sagaCurrenciesAbm";
import { getReservation, getReservationsList, getFilteredReservationsList, getExportReservationsList, addReservation, removeReservation, editReservation } from "./sagaReservationsAbm";
import {
    getVoucher, getVouchersList, addVoucher, removeVoucher, editVoucher, cancelAllVouchers, changeStatusVouchers, printVoucher, printReservationMultivoucher,
    sendTermsAndConditions, sendMailCCGG, sendMailMultivoucher, getHashExportVouchersList, checkExportVouchersList, updateExportVouchersList
} from "./sagaVouchersAbm";
import { getPassenger, getPassengersList, addPassenger, removePassenger, editPassenger, getPassengerByDoc } from "./sagaPassengersAbm";
import { getProductSperta, getProductsSpertaList, addProductSperta, removeProductSperta, editProductSperta } from "./sagaProductsSpertaAbm";
import { getBlacklist, getPassengerBlacklist, addPassengerBlacklist, editPassengerBlacklist, removePassengerBlacklist } from "./sagaBlacklist";
import { addFacturacion } from "./sagaFacturacion";
import { getPaymentDetail } from "./sagaPaymentDetailAbm"

export default function* rootSaga() {
    yield all([
        login(),
        logout(),
        resetPassword(),
        confirmPassword(),
        loginReintegro(),
        getUploadReintegro(),
        getCountriesList(),
        addCountry(),
        getCountry(),
        removeCountry(),
        editCountry(),
        getMunicipio(),
        getMunicipiosList(),
        getSupplier(),
        getSuppliers(),
        getSuppliersList(),
        addSupplier(),
        removeSupplier(),
        editSupplier(),
        getCategories(),
        getZones(),
        getCoverage(),
        getCoveragesList(),
        getCoverages(),
        addCoverage(),
        removeCoverage(),
        editCoverage(),
        getSearchCoverages(),
        getOffices(),
        saveInfoStep(),
        saveInfoStepRefund(),
        updateProduct(),
        uploadFile(),
        uploadImage(),
        saveReintegro(),
        getReintegros(),
        getReintegro(),
        getRefundData(),
        getRefundDataImages(),
        postUploadPendingDoc(),
        patchUploadPendingDoc(),
        editReintegro(),
        editOfficesRate(),
        getHashExportOfficesList(),
        checkExportOfficesList(),
        updateExportOfficesList(),
        addZone(),
        editZone(),
        removeZone(),
        getZonesList(),
        getZone(),
        getProductsList(),
        getProductsListReducida(),
        removeProduct(),
        getCategory(),
        getCategoriesList(),
        addCategory(),
        removeCategory(),
        editCategory(),
        getGroup(),
        getGroupsList(),
        addGroup(),
        removeGroup(),
        editGroup(),
        getUser(),
        getUsersList(),
        addUser(),
        removeUser(),
        editUser(),
        getPermission(),
        getOffice(),
        getOfficesList(),
        getOfficeListLimited(),
        getOfficeParent(),
        addOffice(),
        removeOffice(),
        addDuplicateOffice(),
        duplicateOffice(),
        editOffice(),
        getAditionalOffices(),
        addAditionalOffice(),
        editAditionalOffice(),
        removeAditionalOffice(),
        getSperta(),
        getKaring(),
        getPayment(),
        getPaymentHistory(),
        getPromotionsList(),
        addPromotion(),
        removePromotion(),
        editPromotion(),
        getPromotion(),
        addProductOffices(),
        deleteProductOffices(),
        getProductOffices(),
        editProductOffices(),
        deletePromotionProductOffices(),
        getRateDetail(),
        getTag(),
        getTagsList(),
        addTag(),
        removeTag(),
        editTag(),
        getBusinessUnit(),
        getBusinessUnitsList(),
        addBusinessUnit(),
        removeBusinessUnit(),
        editBusinessUnit(),
        getCurrency(),
        getCurrenciesList(),
        addCurrency(),
        removeCurrency(),
        editCurrency(),
        getReservation(),
        getReservationsList(),
        getFilteredReservationsList(),
        getExportReservationsList(),
        addReservation(),
        removeReservation(),
        editReservation(),
        getVoucher(),
        getVouchersList(),
        getHashExportVouchersList(),
        checkExportVouchersList(),
        updateExportVouchersList(),
        addVoucher(),
        removeVoucher(),
        editVoucher(),
        cancelAllVouchers(),
        changeStatusVouchers(),
        printVoucher(),
        printReservationMultivoucher(),
        sendTermsAndConditions(),
        sendMailCCGG(),
        sendMailMultivoucher(),
        getPassenger(),
        getPassengerByDoc(),
        getPassengersList(),
        addPassenger(),
        removePassenger(),
        editPassenger(),
        getProductSperta(),
        getProductsSpertaList(),
        addProductSperta(),
        removeProductSperta(),
        editProductSperta(),
        addFacturacion(),
        getBlacklist(),
        getPassengerBlacklist(),
        addPassengerBlacklist(),
        editPassengerBlacklist(),
        removePassengerBlacklist(),
        getPaymentDetail(),
    ])
}
