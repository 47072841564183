import React, { Component, Fragment } from 'react';
import Password from '../../common/fields/Password';
import StringNumber from '../../common/fields/StringNumber';
import { Redirect } from "react-router-dom";
import { IconButton, Collapse, Grid, Typography, FormControlLabel, Button, Link, Checkbox, Container } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            user: [],
            password: [],
            errors: {
                status: {
                    user: false,
                    password: false
                },
                text: {
                    user: "",
                    password: ""
                }

            }
        };
    }

    send = async () => {
       
        let form = new Object();
        let countValid = 0;
        let errors = [];

        if (this.state.user && this.state.user !== null) {
            if (this.state.user.state.defaultValue !== '') {
                countValid++;
                form[this.state.user.props.name] = this.state.user.state.defaultValue;
            }
        }

        if (this.state.password && this.state.password !== null) {
            if (this.state.password.state.defaultValue !== '') {
                countValid++;
                form[this.state.password.props.name] = this.state.password.state.defaultValue;
            }
        }

        errors = {
            status: {
                user: (this.state.user && this.state.user.state.defaultValue !== '' && this.state.user.state.errorText === '') ? false : true,
                password: (this.state.password && this.state.password.state.defaultValue !== '' && this.state.password.state.errorText === '') ? false : true
            },
            text: {
                user: (this.state.user && this.state.user.state.defaultValue !== '' && this.state.user.state.errorText === '') ? "" : "Ingrese su usuario",
                password: (this.state.password && this.state.password.state.defaultValue !== '' && this.state.password.state.errorText === '') ? "" : "Ingrese su clave"
            }
        }

        this.setState({ errors: errors });

        if (2 === countValid) {

            this.props.getLogin(form);
        }
    }
	
    collapseAlert = () => {
		this.setState({ open: !this.state.open })
	}

    render() {
        //console.log("login",this.props.responseLogin);
        
        console.log("location.state", this.props.location.state);
        return (
                this.props.responseLogin 
                ? (<Redirect to={"/dashboard"} exact />) 
                : (
                    <Container maxWidth="md" className="mx-auto">
                        <div className="login_container">

                            <div className="form-header">
                                <Typography component="h1" variant="h4" className="title">Bienvenido de vuelta,</Typography>
                                <Typography component="h2" variant="h5" className="subtitle">por favor ingrese sus datos de cuenta.</Typography>
                            </div>

                            {this.props.errorLogin  &&
                                (<Alert severity="error" className="mb-2">
                                    <AlertTitle>Datos inválidos</AlertTitle>
                                    El nombre de usuario o email es incorrecto. Por favor, verifiquelos y vuelva a intentar.
                                </Alert>)}
                                
                            {this.props.location.state !== undefined && this.props.location.state.accessTokenError !== undefined && (
                                <Collapse in={this.state.open}>
                                    {/* <Container maxWidth='xl' className="_container pb-0" > */}
                                        <Grid container spacing={1}>
                                            <Grid item sm={12}>
                                                <Alert severity="warning"
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={this.collapseAlert}
                                                        >
                                                            <CloseIcon fontSize="inherit" />
                                                        </IconButton>
                                                    }
                                                >
                                                    Su sesión ha caducado. Por favor, vuelva a iniciar sesión.
                                                </Alert>
                                            <br/>
                                            </Grid>
                                        </Grid>
                                    {/* </Container> */}
                                </Collapse>
                            )}

                            {this.props.location.state !== undefined 
                            && this.props.location.state.resetPassword !== undefined 
                            && this.props.location.state.resetPassword 
                            && (
                                <Collapse in={this.state.open}>
                                    {/* <Container maxWidth='xl' className="_container pb-0" > */}
                                        <Grid container spacing={1}>
                                            <Grid item sm={12}>
                                                <Alert severity="info"
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={this.collapseAlert}
                                                        >
                                                            <CloseIcon fontSize="inherit" />
                                                        </IconButton>
                                                    }
                                                >
                                                    Verifique su email para seguir con el proceso de cambio de contraseña.
                                                </Alert>
                                            <br/>
                                            </Grid>
                                        </Grid>
                                    {/* </Container> */}
                                </Collapse>
                            )}

                            <form className="form" noValidate>
                                <Grid container spacing={1}>
                                    <Grid item md={7}>
                                        <StringNumber
                                            ref={element => { this.state.user = element }}
                                            id="email"
                                            label="Email o usuario"
                                            name="username"
                                            placeholder="Ingrese un email o usuario"
                                            defaultValue={''}
                                            disabled={false}
                                            error={this.state.errors.status.user}
                                            errorText={this.state.errors.text.user}
                                            classNameCustom={'textField'}
                                            autoComplete="email"
                                            required={true}
                                            handleError={true}
                                        />
                                    </Grid>
                                    <Grid item md={5}>
                                        <Password
                                            ref={element => { this.state.password = element }}
                                            id="password"
                                            label="Password"
                                            name="password"
                                            placeholder="Clave..."
                                            defaultValue={''}
                                            disabled={false}
                                            error={this.state.errors.status.password}
                                            errorText={this.state.errors.text.password}
                                            classNameCustom={'textField'}
                                            autoComplete="current-password"
                                            required={true}
                                        />
                                    </Grid>
                                </Grid>

                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Mantener logueado"
                                />
                                <hr />

                                <div className="text-right">

                                    <Button
                                        variant="text"
                                        component={Link} href={'/recovery'}
                                        className="mr-1"
                                        size="large"
                                    >Olvidó su clave?</Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="submit"
                                        size="large"
                                        onClick={() => this.send()}
                                    >
                                        Entrar
                                </Button>
                                </div>
                            </form>
                        </div>
                    </Container>
                )

        )
    }
}

const mapStateToProps = reducers => {
	
    return reducers.loginReducer;
};

const mapDispachToProps = dispatch => {
	return {
		getLogin: (form) => dispatch({ type: "GET_LOGIN", form }),
	};
};

export default connect(mapStateToProps, mapDispachToProps)(Login)

