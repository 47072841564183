import React, { Component, Suspense,Fragment } from 'react';
import config from '../../../config/config.json';
import ContentLoader from "react-content-loader";
import { IconButton, Collapse, Container, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';


class DashboardCardViews extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true,
			userData: {},
			statusLogin: false,
			card: []
		}
	}

	collapseAlert = () => {
		this.setState({ open: !this.state.open })
	}

	dashboardCardType = () => {
		// console.log("card",this.props.match.params.card)
		let card = this.props.match.params.card.charAt(0).toUpperCase() + this.props.match.params.card.slice(1);
		let Component = React.lazy(() => import(`../../forms/View${card}`));
		return <Suspense fallback={<div className="preload"><div className="responsive">
			<ContentLoader speed={2} backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" width={385} height={200} viewBox="0 0 385 200"><rect x="0" y="0" rx="10" ry="10" width="385" height="200" /></ContentLoader>
		</div></div>}>

			<Component location={this.props.location} card={this.props.match.params.card} views={this.props.match.params.views} id={this.props.match.params.id}/>
		</Suspense>
	}

	collapseAlert = () => {
		this.setState({ open: !this.state.open })
	}

	render() {
		console.log("dashboard card views props", this.props)
		return (
			<Fragment>
				<Grid container spacing={3}>
					<Grid item md={12}>
						<div className={`detail_dashboard dashboard_card_expanded`}>
							<div className="content_detail_dashboard" >
								{this.props.location.state !== undefined && this.props.location.state.stepFinalizado !== undefined && this.props.location.state.view !== 'show' && (
									<Collapse in={this.state.open} className="alertTopCardContent">
											<Alert severity="info"
												action={
													<IconButton
														aria-label="close"
														color="inherit"
														size="small"
														onClick={this.collapseAlert}
													>
														<CloseIcon fontSize="inherit" />
													</IconButton>
												}
											>
												{this.props.location.state.view === 'edit' ? 'El producto se actualizó correctamente' : 'El producto se creó correctamente'}
											</Alert>
									</Collapse>

								)}
								{this.dashboardCardType()}
							</div>
						</div>
					</Grid>
				</Grid>
			</Fragment>
		)
	}
}

export default DashboardCardViews
