const initialState = {
    responseCountriesList: [],
    responseCountry: [],
    errorCountry: false,
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "COUNTRIES_RESPONSE_LIST":
            newState.responseCountriesList = action.value;
            break;
        case "COUNTRY_RESPONSE":
            newState.responseCountry = action.value;
            break;
        default: 
            return newState;

    }
    
    if (action.value.detail !== undefined) newState.errorCountry = true;
    else newState.errorCountry = false;

    return newState;
};

export default reducer;