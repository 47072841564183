const initialState = {
    responsePaymentDetail: [],

};

const reducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case "PAYMENT_DETAIL_RESPONSE":
            newState.responsePaymentDetail = action.value;
            break;
        default: 
            return newState;
    }

    return newState;
};

export default reducer;