const initialState = {
    responseCoveragesList: [],
    responseCoverage: []
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "COVERAGES_RESPONSE_LIST":
           // console.log("COVERAGES_RESPONSE_LIST")
            if (action.value.detail !== undefined)
                newState.responseCoveragesList = []
            else
                newState.responseCoveragesList = action.value;
            break;
        case "COVERAGE_RESPONSE":
           // console.log("COVERAGE_RESPONSE")
            newState.responseCoverage = action.value;
            break;
        default: 
            return newState;
    }

    return newState;
};

export default reducer;