const initialState = {
    responseSuppliersList: [],
    responseSupplier: []
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case "SUPPLIERS_RESPONSE_LIST":
            console.log("SUPPLIERS_RESPONSE_LIST", action.value);
            if (action.value.detail !== undefined)
                newState.responseSuppliersList = []
            else
                newState.responseSuppliersList = action.value;
            
            newState.responseSupplier = []
            break;
        case "SUPPLIER_RESPONSE":
          
            newState.responseSupplier = action.value;
            break;
        default: 
            return newState;
    }

    return newState;
};

export default reducer;