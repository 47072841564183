const initialState = {
    responseProductSpertaList: [],
    responseProductSperta: [],
    errorProductSperta: false,
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };  
    switch (action.type) {
        case "PRODUCT_SPERTA_LIST_RESPONSE":
            newState.responseProductSpertaList = action.value
            break;
            
        case "PRODUCT_SPERTA_RESPONSE":
            newState.responseProductSperta = action.value;
            //Cuando la response da error de edición en un campo no va a tener un atributo "detail", entonces se lo inserta manualmente.
            if (action.value.view == 'edit' || action.value.view == 'create'){ 
                if (action.value.id == undefined){ 
                    newState.responseProductSperta.detail = {};
                }
            }
            break;
        default: 
            return newState;
    }

    if ( (action.value.detail !== undefined) || (action.value.status==404) ){
        newState.errorProductSperta = true;
    }else{ 
        newState.errorProductSperta=false;
    }
    console.log(action.value);
    return newState;
};

export default reducer;